import * as S from './SubMenu.styles';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { settingItemsOpen } from 'redux_toolkit/reducers/app/appSlice';


export const SubMenu = ({
  item,
  sidebarClosed,
  activePath,
  changeTab
}) => {
  const dispatch = useDispatch();
  const { settingItemsOpened, isSidebarOpened } = useSelector(({ app }) => app);

  const [anchorEl, setAnchorEl] = useState(sidebarClosed ? null : settingItemsOpened);
  const isSubMenuOpened = Boolean(anchorEl);

  useEffect(()=>{
    !isSidebarOpened &&  setAnchorEl(null);
  },[isSidebarOpened])

  const opened=(e)=>{  
    if(isSubMenuOpened){      
      setAnchorEl(!anchorEl);
      dispatch(settingItemsOpen(!anchorEl));
    }else{
      setAnchorEl(e.currentTarget);
      dispatch(settingItemsOpen(window.innerWidth < 950 ? !!anchorEl : !anchorEl));
    }
  }

  const id = isSubMenuOpened ? 'submenu-popover' : undefined;

  return (
    <>
      <S.ListItem
        button
        disableGutters={true}
        onClick={opened}
        to={item.path}
        className={isSidebarOpened  &&  "opened"}
        aria-describedby={id}
      >
        <S.ListItemIcon className="listItemIcon">
          <FontAwesomeIcon icon={item.icon} />
        </S.ListItemIcon>
        {!sidebarClosed && <S.ListItemText
          className={`${cx({ sidebarClosed })} subMenu`}
          issubmenu='none'
          primary={item.name}
        />}
        <S.ListItemIcon>
          {
            isSubMenuOpened
              ? <KeyboardArrowUpIcon />
              : <KeyboardArrowDownIcon />
          }
        </S.ListItemIcon>
      </S.ListItem>
      {sidebarClosed ?
        <S.SubMenuListPopover
          id={id}
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <S.Collapse in={isSubMenuOpened} unmountOnExit>
            {
              item.subItems.map(subItem => {
                return (
                  <S.ListItem
                    disableGutters={false}
                    selected={activePath === subItem.path}
                    key={Math.random()*10}
                    button
                    component={Link}
                    to={subItem.path}
                    onClick={()=> { changeTab(subItem.path) }}
                  >
                    <S.ListItemText
                      primary={subItem.name}
                    />
                  </S.ListItem>
                )
              })
            }
          </S.Collapse>
        </S.SubMenuListPopover>
        :
        <S.SubMenuContainerItems in={isSubMenuOpened} className='countainer_submenu'>
          {
            item.subItems.map(subItem => {
            return (
              <S.ListItem
                disableGutters={false}
                selected={activePath === subItem.path}
                key={Math.random()*10}
                button
                component={Link}
                to={subItem.path}
                className='popover'
                onClick={()=> { changeTab(subItem.path) }}
              >
                <S.ListItemText
                  className='SubMenuItem'
                  primary={subItem.name}
                />
              </S.ListItem>
            )
          })
        }
        </S.SubMenuContainerItems>
      } 
    </>
  )
}