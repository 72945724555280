import * as S from './PastChatsHistory.styles';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Table } from 'components';
import { HistoryDetails } from 'pages/HistoryPage/components/HistoryDetails/HistoryDetails';
import { formatTableData, tableHead } from './PastChatsHistory.config';
import { getPastChatHistory } from 'redux_toolkit/reducers/partner/visitors/visitorsSlice';


export const PastChatsHistory = memo(({
  visitor
}) => {
  const dispatch = useDispatch()
  const { partnerId } = useSelector(({ userPartner })=> userPartner.userData);
  const { pastChatsHistory, visitorPastChats } = useSelector(({ visitors })=> visitors);
  const getPastChats = useCallback((id)=> visitorPastChats.get(id), [visitorPastChats]);
  const [selectedChatId, setSelectedChatId] = useState();
  const [selectedChat, setSelectedChat] = useState();

  const pastChats = useMemo(() => {
    return getPastChats(visitor._id)
  }, [getPastChats, visitor._id])

  useEffect(() => {
    const thereIsChat = pastChatsHistory.get(selectedChatId);
    if (selectedChatId && thereIsChat) setSelectedChat(thereIsChat);
  }, [pastChatsHistory, selectedChatId])

  return (
    <S.PastChatsHistory>
      {pastChats?.length && (
        <Table
          tableHead={tableHead}
          tableData={formatTableData(pastChats)}
          pastChatCardStyles={true}
          onRowClick={(chat) => {
            setSelectedChatId(chat._id);
            const thereIsChat = pastChatsHistory.get(chat._id);
            if (!thereIsChat) dispatch(getPastChatHistory({partnerId, chatId: chat._id}));
          }}
        />
      )}

      {selectedChat && selectedChatId && 
        <HistoryDetails
          partnerId={partnerId}
          item={selectedChat}
          showActions={false}
          visitorPartner={visitor}
          onClose={() => {
            setSelectedChatId(null);
          }}
        />
      }
    </S.PastChatsHistory>
  )
}, (prevProps, nextProps)=> {
  return prevProps.visitor === nextProps.visitor;
})