
import * as S from './VisitorsPage.styles';
import React, { useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Loader } from "components";
import { SearchInput } from 'ui';
import { VisitorCard } from './components/VisitorCard/VisitorCard';


export const VisitorsPage = ()=> {
  const { visitorsPartner, loading } = useSelector(({ visitors }) => visitors);
  const [searchText, setSearchText] = useState('');
  const [updateTimes, setUpdateTimes] = useState(false);

  const visitorsCount = useMemo(() => {
    return [
      {
        status: 'idle',
        visitors: visitorsPartner.idle,
      },
      {
        status: 'active',
        visitors: visitorsPartner.active,
      },
      {
        status: 'incoming',
        visitors: visitorsPartner.incoming,
      },
      {
        status: 'assigned',
        visitors: visitorsPartner.assigned,
      },
      {
        status: 'served',
        visitors: visitorsPartner.visitors,
      },
    ]
  }, [
    visitorsPartner.idle,
    visitorsPartner.active,
    visitorsPartner.incoming,
    visitorsPartner.assigned,
    visitorsPartner.visitors
  ]);

  useEffect(() => {
    const interval = setInterval(() => {
      setUpdateTimes(!updateTimes);
    }, 60*5*1000);

    return () => clearInterval(interval);
  },[updateTimes]);

  return(
    <>
      <S.VisitorsPageContainer>
        <S.SearchBar>
          <S.SearchInputContainer>
            <SearchInput
              value={searchText}
              clearInput={() => setSearchText('')}
              onChange={e => setSearchText(e.target.value)}
              placeholder='Search...'
            />
          </S.SearchInputContainer>
        </S.SearchBar>
        <S.VisitorsListContainer>
          {
            visitorsCount.map(card => (
              <VisitorCard
                key={card.status}
                status={card.status}
                data={card.visitors}
                searchText={searchText}
              />
            ))
          }
        </S.VisitorsListContainer>
      </S.VisitorsPageContainer>
      {loading && <Loader color="primary" size={100} />}
    </>
  )
}