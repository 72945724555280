import './Button.css';
import React from 'react'
import { RegularButton } from 'components'

export const Button = (props) => {
  const color = !props.color ? 'primary': props.color;
  return (
    <RegularButton
      className='zc-Button'
      {...props}
      style={{
        ...props.style
      }}
      color={color}
    />
  )
}