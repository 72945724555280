import { useEffect } from "react";
import { Outlet } from "react-router";
import { NotAuthLayout } from "./components/NotAuthLayout";
import { NotAuthFormLayout } from "./components/NotAuthFormLayout/NotAuthFormLayout";


export const LayoutNotAuth = ()=> {
  useEffect(()=> {
    if(localStorage.getItem('access_token')) {
      window.location.replace('/partners');
    }
  }, [])

  return (
    <NotAuthLayout>
      <NotAuthFormLayout>
        <Outlet />
      </NotAuthFormLayout>
    </NotAuthLayout>
  )
}