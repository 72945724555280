export const tableHead = [
  'Name',
  'Admin',
  'Assign',
  'Actions'
]

export const formatTableData = (partnersList, getTableActions) => {
  const formattedList = []
  for (const partner of partnersList) {
    const adminsName = partner?.admin?.map(admin => admin.name).join(', ');

    formattedList.push([
      partner.name,
      adminsName || '---',
      ...getTableActions(partner)
    ])
  }
  return formattedList
}