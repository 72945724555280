import React from 'react'
import ReactSelect from 'react-select'


export const SelectBox = ({
  indicatorContainerStyles = {},
  valueContainerStyles = {},
  controlStyles = {},
  optionStyles = {},
  getOptionLabel,
  defaultValue,
  borderColor,
  className,
  textColor,
  boxShadow,
  valueKey='value',
  labelKey='label',
  onChange,
  type,
  list = [],
  avatarImg='',
  ...rest
}) => {


  return (
    <ReactSelect
      options={list}
      getOptionLabel={getOptionLabel || ((option) => <div {...option?.props} className="item-label"> {option.label} </div>)}
      onChange={onChange}
      value={list[defaultValue] || list[0]}
      maxMenuHeight={type === 'outline' && 200}
      menuPlacement="auto"
      {...rest}
      styles={{
        menu: (provided) => ({
          ...provided,
          minWidth: 'max-content',
          width: type === 'outline' ? 100 : '100%',
          marginLeft: avatarImg.className? '-50%': '0px',
          zIndex: 2
        }),
        control: (provided, state) => ({
          ...provided,
          minHeight: 30,
          background: 'transparent',
          border: type === 'outline' ? 0 : `1px solid ${borderColor || '#0f3d56'}`,
          borderBottom: `1px solid ${borderColor || '#0f3d56'}`,
          borderRadius: type === 'outline' ? 0 : 5,
          boxShadow: boxShadow || (state.menuIsOpen && type !== 'outline' && '0 0 0 1px #0f3d56'),
          width: type === 'outline' && 100,
          minWidth: '55px',
          '&:hover': { borderColor: borderColor || '#0f3d56' },
          ...controlStyles
        }),
        menuList: (provided) => ({
          ...provided,
          padding: 0,
          borderRadius: 5,
          color: textColor || '#0f3d56'
        }),
        indicatorSeparator: () => ({
          display: 'none'
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
          display: avatarImg ? 'none': 'block',
        }),
        indicatorsContainer: (provided) => ({
          ...provided,
          '& div[class*="indicatorContainer"]': { padding: '6px 8px' },
          ...indicatorContainerStyles,
        }),
        option: (provided, state) => ({
          ...provided,
          background: state.isSelected && '#0f3d56',
          margin: 0,
          '&:hover': {
            cursor: 'pointer',
            background: !state.isSelected && '#0f3d5622',
          },
          '&:active': {
            background: '#0b2736',
            color: '#fff'
          },
          '& .item-label': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
          ...optionStyles
        }),
        singleValue: (provided) => ({
          ...provided,
          color: textColor || '#0f3d56',
          fontWeight: type === 'outline' ? 600 : 500,
          '& .item-label': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '& svg': {
              display: 'none'
            }
          },
        }),
        valueContainer: (provided) => ({
          ...provided,
          ...valueContainerStyles
        })
      }}
      isSearchable={false}
      className={className}
    />
  )
}