import styled from 'styled-components/macro'


export const GettingStartedContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 15px;
`

export const InfoItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
`

export const InfoItemTitle = styled.h2`
  color: #0f3d56;
  font-size: 18px;
  padding: 10px 0px;
  border-bottom: 3px solid #0f3d56;
`

export const InfoItemDescription = styled.div`
  color: #555;
  font-size: 15px;
  font-weight: bold;
`

export const AddPartner = styled.div`
  color: #3c4858;
  font-size: 15px;
`

export const RowContainer = styled.div`
  display: flex;
  gap: 10px;
`

export const Script = styled.div`
  overflow: auto;
  padding: 8px;
  opacity: 0.9;
  border: 1px solid #B8B8B8;
  border-radius: 4px;
  text-align: start;
  width: 100%;
  word-break: break-all;
  @media (max-width:950px){
    max-width:100%;
    max-height:300px;
  }
`