import styled from 'styled-components/macro'


import LayoutBackground from 'images/NotAuthLayoutBackground.png'

export const LayoutContainer = styled.div`
  display: flex;
  height: 100vh;
  background: url(${LayoutBackground}) no-repeat;
  background-size: cover;
  justify-content: center;
  align-items: center;
`