import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { selectChatHistoryServ, getChatsMessagesServ } from './historyApi';

const initialState = {
  list: null,
  messagesList: new Map(),
  loading: false,
  count: 0,
  status: 'idle'
}

export const selectHistory = createAsyncThunk(
  'select_chat_history',
  async ({partnerId, queryData={}})=> {
    const res = await selectChatHistoryServ(partnerId, queryData);
    if(res.success) {
      return res;
    }
    throw new Error('Get chats history failed!', res.message);
  }
);

export const getChatMessages = createAsyncThunk(
  'get_chat_messages',
  async ({partnerId, chatId})=> {
    const res = await getChatsMessagesServ(partnerId, chatId);
    if(res.success) {
      return {
        data: res.data,
        chatId
      };
    }
    throw new Error('Get chats Chat Messages failed!', res.message);
  }
);

export const historySlice = createSlice({
  name: 'history',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(selectHistory.pending, (state) => {
        state.status = 'loading';
        state.loading = true
      })
      .addCase(selectHistory.fulfilled, (state, action) => {
        state.status = 'idle';
        state.list = action.payload.data;
        state.count = action.payload.count;
        state.loading = false;
      })
      .addCase(selectHistory.rejected, (state, action) => {
        state.status = 'error';
        state.loading = false;
        console.error("Error redux async func 'selectHistory'", action.error)
      })
      .addCase(getChatMessages.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getChatMessages.fulfilled, (state, action) => {
        state.status = 'idle';
        state.messagesList = state.messagesList.set(action.payload.chatId, action.payload.data);
      })
      .addCase(getChatMessages.rejected, (state, action) => {
        state.status = 'error';
        console.error("Error redux async func 'getChatMessages'", action.error)
      })
  },
});

export const selectBanned = (state) => state.history;
export default historySlice.reducer;