import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  addShortcutServ,
  deleteShortcutServ,
  editShortcutServ,
  getShortcutsServ
} from './shortcutApi';
import { setNotification } from 'redux_toolkit/reducers/notification/notificationSlice';

const initialState = {
  list: [],
  status: 'idle'
}

export const addShortcut = createAsyncThunk(
  'add_shortcut',
  async ({partnerId, shortcut}, {dispatch})=> {
    const res = await addShortcutServ(partnerId, shortcut);
    if (res.success) {
      dispatch(setNotification({type: res.message, message: 'success'}));
      return res.data
    }
    dispatch(setNotification({type: res.message, message: 'error'}));
    throw new Error('Add shortcut failed', res.message);
  }
);

export const getShortcuts = createAsyncThunk(
  'shortcuts_list',
  async (partnerId)=> await getShortcutsServ(partnerId)
);

export const updateShortcut = createAsyncThunk(
  'edit_shortcut',
  async ({partnerId, shortcutId, shortcut}, {dispatch})=> {
    const res = await editShortcutServ(partnerId, shortcutId, shortcut);
    if(res.success){
      dispatch(setNotification({type: res.message, message: 'success'}));
      return {
        shortcutId,
        shortcut
      }
    }
    dispatch(setNotification({type: res.message, message: 'error'}));
    throw new Error('Edit shortcut failed', res.message);
  }
);

export const deleteShortcut = createAsyncThunk(
  'delete_shortcut',
  async ({partnerId, shortcutId}, {dispatch})=> {
    const res = await deleteShortcutServ(partnerId, shortcutId);
    if(res.success){
      dispatch(setNotification({type: res.message, message: 'success'}));
      return shortcutId;
    }
    dispatch(setNotification({type: res.message, message: 'error'}));
    throw new Error('Delete shortcut failed', res.message);
  }
);


export const partnerShortcutSlice = createSlice({
  name: 'partner_shortcut',
  initialState,
  reducers: {
    searchShortcut: (state, action)=> {
      state.list = state.list.filter(shortcut=>shortcut.shortcut.match(action.payload))
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(addShortcut.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addShortcut.fulfilled, (state, action) => {
        state.status = 'idle';
        state.list = state.list.concat(action.payload);
      })
      .addCase(addShortcut.rejected, (state, action) => {
        state.status = 'error';
        console.error("Error redux async func 'addShortcut'", action.error)
      })
      .addCase(getShortcuts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getShortcuts.fulfilled, (state, action) => {
        state.status = 'idle';
        state.list = action.payload.data;
      })
      .addCase(getShortcuts.rejected, (state, action) => {
        state.status = 'error';
        console.error("Error redux async func 'getShortcuts'", action.error)
      })
      .addCase(updateShortcut.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateShortcut.fulfilled, (state, action) => {
        state.status = 'idle';
        state.list = state.list.map(item => item._id === action.payload.shortcutId ? {...item, ...action.payload.shortcut} : item);
      })
      .addCase(updateShortcut.rejected, (state, action) => {
        state.status = 'error';
        console.error("Error redux async func 'updateShortcut'", action)
      })
      .addCase(deleteShortcut.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteShortcut.fulfilled, (state, action) => {
        state.status = 'idle';
        state.list = state.list.filter(shortcut => shortcut._id !== action.payload);
      })
      .addCase(deleteShortcut.rejected, (state, action) => {
        state.status = 'error';
        console.error("Error redux async func 'deleteShortcut'", action.error)
      });
  },
});

export const {
  searchShortcut
} = partnerShortcutSlice.actions
export const selectPartnerShortcut = (state) => state.list;
export default partnerShortcutSlice.reducer;