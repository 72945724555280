import * as Yup from 'yup'

export const initialValues = {
  name: '',
}

const requiredErrorMessage = 'This field is required'

export const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(4, 'The name must be a string min 4 characters.')
    .required(requiredErrorMessage),
});

export const validationSchemaUpdate = Yup.object().shape({
  name: Yup.string()
    .min(4, 'The name must be a string min 4 characters.')
    .required(requiredErrorMessage),
});