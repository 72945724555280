import * as S from "./ShortcutsPage.styles";
import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Table } from "components";
import { SearchInput } from "ui";
import { tableHead, formatTableData } from "./ShortcutsPage.config";
import { showModal, hideModal } from "redux_toolkit/reducers/modals/modalsSlice";
import { deleteShortcut } from "redux_toolkit/reducers/partner/shortcut/shortcutSlice";


export const ShortcutsPage = () => {
  const dispatch = useDispatch();
  const shortcutsList = useSelector(({ shortcut }) => shortcut.list);
  const { partnerId } = useSelector(({ userPartner }) => userPartner.userData);
  const [searchVal, setSearchVal] = useState("");

  const shortcuts = useMemo(() => {
    return searchVal.trim() ? shortcutsList.filter((item) => item.tag.toLowerCase().includes(searchVal.toLowerCase().trim())) : shortcutsList
  }, [searchVal, shortcutsList]);

  function HeaderButtons() {
    return (
      <S.HeaderContainerActions>
        <S.SearchContainer>
          <SearchInput
            placeholder="Search..."
            onChange={e => setSearchVal(e.target.value)}
            clearInput={() => setSearchVal('')}
            value={searchVal}
          />
        </S.SearchContainer>
        <S.Plus onClick={() => dispatch(showModal({type: 'shortcutsForm'}))}>
          <FontAwesomeIcon icon={faPlus} classes="add-agent" />
        </S.Plus>
      </S.HeaderContainerActions>
    );
  }
  const getTableActions = (shortcut) => [
    <S.ActionsContainer>
      <S.ActionButton
        onClick={() =>
          dispatch(showModal({
            type: 'shortcutsForm',
            editableShortcut: shortcut,
          }))
        }
      >
        <EditIcon />
      </S.ActionButton>
      <S.ActionButton
        onClick={() =>{
          dispatch(showModal({
            type: 'confirmation',
            title: `Are you sure you want to delete ${shortcut.tag} shortchut?`,
            hideModal: hideModal,
            confirmText: "Delete",
            onConfirm: () => dispatch(deleteShortcut({partnerId, shortcutId: shortcut._id})),
          }))
        }
        
        }
      >
        <DeleteIcon />
      </S.ActionButton>
    </S.ActionsContainer>,
  ];
  return (
      <S.ShortcutsPageContainer>
        <Table
          tableHead={tableHead}
          tableData={formatTableData(shortcuts, getTableActions)}
          cardHeaderText="Shortcuts"
          headerComponent={HeaderButtons()}
          allScreen={true}
        />
      </S.ShortcutsPageContainer>
  );
};
