import { USERS_ROLES } from "configs/constants";
import { USER_STATUSES } from "configs/constants";
import { ROLES } from "configs/constants";
import { compact } from "utils/functions";

export const TABLE_HEADERS = {
  '1': ['Name', 'Role', 'Status', 'Chats count'],
  '2': ['Name', 'Role', 'Status', 'Chats count', 'Chat'],
  '3': ['Name', 'Role', 'Chat']
}

export const formatTableData = (agentsList, getTableActions, currentUser) => {
  const formattedList = [];
  for (const agent of agentsList) {
    formattedList.push([
      agent.name,
      USERS_ROLES[agent.role] || null,
      currentUser?.role !== ROLES.AGENT ? USER_STATUSES[agent.status] : null,
      currentUser?.role !== ROLES.AGENT ? `${agent.chatQuantity} / ${agent.limit}` : null,
      currentUser?.role !== ROLES.ADMIN ? getTableActions(agent) : null
    ])
  }

  return formattedList.map(i => compact(i));
}