import cx from "classnames";
import * as S from "./VisitorCard.styles";
import React, { memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@material-ui/core/Typography";
import { Table } from "components";
import { Button } from "ui";
import { upperFirst } from "utils/functions";
import { viewChat } from "socket/socket";
import {
  VISITORS_PER_PAGE,
  formatTableData,
  tableHead,
  tableHeadSecond
} from "./VisitorCard.config";
import { showModal } from "redux_toolkit/reducers/modals/modalsSlice";


export const VisitorCard = memo(({
  data,
  status,
  searchText,
}) => {
  const dispatch = useDispatch();
  const { watchingChats, chats } = useSelector(({chat})=> chat);
  const label = `${upperFirst(status)} website visitors`;
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [tableData, setTableData] = useState(data || []);

  const rowDataCallBack = useCallback(() => {
    let allData = data;
    if (searchText) {
      allData = data.filter(item => item.name.includes(searchText));
    }
    return allData;
  }, [searchText, data]);

  useEffect(()=> {
    setTableData(rowDataCallBack());
  }, [rowDataCallBack]);

  return (
    <Accordion
      onChange={(_e, expanded) => {
        setOpen(expanded);
      }}
      expanded={open}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon  className="svg_expander"/>}
        aria-controls="panel1a-content"
        id="panel1a-header"
        className='expander'
      >
        <Typography>{label}</Typography>
        <S.VisitorsCount className={cx({ empty: !tableData.length })}>
          {tableData.length}
        </S.VisitorsCount>
      </AccordionSummary>
      <S.AccordionDetails>
        <Table
          allTdEqual={true}
          tableHead={status === 'idle' || status === 'active' ? tableHeadSecond : tableHead}
          cellTextCenter={false}
          onRowClick={(visitor) => {
            if(status === 'served') {
              const thereIsviewChat = (watchingChats.get(visitor?.chatId) || chats.get(visitor?.chatId))?.messages.length;
              if(!thereIsviewChat) viewChat(visitor?.chatId);
              dispatch(showModal({type: 'visitorInfo', visitor }));
            }
          }}
          tableData={formatTableData(tableData.slice(0, page*VISITORS_PER_PAGE), status)}
        />
        {page * VISITORS_PER_PAGE < tableData.length && (
          <Button onClick={() => setPage(prev => prev + 1)}>
            show more
          </Button>
        )}
      </S.AccordionDetails>
    </Accordion>
  );
}, (prevProps, nextProps)=> {
  return(
    prevProps.data === nextProps.data &&
    prevProps.status === nextProps.status &&
    prevProps.searchText === nextProps.searchText
  )
});
