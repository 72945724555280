import * as S from './NotAuthFormLayout.styles'
import React from 'react'
import logo from 'images/logo.png'

export const NotAuthFormLayout = ({
  children,
  headerHeight
}) => {
  return (
    <S.NotAuthFormLayoutContainer>
      <S.FormHeader height={headerHeight}>
        <S.Logo src={logo} />
        <S.HeaderBottomBackground />
      </S.FormHeader>
      <S.FormBody>
        { children }
      </S.FormBody>
    </S.NotAuthFormLayoutContainer>
  )
}

NotAuthFormLayout.defaultProps = {
  headerHeight: 265
}