import styled from 'styled-components'


export const LoaderContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.015);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`

