import { useMemo } from 'react';
import {
  faHome,
  faUsers,
  faHistory,
  faCog,
  faUser
} from '@fortawesome/free-solid-svg-icons'


export const MenuBuilder = () => {
  const currentPath = useMemo(() => window.location.pathname, []);
  const partnerKey = currentPath.split('/')[1];

  const agentSubMenu = [
    {
      name: 'Personal',
      path: `/${partnerKey}/personal`
    }
  ]
  const teamLeadSubMenu = [
    {
      name: 'Agents',
      path: `/${partnerKey}/agents`
    },
    {
      name: 'Banned',
      path: `/${partnerKey}/banned`
    },
    {
      name: 'Shortcuts',
      path: `/${partnerKey}/shortcuts`
    },
    {
      name: 'Personal',
      path: `/${partnerKey}/personal`
    }
  ]
  const adminSubMenu = [
    {
      name: 'Agents',
      path: `/${partnerKey}/agents`
    },
    {
      name: 'Banned',
      path: `/${partnerKey}/banned`
    },
    {
      name: 'Shortcuts',
      path: `/${partnerKey}/shortcuts`
    },
    {
      name: 'Widget',
      path: `/${partnerKey}/widget`,
    },
    {
      name: 'Personal',
      path: `/${partnerKey}/personal`
    }
  ]

  const homeMenu = {
    name: 'Home',
    path: `/${partnerKey}/home`,
    icon: faHome,
  }
  const visitorMenu = {
    name: 'Visitors',
    path: `/${partnerKey}/visitors`,
    icon: faUsers,
  }
  const historyMenu = {
    name: 'History',
    path: `/${partnerKey}/history`,
    icon: faHistory,
  }
  const settingsMenu = {
    name: 'Settings',
    isSubMenu: true,
    icon: faCog,
  }

  const menuAll = {
    '0': [
      {
        name: 'Home',
        path: '/superadmin/home',
        icon: faHome,
      },
      {
        name: 'Partners',
        path: '/superadmin/partners',
        icon: faUsers,
      },
      {
        name: 'Admins',
        path: '/superadmin/admins',
        icon: faUser,
      },
    ],
    '1': [
      homeMenu,
      visitorMenu,
      historyMenu,
      {
        ...settingsMenu,
        subItems: adminSubMenu,
      }
    ],
    '2': [
      homeMenu,
      visitorMenu,
      historyMenu,
      {
        ...settingsMenu,
        subItems: teamLeadSubMenu,
      },
    ],
    '3': [
      homeMenu,
      historyMenu,
      {
        ...settingsMenu,
        subItems: agentSubMenu,
      }
    ]
  }

  return { menuAll };
}