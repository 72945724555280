import styled, { keyframes } from "styled-components/macro";
import MaterialButton from "@material-ui/core/Button";
import MaterialListItemIcon from "@material-ui/core/ListItemIcon";
import MaterialCollapse from "@material-ui/core/Collapse";
import MaterialPopover from "@material-ui/core/Popover";

export const TeamContainerItems = styled(MaterialCollapse)`
  display: flex;
  color: #fff;
  width: 100%;
  flex-direction: column;
  &.MuiPaper-root {
    left: 6%;
  }
`;

export const TeamContainer = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  color: #fff;
  cursor: pointer;
  padding: 4px 10px 4px 16px;

  @media (min-width:1500px) {
    padding: 0 0 4px 16px;
  }
  
  span{
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  }

  &.sidebarClosed {
    padding: 0 0 3px 24px;
  }
  @media (max-width:1399px){
    &.sidebarClosed{
      padding: 0px 0px 0px 27%!important;
    }
  }
 
  @media (max-width: 950px){
    &.sidebarClosed{
      padding:4px 10px 4px 28px !important;
    }
 }
  @media (min-width:1400px){
    &.sidebarClosed{
      position: relative;
    }

    span{
      font-size: 14px;
    }
    svg{
      width:24px !important;
      height:24px;
    }
  }
  @media (min-width:1920px){
    span{
      font-size: 14px;
    }
  }
  @media (max-width:950px){
    .teamText{
      font-size:12px;
    }
    svg{
      width:18px !important;
      height:18px;
    }
  }
  @media (max-width:1400px){
    .teamText{
      font-size:14px;
    }
  }
 
`;
export const TeamContent = styled.div`
  display: flex;
  align-items: center;
  gap: 25%;
  @media(max-width: 1399px){
    &.sidebarClosed{
      margin-left: auto;
    }
  }
  svg{
    color: rgba(255, 255, 255, 0.8);
    @media (min-width: 951px) and (max-width: 1399px) {
      width: 18px;
      height: 18px;
    }
  }
`;

export const Icon = styled(MaterialListItemIcon)`
  color: rgba(255, 255, 255, 0.8);
  :nth-child(2n){
    position: relative;
  }
  @media (min-width:1400px){
    :nth-child(2n){
      svg{
        width:24px!important;
        height:24px!important;
      } 
    }
  }
  
  @media (min-width:1920px) {
    &.sidebarClosed:nth-child(2n){
      left: 0px;
    }
  }
  @media (min-width:951px){
    svg{
      width: 18px !important;
      height: 18px !important;
    }
    min-width: auto !important;
    margin-left: auto;
  }
  
  @media(max-width: 1399px){
    &.sidebarClosed{
      min-width: auto !important;
      margin-left: auto;
    }
  }
  
`;

export const ListItemText = styled(MaterialButton)`
  text-transform: none;
  color: unset;
  @media (min-width: 1400px) {
    &.sidebarClosed {
      margin-left: 0px;
    }
  }
  @media (max-width: 1024px) {
    &.view_all_txt {
      font-size: 9px;
    }
  }
`;

export const ListItem = styled.div`
  display: flex;
  justify-content: center;
  padding: 3px 8px;
  background: #000;
  align-items: center;

  button {
    font-size: 12px;
  }
  &.sidebarClosed{
    padding:unset!important;
  }

    ${ListItemText} {
      color: #fff;
    }
 
  &.view_all{
    padding:0px!important;
    background-color: transparent;
  }

  @media (max-width:1300px){
    padding: 5px;

    &.sidebarClosed {
      .MuiButton-root{
        min-width:0px;
      }
      .MuiButton-label{
        font-size:7px;
      }
    }
  }  @media (max-width:950px){
    &.view_all button span{
     font-size:10px!important;
    }
  }
   @media (max-width:1920px){
    &.sidebarClosed {
      .MuiButton-root{
        min-width:0px;
      }
      .MuiButton-label{
        font-size:11px;
      }
    }
   }
`;

export const CollapseContainer = styled.div``;

const statusAnimation = keyframes`
  0% {
    opacity: 0.7;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.7;
  }
`;

export const AgentStatus = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: rgba(2, 173, 2);
  opacity: 0.7;
  animation: ${statusAnimation} 1s ease-in-out infinite;

  &.Away {
    background: #ef6c00;
  }

  &.Invisible {
    background: #808080;
  }
`;

export const TeamListPopover = styled(MaterialPopover)`
  .MuiPaper-root {
    &:before {
      right: 100%;
      bottom: 0;
      content: "";
      position: absolute;
      border-top: 12px solid transparent;
      border-right: 12px solid red;
    }
  }

  .MuiButton-label {
    color: black;
    font-size: 12px;
  }
  @media (min-width: 951px) and (max-width: 1399px) {
    inset: 5px 15px !important;
  }
  @media (min-width: 1400px) {
    inset: 5px !important;
  }
`;

export const SubMenuList = styled.div`
  display: flex;
  gap: 5px;
  flex-direction: column;
`;
