import * as S from './Modal.styles'
import React, { forwardRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Slide from '@material-ui/core/Slide'
import { hideModal, showModal } from 'redux_toolkit/reducers/modals/modalsSlice';
import {
  AgentChat,
  ConfirmationAlert,
  TransferChat,
  ShortcutsModal,
  AssignAdmin,
  ClientChat
} from 'components';
import { RemoveUserModal } from 'pages/AgentsPage/components/RemoveUserModal/RemoveUserModal';
import { PartnerSettings } from 'pages/PartnersPage/components/PartnerSettings/PartnerSettings';
import { PartnerForm } from 'pages/PartnersPage/components/PartnerForm/PartnerForm';
import { AdminForm } from 'pages/AdminsPage/components/AdminForm/AdminForm';
import { AgentForm } from 'pages/AgentsPage/components/AgentForm/AgentForm';
import { ShortcutsForm } from 'pages/ShortcutsPage/components/ShortcutsForm/ShortcutsForm';
import { VisitorInfo } from 'pages/VisitorsPage/components/VisitorInfo/VisitorInfo';
import { AgentsModal } from 'components/Sidebar/components/Team/components/AgentsModal/AgentsModal';
import { updateChat } from 'redux_toolkit/reducers/chat/chatSlice';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const Modal = () => {
  const { modals } = useSelector(({ modal })=> modal);
  return (
    <>
      {modals.map((modal, index) =>
        <DialogInner
          key={index}
          modal={modal}
          hideModal={hideModal}
          showModal={showModal}
          props={modal.modalProps}
        />
      )}
    </>
  )
}

const DialogInner = ({ modal, showModal, hideModal, props }) => {
  const dispatch = useDispatch();
  if (!modal.isVisible || !modal.type) return null;
  return (
    <S.Dialog
      maxWidth={false}
      keepMounted
      open={modal.isVisible}
      onClose={()=> {
          dispatch(hideModal());
          dispatch(updateChat({chatId: modal.componentProps.chatId, data: {chatOpen: false}}));
        }
      }
      TransitionComponent={Transition}
      scroll='paper'
      {...props}
      className={`${modal.componentProps?.className? modal.componentProps.className:''}`}
    >
      <ModalComponentInner
        hideModal={hideModal}
        showModal={showModal}
        modal={modal}
      />
    </S.Dialog>
  )
}

const ModalComponentInner = ({ modal, hideModal, showModal }) => {
  const modalRoutes = {
    clientChat: ClientChat,
    internal: AgentChat,
    agentsModal: AgentsModal,
    confirmation: ConfirmationAlert,
    assignAdmin: AssignAdmin,
    partnerForm: PartnerForm,
    partnerSettings: PartnerSettings,
    removeUserModal: RemoveUserModal,
    shortcutsModal: ShortcutsModal,
    transferChat: TransferChat,
    visitorInfo: VisitorInfo,
    adminForm: AdminForm,
    agentForm: AgentForm,
    shortcutsForm: ShortcutsForm
  };
  const CustomComponent = modalRoutes[`${modal.type}`];

  if (CustomComponent) {
    return (
      <CustomComponent
        className="asssddd"
        hideModal={hideModal}
        showModal={showModal}
        { ...modal.componentProps }
      />
    )
  }
  return null
}