import * as S from "./AgentChat.styles";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { debounce } from "utils/functions";
import { hideModal } from "redux_toolkit/reducers/modals/modalsSlice";
import { ChatHeader } from "components/ClientChat/components/ContainerChat/components/ChatHeader/ChatHeader";
import { CurrentAgentChat } from "./components/CurrentAgentChat";


export const AgentChat = ({
  agent,
  isNewChat
}) => {
  const { chats } = useSelector(({ chat }) => chat);
  const currentUser = useSelector(({ userPartner }) => userPartner.userData);
  const chatData = useSelector(({ chat }) => {
    return [...chat.chats.values()].find(item => item.companion._id === agent._id);
  });

  const debaunceCallback = debounce(hideModal, 100);
  const [canClose, setCanClose] = useState(isNewChat);

  const allChats = useMemo(() => [...chats.values()], [chats]);

  useEffect(() => {
    if (allChats.some(chat => chat._id?.includes(currentUser._id) && chat._id?.includes(agent._id))) {
      setCanClose(false)
    }
  }, [agent._id, canClose, allChats, currentUser._id]);

  if (!agent) return null;

  if(!canClose && !allChats.some(chat => chat._id.includes(currentUser._id) && chat._id.includes(agent._id))) {
    debaunceCallback();
  }

  return (
    <>
      { chatData && <>
        <ChatHeader canEdit={true} chatData={chatData} />
        <S.AgentChatContainer>
          <S.AgentChatWrapper>
            <CurrentAgentChat chatData={chatData} agent={agent} />
          </S.AgentChatWrapper>
        </S.AgentChatContainer>
      </>}
    </>
  );
};