import { useClickOutside } from 'hooks/useClickOutside';
import React, { memo, useRef } from 'react';

export const ClickOutside = memo(
  ({ onOutsideClick = () => null, exceptSelectors, children, className }) => {
    const wrapperRef = useRef(null);
    useClickOutside(wrapperRef, onOutsideClick, exceptSelectors);

    return (
      <div ref={wrapperRef} data-click-outside className={className}>
        {children}
      </div>
    );
  }
);

ClickOutside.displayName = 'ClickOutside';
