import * as S from '../../../AgentsPage.styles';
import { memo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Switch from '@material-ui/core/Switch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { ROLES } from "configs/constants";
import { updatePartnerRequest } from 'socket/socket';
import { showModal } from 'redux_toolkit/reducers/modals/modalsSlice';
import { updateChatRequest } from 'redux_toolkit/reducers/partner/agents/agentsSlice';
import { changePartnerRequest } from 'redux_toolkit/reducers/current_partner/currentPartnerSlice';


export const HeaderButtons = memo(({userRole, request})=> {
  const dispatch = useDispatch();
  const [autoAssignData, setAutoAssignData] = useState(request);

  const changeChatRouting = ()=> {
    setAutoAssignData(!autoAssignData);
    updatePartnerRequest(!autoAssignData);
    dispatch(changePartnerRequest(!autoAssignData));
    dispatch(updateChatRequest(!autoAssignData));
  }

  useEffect(() => {
    request && setAutoAssignData(request);
  }, [request]);

  return (
    <S.HeaderContainerActions>
      <Switch
        checked={!!autoAssignData}
        onChange={() => {
          changeChatRouting();
        }}
        inputProps={{ "aria-label": "controlled" }}
        color="primary"
      />
      <span className="chat-routing">
        {autoAssignData ? "Manual" : "Auto"} Chat Routing
      </span>
      {userRole !== ROLES.TEAMLEADER && (
        <S.Plus
          onClick={() =>
            dispatch(showModal({
              type: 'agentForm',
              editableAgent: {
                limit: 4,
                name: "",
                email: "",
              },
            }))
          }
        >
          <FontAwesomeIcon icon={faPlus} classes="add-agent" />
        </S.Plus>
      )}
    </S.HeaderContainerActions>
  );
}, (prevProps, nextProps)=> {
    return(
      prevProps.userRole === nextProps.userRole &&
      prevProps.request === nextProps.request
    );
})