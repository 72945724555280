import { makeStyles } from '@material-ui/core'
import styled from 'styled-components/macro'


export const HistoryPageContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const FiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media (max-width:950px){
    & * {
      font-size: 12px;
    }
  }
`

export const FilterRow = styled.div`
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  @media (max-width:950px){
    .MuiSelect-select{
      font-size:12px;
    }
  }

  .SearchInput {
    width: 120px;

    div {
      top: calc(50% - 7px);
      left: 6px;
      width: 20px;
      height: 17px;

      svg {
        width: 20px;
        height: 17px;
      }
      @media (max-width:950px){
        font-size: 10px;
      }
    }

    input {
      font-size: 15px;
      padding: 7px 25px 7px 25px;
    }
  }
  @media (max-width:950px){
    .SearchInput {
      width: 100px;
  
      input {
        font-size: 12px;
        padding: 7px 25px 7px 25px;
      }
    }
    .SearchInput svg{
      width: 15px!important;
      height: 15px;
    }
  }
`

export const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`

export const ResultCount = styled.div`
  color: #555;
  margin-right: 10px;
`

export const TableContainer = styled.div`
  display: flex;
  gap: 15px;
  margin-top: 20px;
  @media (max-width: 600px) {
    flex-direction: column;
    font-size: 12px;
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-start;

  button {
    min-width: unset !important;
    font-size: 13px !important;
    padding: 8px 15px !important;
  }
  button:nth-child(2){
    border:1px solid;
  }
`

export const useStyles = makeStyles(() => ({
  noJoined: {
    background: "#fff9d1",
  },
  tbody: {
    maxHeight: 'calc(100vh - 284px)',
    overflow: 'auto',
    display: 'block',
    '& tr': {
      display: 'table',
      tableLayout: 'fixed',
      width: '100%'
    }
  },
  thead: {
    display: 'table',
    tableLayout: 'fixed',
    width: '100%'
  }
}));