import moment from "moment";

export const tableHead = [
  'Visitor id',
  'Agent',
  'Date',
  'Rating'
];

export const ratings = [
  {
    id: 6,
    label: 'Rating',
    value: -1
  },
  {
    id: 1,
    label: 1,
    value: 1
  },
  {
    id: 2,
    label: 2,
    value: 2
  },
  {
    id: 3,
    label: 3,
    value: 3
  },
  {
    id: 4,
    label: 4,
    value: 4
  },
  {
    id: 5,
    label: 5,
    value: 5
  },
];

export const types = [
  {
    id: 0,
    label: 'All',
    value: 0
  },
  {
    id: 1,
    label: 'Served',
    value: 5
  },
  {
    id: 2,
    label: 'Missed',
    value: 6
  }
];

export const formatTableData = (historyList) => {
  const formattedList = [];
  for (const history of historyList) {
    formattedList.push([
      history.visitorPartner[0]?.user?.name,
      history.agentPartner[0]?.user?.name,
      history.createdAt && moment(history.createdAt).format('DD/MM/YYYY HH:mm:ss'),
      history.rating !== 'undefined' ? history.rating : '-',
      history
    ])
  }

  return formattedList
}

export const getMessageUserName = (id, teamList, visitor) => {
  let resultName = {name: '', agentMessage: false};
  for (let item of teamList) {
    if(item._id === id) resultName = {name: item.name, agentMessage: true};
  }
  if(!resultName.agentMessage && id === visitor._id) resultName.name = visitor.name;

  return resultName;
}