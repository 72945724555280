import { axiosInstance } from "utils/axiosInstance";
import { superAdminURL } from "utils/urls";

export const getUsersCountServ = async () => {
  return axiosInstance
    .get(`${superAdminURL}/user-count`)
    .then((res) => res.data)
    .catch((err) => new Error(err.response ? err.response.data.message : "Getting User Counts failed"));
};

export const getPartnersCountServ = async () => {
  return await axiosInstance
    .get(`${superAdminURL}/partner-count`)
    .then((res) => res.data)
    .catch((err) => new Error(err.response ? err.response.data.message : "Getting Partners Count failed"))
};

export const getChatsCountServ = async (date) => {
  return axiosInstance
    .get(`${superAdminURL}/chat-count?dateFrom=${encodeURIComponent(date.dateFrom)}&dateTo=${encodeURIComponent(date.dateTo)}`)
    .then((res) => res.data)
    .catch((err) => new Error(err.response ? err.response.data.message : "Getting Chat Counts failed"));
};