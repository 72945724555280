import styled from "styled-components/macro";

export const ChatContainer = styled.div`
  width: 100%;
  padding: 10px;
`;

export const ChatLogsItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #9e9e9e;
  font-weight: 600;
  font-size: 14px;
`;

export const ChatLeftItem = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  min-height: 75px;
`;
