import React from 'react';
import { SelectBox } from 'ui'
import Switch from '@material-ui/core/Switch'
import { ROLES, USERS_ROLES, USER_STATUSES } from 'configs/constants';

let limits = [
  { id: 1, value: 1, label: 1 },
  { id: 2, value: 2, label: 2 },
  { id: 3, value: 3, label: 3 },
  { id: 4, value: 4, label: 4 },
  { id: 5, value: 5, label: 5 },
  { id: 6, value: 6, label: 6 },
  { id: 7, value: 7, label: 7 },
  { id: 8, value: 8, label: 8 },
  { id: 9, value: 9, label: 9 },
  { id: 10, value: 10, label: 10 }
];

export const headData = [
  "Name",
  "E-mail",
  "Role",
  "Status",
  "Chat limit",
  "Activity",
  "Actions",
];

export const formatTableData = (agentsList, getTableActions, nestedComponentsChange, userRole) => {
  let formattedList = [];
  for (const agent of agentsList) {
    if(userRole !== ROLES.TEAMLEADER){
      formattedList.push([
        agent?.name,
        agent?.email,
        USERS_ROLES[agent?.role],
        USER_STATUSES[agent?.status],
        <SelectBox
          onChange={(e) => { nestedComponentsChange('Select', { id: agent?._id, email: agent?.email, value: e.value }) }}
          list={limits}
          defaultValue={agent?.limit-1}
          placeholdeer='Chat Limit'
          type='outline'
        />,
        <Switch
          checked={!!agent?.status}
          onChange={() => { nestedComponentsChange('Switch', { id: agent?._id, email: agent.email, value: !agent.status }) }}
          inputProps={{ 'aria-label': 'controlled' }}
          color="primary"
        />,
        ...getTableActions(agent)
      ])
    }else{
      formattedList.push([
        agent?.name,
        agent?.email,
        USERS_ROLES[agent?.role],
        USER_STATUSES[agent?.status],
        <SelectBox
          onChange={(e) => {nestedComponentsChange('Select', { id: agent?._id, email: agent.email, value: e.value }) }}
          list={limits}
          inputProps={{ 'aria-label': 'controlled' }}
          defaultValue={agent?.limit-1}
          placeholdeer='Chat Limit'
          type='outline'
        />,
        ...getTableActions(agent)
      ])
    }

  }

  return formattedList
}