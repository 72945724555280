import styled from 'styled-components'
import MaterialButton from '@material-ui/core/Button'

export const HeaderContainerActions = styled.div`
  color:#fff;
  font-size: 17px!important;
  display: flex;
  justify-content: center;
  align-items: center;
  .MuiSwitch-colorPrimary.Mui-checked{
    color: #444!important;

  }
  .MuiSwitch-root {
    margin-top: 3px;
  }
  .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track{
    background-color:#999!important;
    opacity: 1!important;
  }
  
`

export const ShortcutsPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`

export const ActionsContainer = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
`

export const ActionButton = styled(MaterialButton)`
  min-width: 0px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
`

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  background-color: #eee;
  border-radius: 5px;
  .SearchInput {
    width: 200px;
  }
  
`

export const Plus = styled(MaterialButton)`
  color: #fff;
  margin-left: 20px;
  .fa-plus{
    font-size: 15px!important;
  }
`