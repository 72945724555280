import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getBannedVisitorServ,
  getPastChatHistoryServ,
  getVisitorChatsServ,
} from './visitorsApi';
import { uniqBy } from 'utils/functions';

const initialState = {
  loading: false,
  counts: {},
  list: [
    {
      _id: "",
      name: "",
      lastOnline: "",
      referrer: "",
      chats: "",
      pastVisits: "",
      browser:"",
      country: "",
      countryCode: "",
      ip: "",
      phone: "",
      platform: "",
      userAgent: ""
    },
  ],
  visitorsPartner: {
    active: [],
    assigned: [],
    idle: [],
    incoming: [],
    visitors: [],
  },
  bannedVisitors: [],
  visitorsActivities: {},
  visitorPastChats: new Map(),
  pastChatsHistory: new Map(),
};

export const getBannedVisitors = createAsyncThunk(
  'get_banned_visitor',
  async (partnerId)=> {
    const res = await getBannedVisitorServ(partnerId);
    if (res.success) return res;
    throw new Error('getBannedVisitors function Error', res.message)
  }
);

export const getVisitorChats = createAsyncThunk(
  'get_visitor_chats',
  async ({partnerId, visitorId})=> {
    const res = await getVisitorChatsServ(partnerId, visitorId);
    return {
      visitorId,
      chats: res.data
    };
  }
);

export const getPastChatHistory = createAsyncThunk(
  'get_past_chat_history',
  async ({partnerId, chatId})=> {
    const res = await getPastChatHistoryServ(partnerId, chatId);
    if (res.success) {
      return res.data;
    }
    throw new Error('getPastChatHistory function error', res.message);
  }
);

export const partnerVisitorSlice = createSlice({
  name: 'partner_visitor',
  initialState,
  reducers: {
    addVisitorToList: (state, action)=> {
      const list = [...state.list, action.payload];
      state.list = uniqBy(list, "_id");
    },
    addBannedVisitor: (state, action)=> {
      const currentIndex =  state.list.findIndex((item) => item._id === action.payload._id);
      state.list[currentIndex].banExpire = action.payload.banExpire;
      state.bannedVisitors = [...state.bannedVisitors, {...action.payload, name: state.list[currentIndex].name}];
    },
    removeBannedVisitor: (state, action)=> {
      state.bannedVisitors = state.bannedVisitors.filter((visitor) => visitor._id !== action.payload);
      const currentIndex =  state.list.findIndex((item) => item._id === action.payload);
      currentIndex > -1 && (state.list[currentIndex].banExpire = '');
    },
    addPartnersVisitors: (state, action)=> {
      state.visitorsPartner = action.payload || state.visitorsPartner;
    },
    updatePartnerVisitor: (state, action)=> {
      const data = action.payload;
      if(data.oldRole){
        state.visitorsPartner[data.oldRole] = state.visitorsPartner[data.oldRole].filter((item)=> item._id !== data._id);
      }
      if(data.role){
        state.visitorsPartner[data.role].push({...data});
      }
    },
    addVisitorPastChat: (state, action)=> {
      const id = action.payload.id;
      const pastChats = state.visitorPastChats.get(id);
      state.chats = state.visitorPastChats.set(id, {...pastChats || [], ...action.payload});
    },
    editVisitor: (state, action)=> {
      const currentIndex =  state.list.findIndex(
        (i) => i._id === action.payload.visitorId
      );
      state.list[currentIndex] = {
        ...state.list[currentIndex],
        ...action.payload.body
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBannedVisitors.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBannedVisitors.fulfilled, (state, action) => {
        state.loading = false;
        state.bannedVisitors = action.payload.data;
      })
      .addCase(getBannedVisitors.rejected, (state, action) => {
        state.loading = false;
        console.error("Error redux async func 'getBannedVisitors'", action.error)
      })
      .addCase(getVisitorChats.pending, (state) => {
        state.loading = true;
      })
      .addCase(getVisitorChats.fulfilled, (state, action) => {
        state.loading = false;
        state.visitorPastChats = state.visitorPastChats.set(action.payload.visitorId, action.payload.chats);
      })
      .addCase(getVisitorChats.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        console.error("Error redux async func 'getVisitorChats'", action.error);
      })
      .addCase(getPastChatHistory.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPastChatHistory.fulfilled, (state, action) => {
        state.loading = false;
        state.pastChatsHistory = state.pastChatsHistory.set(action.payload._id, action.payload);
      })
      .addCase(getPastChatHistory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        console.error("Error redux async func 'getPastChatHistory'", action.error);
      })
  },
});

export const {
  addBannedVisitor,
  removeBannedVisitor,
  addVisitorToList,
  updatePartnerVisitor,
  addPartnersVisitors,
  addVisitorPastChat,
  editVisitor,
} = partnerVisitorSlice.actions
export const selectPartnerVisitors = (state) => state.visitors;
export default partnerVisitorSlice.reducer;