import styled from 'styled-components/macro'
import MaterialPaper from '@material-ui/core/Paper'


export const WidgetsPageContainer = styled.div`
  display: flex;
  position:relative;
`

export const SelectWrapper = styled.div`
  width: fit-content; 
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  p {
    text-align: start;
    margin: 10px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.54);
  }
`

export const Paper = styled(MaterialPaper)`
  width: 100%;
  background: #fff;
`


