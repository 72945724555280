import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  connected: false,
  connect_error: false,
};

export const connectSlice = createSlice({
  name: 'connected',
  initialState,
  reducers: {
    connected: (state, action)=> {
      state.connect_error = false;
      state.connected = action.payload;
    },
    connectError: (state)=> {
      state.connect_error = true;
    },
    appTypesConnect: (state, action)=> {
      state.connect_error = false;
      state.connected = action.payload.isConnected;
    },
  },
});

export const {
  connectError,
  connected
} = connectSlice.actions;
export const selectConnect = (state) => state.connect;
export default connectSlice.reducer;