import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getPartnersListServ,
  addPartnerServ,
  editPartnerServ,
  updatePartnerStatusServ,
  updatePartnerFilePermissionServ,
  updatePartnerAssignServ,
  deletePartnerServ,
  removeAssignedAdminServ,
  getPartnersCountServ
} from './partnersApi';
import { setNotification } from 'redux_toolkit/reducers/notification/notificationSlice';
import { setPartnerInAdminId } from '../admins/adminsSlice';
import { getPartnerServ } from 'redux_toolkit/reducers/current_partner/currentPartnerApi';

const initialState = {
  loading: false,
  list: new Map()
}

export const getPartners = createAsyncThunk(
  'get_partners',
  async (empty, {dispatch})=> {
    let res = await getPartnersListServ();
    if(res.success) return res.data;
    dispatch(setNotification({type: res.message, message: 'error'}));
    throw new Error('getPartners function Error', res.message);
  }
);

export const getPartner = createAsyncThunk(
  'get_partner',
  async (id) => await getPartnerServ(id)
);

export const addPartner = createAsyncThunk(
  'add_partner',
  async (data, {dispatch}) => {
    const res = await addPartnerServ({...data});
    if(res.success){
      dispatch(setNotification({type: res.message, message: 'success'}));
      return res.data;
    }
    dispatch(setNotification({type: res?.message, message: 'error'}));
    throw new Error('addPartner function Error', res?.message);
  }
);

export const editPartner = createAsyncThunk(
  'edit_partner',
  async ({_id, name}, {dispatch}) => {
    const res = await editPartnerServ(_id, {name});
    if(res.success){
      dispatch(setNotification({type: res.message, message: 'success'}));
      return {
        _id,
        name
      }
    }
    dispatch(setNotification({type: res.message, message: 'error'}));
    throw new Error('editPartner function Error', res.message);
  }
);

export const updatePartnerStatus = createAsyncThunk(
  'update_partner_status',
  async ({_id, status}, {dispatch}) => {
    const res = await updatePartnerStatusServ(_id, status);
    if(res.success){
      dispatch(setNotification({type: res.message, message: 'success'}));
      return {
        _id,
        status
      }
    }
    dispatch(setNotification({type: res.message, message: 'error'}));
    throw new Error('updatePartnerStatus function Error', res.message);
  }
);

export const updatePartnerFilePermission = createAsyncThunk(
  'update_partner_file_storage',
  async ({_id, file}, {dispatch}) => {
    const res = await updatePartnerFilePermissionServ(_id, file);
    if(res.success){
      dispatch(setNotification({type: res.message, message: 'success'}));
      return {
        _id,
        file
      }
    }
    dispatch(setNotification({type: res.message, message: 'error'}));
    throw new Error('updatePartnerFilePermission function Error', res.message);
  }
);

export const updatePartnerAssign = createAsyncThunk(
  'update_partner_assign',
  async ({_id, admin}, {dispatch}) => {
    const res = await updatePartnerAssignServ(_id, admin._id);
    if(res.success){
      dispatch(setPartnerInAdminId({partnerId: _id, _id: admin._id}))
      dispatch(setNotification({type: res.message, message: 'success'}));
      return {
        _id,
        admin
      }
    }
    dispatch(setNotification({type: res.message, message: 'error'}));
    throw new Error('updatePartnerAssign function Error', res.message);
  }
);

export const deletePartner = createAsyncThunk(
  'delete_partner',
  async (partnerId, {dispatch}) => {
    const res = await deletePartnerServ(partnerId);
    if(res.success){
      dispatch(setNotification({type: res.message, message: 'success'}));
      return {
        _id: partnerId
      }
    }
    dispatch(setNotification({type: res.message, message: 'error'}));
    throw new Error('deletePartner function Error', res.message);
  }
);

export const removeAssignedAdmin = createAsyncThunk(
  'remove_assigne',
  async (info, {dispatch}) => {
    const res = await removeAssignedAdminServ(info.partnerId, info.adminId);
    if(res.success){
      dispatch(setNotification({type: res.message, message: 'success'}));
      return {
        _id: info.partnerId,
        adminId: info.adminId
      }
    }
    dispatch(setNotification({type: res.message, message: 'error'}));
    throw new Error('removeAssignedAdmin function Error', res.message);
  }
);

export const getPartnersCount = createAsyncThunk(
  'get_partners_count',
  async () => await getPartnersCountServ()
);


export const partnersSlice = createSlice({
  name: 'partners',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPartners.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPartners.fulfilled, (state, action) => {
        state.loading = false;
        state.list = new Map(action.payload.map(item => {
          return [item._id, item];
        }));
      })
      .addCase(getPartners.rejected, (state, action) => {
        state.loading = false;
        console.error("Error redux async func 'getPartnersList'", action.error);
      })
      .addCase(getPartner.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPartner.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload.data;
      })
      .addCase(getPartner.rejected, (state, action) => {
        state.loading = false;
        console.error("Error redux async func 'getPartner'", action.error);
      })
      .addCase(addPartner.pending, (state)=> {
        state.loading = true
      })
      .addCase(addPartner.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload?._id) {
          state.list = state.list.set(action.payload?._id, action.payload);
        }
      })
      .addCase(addPartner.rejected, (state, action) => {
        state.loading = false;
        console.error("Error redux async func 'addPartner'", action.error);
      })
      .addCase(editPartner.pending, (state)=> {
        state.loading = true
      })
      .addCase(editPartner.fulfilled, (state, action) => {
        state.loading = false;
        const partner = state.list.get(action.payload._id);
        state.list= state.list.set(action.payload._id, {...partner, ...action.payload });
      })
      .addCase(editPartner.rejected, (state, action) => {
        state.loading = false;
        console.error("Error redux async func 'editPartner'", action.error);
      })
      .addCase(updatePartnerStatus.pending, (state)=> {
        state.loading = true
      })
      .addCase(updatePartnerStatus.fulfilled, (state, action) => {
        state.loading = false;
        const partner = state.list.get(action.payload._id)
        partner.status = action.payload.status;
        state.list= state.list.set(action.payload._id, {...partner, ...action.payload });
      })
      .addCase(updatePartnerStatus.rejected, (state, action) => {
        state.loading = false;
        console.error("Error redux async func 'updatePartnerStatus'", action.error);
      })
      .addCase(updatePartnerFilePermission.pending, (state)=> {
        state.loading = true
      })
      .addCase(updatePartnerFilePermission.fulfilled, (state, action) => {
        state.loading = false;
        const partner = state.list.get(action.payload._id);
        partner.file = action.payload.file || partner.file;
        state.list= state.list.set(action.payload._id, {...partner, ...action.payload });
      })
      .addCase(updatePartnerFilePermission.rejected, (state, action) => {
        state.loading = false;
        console.error("Error redux async func 'updatePartnerFilePermission'", action.error);
      })
      .addCase(updatePartnerAssign.pending, (state)=> {
        state.loading = true
      })
      .addCase(updatePartnerAssign.fulfilled, (state, action) => {
        state.loading = false;
        const partnerId = action.payload._id;
        const data = [...state.list.values()].find(item => item._id === partnerId);
        const adminList = data?.admin || [];
        state.list = state.list.set(partnerId, {...data, admin: [...adminList, action.payload.admin]});
      })
      .addCase(updatePartnerAssign.rejected, (state, action) => {
        state.loading = false;
        console.error("Error redux async func 'updatePartnerAssign'", action.error);
      })
      .addCase(deletePartner.pending, (state)=> {
        state.loading = true
      })
      .addCase(deletePartner.fulfilled, (state, action) => {
        state.loading = false;
        state.list.delete(action.payload._id);
      })
      .addCase(deletePartner.rejected, (state, action) => {
        state.loading = false;
        console.error("Error redux async func 'deletePartner'", action.error);
      })
      .addCase(removeAssignedAdmin.pending, (state)=> {
        state.loading = true
      })
      .addCase(removeAssignedAdmin.fulfilled, (state, action) => {
        state.loading = false;
        const partnerId = action.payload._id;
        const data = [...state.list.values()].find(item => item._id === partnerId);
        const admins = data.admin.filter(item=> item._id !== action.payload.adminId);
        state.list = state.list.set(partnerId, {...data, admin: admins});
      })
      .addCase(removeAssignedAdmin.rejected, (state, action) => {
        state.loading = false;
        console.error("Error redux async func 'removeAssignedAdmin'", action.error);
      })
  },
});

export const selectPartners = (state) => state.partners;
export default partnersSlice.reducer;