import { axiosInstance } from '../../../utils/axiosInstance';
import { usersURL, partnerURL } from "../../../utils/urls";


export const updateUserStatusServ = async (partnerId, status, userId)=> {
  return await axiosInstance
    .put(`${partnerURL}/${partnerId}/users/${userId}/status`, { status })
    .then((res) => res.data)
    .catch((err) => new Error(err.response ? err.response.data.message : "Updating USER(AGENT) STATUS failed"))
}

export const partnerLoginServ = async (partnerId, userId)=> {
  return await axiosInstance
    .post(`${partnerURL}/${partnerId}/login`, { userId })
    .then((res) => res.data)
    .catch((err) => new Error(err.response ? err.response.data.message : "Updating USER(AGENT) STATUS failed"))
}

export const logoutServ = async ()=> {
  return await axiosInstance
    .get(`${usersURL}/logout`)
    .then((res) => res.data)
    .catch((err) => new Error(err.response ? err.response.data.message : "LOGOUT failed"));
}

export const uploadImageServ = async (partnerId, dataFile)=> {
  return await axiosInstance
    .post(`${partnerURL}/${partnerId}/users/image`, dataFile, {
      headers: {
        'accept': 'application/json',
        'Accept-Language': 'en-US,en;q=0.8',
        'Content-Type': `multipart/form-data; boundary=${dataFile._boundary}`,
      }
    })
    .then((res) => res.data)
    .catch((err) => new Error(err.response ? err.response.data.message : "Upload PROFILE IMAGE failed"));
}

export const removeImageServ = async (data)=> {
  return await axiosInstance
    .delete(`${partnerURL}/${data.partnerId}/users/image/${data._id}?${Object.keys(data)[0]}=${Object.values(data)[0]}`)
    .then((res) => res.data)
    .catch((err) => new Error(err.response ? err.response.data.message : "Failed Delete Image"));
}

export const updateUserProfileServ = async (data)=> {
  return await axiosInstance
    .put(`${usersURL}/user/profile`, data)
    .then((res) => res.data)
    .catch((err) => new Error(err.response ? err.response.data.message : "Updating USER(AGENT) Profile failed"))
}
