import * as Yup from 'yup'


export const initialValues = {
  message: '',
  tag: '',
  options: ['']
}

const requiredErrorMessage = 'This field is required'

export const validationSchema = Yup.object().shape({
  tag: Yup.string()
    .required(requiredErrorMessage),
  message: Yup.string()
    .required(requiredErrorMessage)
})
