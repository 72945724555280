import { axiosInstance } from '../../../../utils/axiosInstance';
import { partnerURL } from '../../../../utils/urls';

export const addShortcutServ = async (partnerId, dataBody)=> {
  return await axiosInstance
    .post(`${partnerURL}/${partnerId}/shortcuts`, dataBody)
    .then((res) => res.data)
    .catch((err) => new Error(err.response?.data.message || "Create SHORTCUT failed"));
}

export const getShortcutsServ = async (partnerId)=> {
  return await axiosInstance
    .get(`${partnerURL}/${partnerId}/shortcuts`)
    .then((res) => res.data)
    .catch((err) => new Error(err.response?.data.message || "Get SHORTCUT failed"));
}

export const editShortcutServ = async (partnerId, shortcutId, shortchut)=> {
  return await axiosInstance
    .put(`${partnerURL}/${partnerId}/shortcuts/${shortcutId}`, shortchut)
    .then((res) => res.data)
    .catch((err) => new Error(err.response?.data.message || "Edit SHORTCUT falied"));
}

export const deleteShortcutServ = async (partnerId, shortcutId)=> {
  return await axiosInstance
    .delete(`${partnerURL}/${partnerId}/shortcuts/${shortcutId}`)
    .then((res) => res.data)
    .catch((err) => new Error(err.response?.data.message || "Delete SHORTCUT failed"));
}