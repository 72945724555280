import { withStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";

const radioBoxStyles = () => ({
  root: {
    "&$checked": {
      color: "inherit",
    },
    padding: "4px"
  },
  checked: {},
});

export const RadioBox = withStyles(radioBoxStyles)(Radio);