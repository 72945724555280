import axios from 'axios';

function getAccessToken() {
  const pathParts = window.location.pathname.split('/');
  if (!['login', 'partners', 'superadmin', 'home'].includes(pathParts[1])) {
    const partnerToken = localStorage.getItem(pathParts[1]) || '';
    return partnerToken;
  } else {
    return localStorage.getItem('access_token') || '';
  }
}

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = getAccessToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token.split('|')[0]}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);


