import * as S from './ResetPasswordPage.styles'
import React from 'react'
import { useDispatch } from 'react-redux'
import { Formik } from 'formik'
import PasswordIcon from 'images/passwordIcon.png'
import { Button, Input } from 'ui'
import { initialValues } from './ResetPasswordPage.config'
import { resetPassword } from 'redux_toolkit/reducers/user/userSlice'


export const ResetPasswordPage = ({
  error
}) => {
  const dispatch = useDispatch();

  return (
    <>
      <S.FormTitle>
        Reset Password
      </S.FormTitle>
      <Formik
        onSubmit={values => dispatch(resetPassword(values))}
        initialValues={initialValues}
      >
        {
          ({
            handleSubmit,
            handleChange
          }) => {
            return (
              <S.Form>
                {
                  error &&
                    <S.FormError>
                      { error }
                    </S.FormError>
                }
                <Input
                  id='password'
                  type='password'
                  name='password'
                  onChange={handleChange}
                  icon={PasswordIcon}
                  placeholder='New password'
                />
                <Input
                  type='password'
                  id='confirmPassword'
                  name='confirmPassword'
                  onChange={handleChange}
                  icon={PasswordIcon}
                  placeholder='Confirm password'
                />
                <S.ButtonsContainer>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </S.ButtonsContainer>
              </S.Form>
            )
          }
        }
      </Formik>
    </>
  )
}