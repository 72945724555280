import { axiosInstance } from '../../../../utils/axiosInstance';
import { superAdminURL, superPartnersURL } from '../../../../utils/urls';

export const getPartnersListServ = async () => {
  return await axiosInstance
    .get(`${superPartnersURL}`)
    .then((res) => res.data)
    .catch((err) => new Error(err.response ? err.response.data.message : "Getting Partners List failed"));
};

export const addPartnerServ = async (dataBody) => {
  return await axiosInstance
    .post(`${superPartnersURL}`, dataBody)
    .then((res) => res.data)
    .catch((err) => new Error(err.response ? err.response.data.message : "Create Partner failed"));
};

export const editPartnerServ = async (id, dataBody) => {
  return await axiosInstance
    .put(`${superPartnersURL}/${id}`, dataBody)
    .then((res) => res.data)
    .catch((err) => new Error(err.response ? err.response.data.message : "Edit Admin failed"));
};

export const updatePartnerStatusServ = async (id, status) => {
  return await axiosInstance
    .put(`${superPartnersURL}/${id}/status`, {status} )
    .then((res) => res.data)
    .catch((err) => new Error(err.response ? err.response.data.message : "Updating Partner Status failed"));
};

export const updatePartnerFilePermissionServ = async (id, file) => {
  return await axiosInstance
    .put(`${superPartnersURL}/${id}/file-permission`, {file})
    .then((res) => res.data)
    .catch((err) => new Error(err.response ? err.response.data.message : "Updating Partner File Permission failed"));
};

export const updatePartnerAssignServ = async (id, adminId) => {
  return await axiosInstance
    .put(`${superPartnersURL}/${id}/assign`, {adminId})
    .then((res) => res.data)
    .catch((err) => new Error(err.response ? err.response.data.message : "Updating Partner Assign failed"));
};

export const deletePartnerServ = async (id) => {
  return await axiosInstance
    .delete(`${superPartnersURL}/${id}`)
    .then(res => res.data)
    .catch((err) => new Error(err.response ? err.response.data.message : "Delete Partner failed"))
}

export const removeAssignedAdminServ = async (partnerId, adminId) => {
  return await axiosInstance
    .delete(`${superPartnersURL}/${partnerId}/admins/${adminId}`)
    .then((res) => res.data)
    .catch((err) => new Error(err.response ? err.response.data.message : "Removing Assigned Admin failed"));
}

export const getPartnersCountServ = async () => {
  return await axiosInstance
    .get(`${superAdminURL}/partner-count`)
    .then((res) => res.data)
    .catch((err) => new Error(err.response ? err.response.data.message : "Getting Partners Count failed"))
};
