import {
HomePage,
PersonalPage,
ShortcutsPage,
WidgetsPage,
ChoosePartnerPage,
VisitorsPage,
BannedPage,
HistoryPage,
LoginPage,
ForgotPasswordPage,
ResetPasswordPage,
ConfirmPasswordForm,
AgentsPage,
SuperAdminHomePage,
PartnersPage,
AdminsPage
} from "pages"
import { Navigate, Route, Routes } from "react-router-dom"
import {
  LayoutUsers,
  LayoutNotAuth,
  LayoutPartners,
  LayoutSuperAdmin
} from "../layouts";


export const Pages = () => {
  return (
    <Routes>
      {/* Public Routes */}
      <Route path="/" element={<LayoutNotAuth />}>
        <Route index element={<Navigate to="login" />} />
        <Route path="login" element={<LoginPage />} />
        <Route path="forgot-password" element={<ForgotPasswordPage />} />
        <Route path="reset-password" element={<ResetPasswordPage />} />
        <Route path="confirm-password" element={<ConfirmPasswordForm />} />
        <Route path="*" element={<Navigate to="login" />} />
      </Route>

      {/* Partner Selection Route */}
      <Route path="partners" element={<LayoutPartners />}>
        <Route index element={<ChoosePartnerPage />} />
        <Route path="*" element={<Navigate to="partners" />} />
      </Route>

      {/* SuperAdmin Login */}
      <Route path="superadmin/login" element={<LayoutNotAuth />}>
        <Route index element={<LoginPage />} />
        <Route path="*" element={<Navigate to="/superadmin/login" />} />
      </Route>

      {/* Authenticated Super Admin Routes */}
      <Route path="superadmin" element={<LayoutSuperAdmin />}>
        <Route index element={<Navigate to="home" />} />
        <Route path="home" element={<SuperAdminHomePage />} />
        <Route path="partners" element={<PartnersPage />} />
        <Route path="admins" element={<AdminsPage />} />
        <Route path="*" element={<Navigate to="home" />} />
      </Route>

      {/* Authenticated User Routes */}
      <Route path=":partnerKey" element={<LayoutUsers />}>
        <Route index element={<Navigate to="home" />} />
        <Route path="home" element={<HomePage />} />
        <Route path="history" element={<HistoryPage />} />
        <Route path="personal" element={<PersonalPage />} />
        <Route path="agents" element={<AgentsPage />} />
        <Route path="banned" element={<BannedPage />} />
        <Route path="shortcuts" element={<ShortcutsPage />} />
        <Route path="visitors" element={<VisitorsPage />} />
        <Route path="widget" element={<WidgetsPage />} />
        <Route path="*" element={<Navigate to="home" />} />
      </Route>
    </Routes>
  );
};