import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  type: '',
  message: '',
  show: false,
  notificationTab: false
}

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setNotification: (state, action)=> {
      state.type = action.payload.type;
      state.message = action.payload.message;
      state.show = action.payload.message? true : false;
    },
    notificationTabOpen: (state, action)=> {
      state.notificationTab = action.payload;
    }
  }
});

export const {
  setNotification,
  notificationTabOpen
} = notificationSlice.actions;
export const selectNotification = (state) => state.notification;
export default notificationSlice.reducer;