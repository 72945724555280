import * as S from './AgentsModal.styles';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatTableData, TABLE_HEADERS } from './AgentsModal.config';
import { Table } from 'components';
import { faComment } from '@fortawesome/free-solid-svg-icons';
import { ROLES, STATUS } from 'configs/constants';
import { internalChat } from 'socket/socket';
import { showModal, hideModal } from 'redux_toolkit/reducers/modals/modalsSlice';


export const AgentsModal = () => {
  const dispatch = useDispatch();
  const {partnerAgents, partnerTeamleads} = useSelector(({ agents }) => agents);
  const currentUser = useSelector(({ userPartner }) => userPartner.userData);

  const teammates = partnerTeamleads.concat(partnerAgents);
  const filtredTeamList = useMemo(() => {
    return teammates.filter(agent => agent.status !== STATUS.OFFLINE &&  agent.status !== STATUS.INACTIVE && currentUser._id !== agent._id);
  }, [teammates, currentUser])

  const getTableActions = (agent) => (
    <S.ActionButton
      onClick={() => {
        if (currentUser.role === ROLES.ADMIN) return;
        internalChat(agent._id);
        dispatch(showModal({type: 'internal', agent, isNewChat: true }))}
      }
      disabled={currentUser.role === ROLES.ADMIN}
    >
      <FontAwesomeIcon icon={faComment} style={{
        width: 15,
        height: 15,
        color: currentUser.role === ROLES.ADMIN ? 'grey' : 'rgb(15, 61, 86)'
      }}/>
    </S.ActionButton>
  )

  return (
    <S.AgentsModalContainer>
      <Table
        hideModal={hideModal}
        cardHeaderText='Agents'
        tableHead={TABLE_HEADERS[currentUser.role]}
        tableData={formatTableData(filtredTeamList, getTableActions, currentUser)}
      />
    </S.AgentsModalContainer>
  )
}