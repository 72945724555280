import cn from 'classnames';
import * as S from './PreChatForm.styles'
import React, { useState, useRef, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Button, TextField } from '@material-ui/core'
import { AttachFile as AttachFileIcon, Send as SendIcon, Remove as RemoveIcon } from '@mui/icons-material'
import DefaultAvatar from 'images/widget/user-default.png';
import EndChatIcon from 'images/widget/end-chat.svg';
import { WidgetRect, WidgetCircular } from 'icons';
import { setNewMessageApper } from 'redux_toolkit/reducers/partner/widget/widgetSlice';


export const PreChatForm = ({
  chatConfig
}) => {
  const dispatch = useDispatch();
  const { currentPartner } = useSelector(({ currentPartner })=> currentPartner);
  const { conversation } = useSelector(({ widget }) => widget);
  const chatConfigState = useSelector(({ chat }) => chat.config);
  chatConfig = chatConfig? chatConfig : chatConfigState;

  const scrolledView = useRef(null)
  const [message, setMessage] = useState('');

  const classnames = useMemo(() => ({
    squared: chatConfig.widgetType === 'bar',
    circular: chatConfig.widgetType === 'circular',
  }) , [chatConfig.widgetType]);

  const textColors = useMemo(() => ({
    agent: chatConfig.agentTextColor,
    visitor: chatConfig.visitorTextColor,
    bot: chatConfig.botTextColor
  }), [chatConfig]);

  const bgColors = useMemo(() => ({
    agent: chatConfig.messagesBackgroundColors.our,
    visitor: chatConfig.messagesBackgroundColors.client,
    bot: 'transparent'
  }), [chatConfig])

  const mobileWigetClassname = useMemo(() => chatConfig.windowSize === 'overlay' ? 'overlay' : 'popout', [chatConfig.windowSize])
  
  const addNewMessage = () => {
    dispatch(setNewMessageApper({
      type: 'visitor',
      date: `${new Date().getHours()}:${new Date().getMinutes()}`,
      text: message
    }))
    setTimeout(() => {
      scrolledView.current.scroll({ behavior: 'smooth', top: scrolledView.current.scrollHeight })
      setMessage('')
    }, 0);
  }

  return (
    <>
      <S.PreChatFormContainer className={mobileWigetClassname}>
        <S.PreChatFormHeader
          headerLeftColor={chatConfig.headerLeftColor}
          headerRightColor={chatConfig.headerRightColor}
          degree={chatConfig.headerDegree}
          className={mobileWigetClassname}
        >
          <S.PreChatFormHeaderInner>
            <S.PreChatFormImg >
              <img src={DefaultAvatar} alt="Test agent"/>
            </S.PreChatFormImg>
            <S.PreChatFormHeaderText>
              <h2>{chatConfig.title || currentPartner?.name}</h2>
              <div>
                <S.PreChatFormStatus />
                <p>Test agent</p>
              </div>
            </S.PreChatFormHeaderText>
          </S.PreChatFormHeaderInner>
          {
            chatConfig.windowSize === 'overlay' &&
              <Button>
                <RemoveIcon />
              </Button>
          }
        </S.PreChatFormHeader>
        <S.PreChatCoversationContent
          bgColor={chatConfig.chatBadgeBackgroundColor}
          borderColor={chatConfig.headerLeftColor}
          className={mobileWigetClassname}
        >
          <S.PreChatMessagesBlock ref={scrolledView}>
            {
              conversation.map((e, index) => {
                return (
                  <S.MessageWrapper
                    key={index}
                    position={e.type === 'agent' ? 'flex-start' : 'flex-end'}
                  >
                    <S.MessageText
                      dir='auto'
                      bgColor={bgColors[e.type]}
                      textColor={textColors[e.type]}
                      badgeColor={chatConfig.chatBadgeBackgroundColor}
                      className={cn({
                        agentMessage: e.type === 'agent',
                        ourMessage: e.type === 'visitor',
                        botMessage: e.type === 'bot'
                      })}
                    >
                      {e.text}
                      <S.MessageDate textColor={textColors.bot} className={cn({ messageDate: true, botMessage: e.type === 'bot'})}>{e.date}</S.MessageDate>
                    </S.MessageText>
                  </S.MessageWrapper>
                )
              })
            }
          </S.PreChatMessagesBlock>
          <S.PreChatTextAreaBlock>
            <TextField
              variant='outlined'
              placeholder=''
              multiline
              minRows={3}
              maxRows={4}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            <S.IconBox>
              <AttachFileIcon disabled className="attach-icon" />
              <SendIcon onClick={addNewMessage} />
            </S.IconBox>
          </S.PreChatTextAreaBlock>
          <S.PreChatEndWrapper>
            <img src={EndChatIcon} alt="end chat"/>
            <p>End chat</p>
          </S.PreChatEndWrapper>
        </S.PreChatCoversationContent>
      </S.PreChatFormContainer>
      <S.ChatBubbleContainer>
        <S.ChatBubbleContent
          colors={{
            right: chatConfig.widgetRightColor,
            left: chatConfig.widgetLeftColor }
          }
          degree={chatConfig.widgetDegree}
          type={chatConfig.widgetGradientType}
          className={cn(classnames)}
        >
          <S.ChatIcon bgColor={chatConfig.widgetIconColor} className={cn(classnames)}>
            {chatConfig.widgetType === 'bar' ? <WidgetRect /> : <WidgetCircular />}
          </S.ChatIcon>
        </S.ChatBubbleContent>
        <S.ChatBubbleContent
          colors={{
            right: chatConfig.widgetRightColor,
            left: chatConfig.widgetLeftColor }
          }
          degree={chatConfig.widgetDegree}
          type={chatConfig.widgetGradientType}
          className={cn(classnames)}
        >
          <S.ChatIcon bgColor={chatConfig.widgetIconColor}>
            <S.Minimized className={cn(classnames)} />
          </S.ChatIcon>
        </S.ChatBubbleContent>
      </S.ChatBubbleContainer>
    </>
  )
}

PreChatForm.defaultProps = {
  chatConfig: {}
}