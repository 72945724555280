import styled from 'styled-components/macro'
import MaterialAppBar from '@material-ui/core/AppBar';
import MaterialTypography from '@material-ui/core/Typography';
import MaterialButton from '@material-ui/core/Button';
import MaterialToolbar from '@material-ui/core/Toolbar';
import MaterialPopover from '@material-ui/core/Popover';
import MaterialCollapse from "@material-ui/core/Collapse";

export const ContentContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  background: #eee;
  min-height: 100vh;
`

export const ChatsContainer = styled(MaterialCollapse)`
  display: flex;
  width: 100%;
  flex-direction: column;
  max-height: 100px;
  overflow: auto;
  &.MuiPaper-root{
    left:6%;
  }
`;

export const ListItemText = styled.div`
  text-transform: none;
  font-weight: bold;
`
export const ChatsContent = styled.div`
  text-transform: none;
  color:unset;
`

export const AgentName = styled.div`
  text-transform: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 5px;
  cursor: pointer;
  color:unset;
  font-size: 18px;
  font-weight: bold;
  color: #0f3d56;
  .isOpened {
    transform: rotateX(180deg);
  }
`

export const ListItem = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 3px 8px;
  cursor: pointer;
  margin: 5px 0;
`

export const AppBar = styled(MaterialAppBar)`
  box-shadow: none;
  background: unset;
`

export const Typography = styled(MaterialTypography)`
  color: #555555;
  font-size: 1rem;
  @media (max-width:950px){
    font-size:10px;
  }
  @media (min-width:1600px){
    font-size:1.5rem;
  }
`

export const Button = styled(MaterialButton)`
   font-size: 1rem;

  .MuiButton-label {
    display: flex;
    color: #555;
    justify-content: center;
    align-items: center;
    img {
      margin-left: 8px;
    }
  }
  @media (min-width:1600px){
    .MuiButton-label {
      font-size:1.3rem;
    }
  }
  @media (max-width:950px){
    .MuiButton-label {
      font-size:10px;
    }
    min-width: 50px;

    svg{
     width: 16px!important;
     height: 16px!important;
    }
  }

`

export const ButtonsList = styled.div`
  display: flex;
  gap: 20px;
  @media (max-width:950px){
    gap: 5px;
  }
`

export const Toolbar = styled(MaterialToolbar)`
  display: flex;
  justify-content: space-between;
  @media (max-width:950px){
    padding-left:26px;
  }
`

export const AuthLayout = styled.div`
  display: flex;
  height: 100%;
`

export const MainContainer = styled.div`
  padding: 24px;
`

export const Avatar = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #bdbdbd;
  display: flex;
  justify-content: center;
  align-items: center;
  position:relative;
`
  export const AvatarImage=styled.img`
  width:100%;
  height:100%;
  border-radius: 50%;
  position:absolute;
  margin-left:unset!important;
  `

export const UsernameLetter = styled.div`
  color: #fff;
  font-size: 20px;
  margin-top: 5px;
  
`

export const ChatsButton = styled(MaterialButton)`
  z-index: 1200;
  position: fixed;
  min-width: 0px;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  bottom: 10px;
  &.sidebarClosed {
    left: 120px;
  }
  .MuiSvgIcon-root {
    width: 32px;
    height: 32px;
  }
  @media (max-width:950px){
    width: 50px;
    height: 50px;
  }
`

export const EmptyChatsListText = styled(MaterialTypography)`
  color: #0f3d56;
  font-size: 18px;
`

export const ChatsListPopover = styled(MaterialPopover)`
  .MuiPaper-root {
    padding: 18px !important;
    &:before {
      right: 100%;
      bottom: 0;
      content: "";
      position: absolute;
      border-top: 12px solid transparent;
      border-right: 12px solid red;
    }
  }
`