import * as Yup from 'yup'


const requiredErrorMessage = 'This field is required'

export const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'The name must be a string min 3 characters.')
    .required(requiredErrorMessage),
  tagline: Yup.string()
    .min(3, 'The tagline name must be a string min 3 characters.'),
  email: Yup.string()
    .required(requiredErrorMessage)
    .email('Email is not valid')
});