import * as S from './RemoveUserModal.styles'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import { Button } from 'ui'
import { hideModal } from 'redux_toolkit/reducers/modals/modalsSlice'
import { removeUser } from 'redux_toolkit/reducers/partner/agents/agentsSlice'


export const RemoveUserModal = ({
  agentId
}) => {
  const dispatch = useDispatch();
  const { partnerId } = useSelector(({userPartner})=> userPartner.userData);

  const onDelete = () => {
    dispatch(removeUser({ agentId, partnerId }));
    dispatch(hideModal());
  }

  return (
    <S.RemoveUserModalContainer>
      <Typography>
        Are you sure to want to delete the User. ?
      </Typography>
      <S.ButtonsContainer>
        <Button
          color='secondary'
          onClick={()=> dispatch(hideModal())}
        >
          Cancel
        </Button>
        <Button onClick={onDelete}>
          Accept
        </Button>
      </S.ButtonsContainer>
    </S.RemoveUserModalContainer>
  )
}