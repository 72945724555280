import { getLimit } from 'components/Table/Table.config';
import { axiosInstance } from '../../../../utils/axiosInstance';
import { partnerURL } from '../../../../utils/urls';

export const getBannedVisitorServ = async (partnerId)=> {
  const limit = getLimit();
  return await axiosInstance
    .get(`${partnerURL}/${partnerId}/banned-visitors?limit=${limit}`)
    .then((res) => res.data)
    .catch((err) => new Error(err.response?.data.message || "Get BANNED VISITORS failed!"))
}

export const getVisitorChatsServ = async (partnerId, visitorId)=> {
  return await axiosInstance
    .get(`${partnerURL}/${partnerId}/visitors/${visitorId}/past-chats`)
    .then((res) => res.data)
    .catch((err) => new Error(err.response?.data.message || "Get VISITOR CHATS failed"))
}

export const getPastChatHistoryServ = async (partnerId, chatId)=> {
  return await axiosInstance
    .get(`${partnerURL}/${partnerId}/chats/${chatId}`)
    .then((res) => res.data)
    .catch((err) => new Error(err.response?.data.message || "Get PAST CHAT HISTORY Visitor failed"))
}