import cx from "classnames";
import * as S from "./Team.styles";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faUserFriends} from "@fortawesome/free-solid-svg-icons";
import { ROLES, USER_STATUSES, STATUS } from "configs/constants";
import { internalChat } from "socket/socket";
import { showModal } from "redux_toolkit/reducers/modals/modalsSlice";
import { teamItemsOpen } from "redux_toolkit/reducers/app/appSlice";
import { getAgentsByPartnerId } from "redux_toolkit/reducers/partner/agents/agentsSlice";


export const Team = ({ sidebarClosed }) => {
  const dispatch = useDispatch()
  const { teamItemsOpened, isSidebarOpened } = useSelector(({ app })=> app);
  const teamleadsList = useSelector(({ agents }) => agents.partnerTeamleads);
  const user = useSelector(({ userPartner }) => userPartner.userData);
  const [anchorEl, setAnchorEl] = useState(teamItemsOpened);
  const isSubMenuOpened = Boolean(anchorEl);

  const startInternalChat = (receiverId) => {
    internalChat(receiverId);
    dispatch(showModal({
      type: 'internal',
      agent: teamleadsList.find((a) => a._id === receiverId),
      isNewChat: true,
    }));
  };

  const teamLeads = useMemo(() => {
    return teamleadsList?.filter((item) => item.status !== STATUS.OFFLINE && item.status !== STATUS.INACTIVE && item._id !== user._id) || []
  }, [teamleadsList, user._id])

  useEffect(()=>{
    isSidebarOpened &&  setAnchorEl(null) 
  },[isSidebarOpened]);

  const opened=(e)=>{
    if(isSubMenuOpened){
      setAnchorEl(!anchorEl);
      dispatch(teamItemsOpen(!anchorEl));
    }else{
      setAnchorEl(e.currentTarget);
      dispatch(teamItemsOpen(window.innerWidth < 950 ? !!anchorEl : !anchorEl));
    }
  }

  const id = isSubMenuOpened ? 'team-popover' : undefined;

  return (
    <>
      <S.TeamContainer
        button
        onClick={opened}
        className={cx({ sidebarClosed })}
        aria-describedby={id}
      >
        <S.TeamContent className={cx({ sidebarClosed })}>
        <FontAwesomeIcon icon={faUserFriends} />
        {!sidebarClosed && <span className='teamText'> Team </span> }
        </S.TeamContent> 
        <S.Icon  className={cx({ sidebarClosed })}>
          {isSubMenuOpened ? (
            <KeyboardArrowUpIcon />
          ) : (
            <KeyboardArrowDownIcon />
          )}
        </S.Icon>
      </S.TeamContainer>
      {!sidebarClosed ? (
        <S.TeamContainerItems in={isSubMenuOpened} unmountOnExit>
          <S.ListItem onClick={() => {
            dispatch(getAgentsByPartnerId(user.partnerId))
            dispatch(showModal({type: 'agentsModal'}));
          }} 
            className="popover"
            disableGutters={true}
          >
            <S.ListItemText className="view_all_txt">View All</S.ListItemText>
          </S.ListItem>
          {teamLeads?.map((lead) => {
            return (
              <S.ListItem
                onClick={() => user.role !== ROLES.ADMIN && startInternalChat(lead._id)}
                disableGutters={true}
                key={lead._id}
                className={`${cx({ sidebarClosed })}`}
              >
                <S.AgentStatus className={cx(USER_STATUSES[lead.status])} />
                <S.ListItemText disabled={user.role === ROLES.ADMIN} className="agent_name">{lead.name}</S.ListItemText>
              </S.ListItem>
            );
          })}
        </S.TeamContainerItems>
      ) : (
        <S.TeamListPopover
          id={id}
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <S.TeamContainerItems in={isSubMenuOpened} unmountOnExit >
            <S.ListItem
              onClick={() => {
                dispatch(showModal({type: 'agentsModal'}))}
              }
              className='view_all'
            >
              <S.ListItemText>View All</S.ListItemText>
            </S.ListItem>
          </S.TeamContainerItems>
        </S.TeamListPopover>
      )}
    </>
  );
};