import cx from "classnames";
import * as S from "../../Sidebar.styles";
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import MenuIcon from "@mui/icons-material/Menu";
import { ROLES } from "configs/constants";
import { toggleSidebar } from "redux_toolkit/reducers/app/appSlice";
import { PartnerAvatar } from "./components/PartnerAvatar";
import { RequestButtons } from "./components/RequestButtons";


export const SidebarHeader = memo(({
  windowWidth,
  sidebarClosed,
  dashboardImage,
  isSuperAdmin,
  role
})=> {
  const dispatch = useDispatch();
  const { currentPartner } = useSelector(({ currentPartner })=> currentPartner);

  return(
    <S.SidebarHeader className={cx({ sidebarClosed })}>
      <S.PartnerAllInfo className={cx({ sidebarClosed })}>
        {(currentPartner || isSuperAdmin) && <PartnerAvatar
          noAdmin={role !== ROLES.ADMIN}
          dashboardImage={dashboardImage}
          isSuperAdmin={isSuperAdmin}
          sidebarClosed={sidebarClosed}
          currentPartner={currentPartner}
        />}
        {windowWidth > 950 && (
          <S.Button
            onClick={()=> dispatch(toggleSidebar())}
            className={cx({ sidebarClosed })}
          >
            <MenuIcon />
          </S.Button>
        )}
      </S.PartnerAllInfo>
      {currentPartner && !isSuperAdmin && role !== ROLES.ADMIN && (
        <RequestButtons
          currentPartner={currentPartner}
          sidebarClosed={sidebarClosed}
          role={role}
        />
      )}
      <S.Divider />
    </S.SidebarHeader>
  )
}, (prevProps, nextProps)=> {
  return(
    prevProps.windowWidth === nextProps.windowWidth &&
    prevProps.sidebarClosed === nextProps.sidebarClosed &&
    prevProps.dashboardImage === nextProps.dashboardImage &&
    prevProps.role === nextProps.role &&
    prevProps.isSuperAdmin === nextProps.isSuperAdmin
  );
})