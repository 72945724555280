export const tableHead = [
  'Shortcut',
  'Message',
  'Actions'
]


export const formatTableData = (shortcutsList, getTableActions) => {
  const formattedList = []

  for (const shortcut of shortcutsList) {
    formattedList.push([
      shortcut.tag,
      shortcut.message,
      ...getTableActions(shortcut)
    ])
  }

  return formattedList
}