import * as S from './ConfirmPasswordForm.styles'
import React from 'react'
import { useLocation } from "react-router-dom";
import { Formik } from 'formik'
import TextField from '@material-ui/core/TextField'
import DialogTitle from '@material-ui/core/DialogTitle'
import { initialValues, validationSchema } from './ConfirmPasswordForm.config'
import { Button } from 'ui'
import { confirmPasswordServ } from 'redux_toolkit/reducers/user/userApi';
import { useDispatch } from 'react-redux';
import { setNotification } from 'redux_toolkit/reducers/notification/notificationSlice';

export const ConfirmPasswordForm = () => {
  const { search } = useLocation();
  const dispatch = useDispatch()
  let userId = new URLSearchParams(search).get('userId');

  const onSubmit = async ({ password }) => {
    const data = await confirmPasswordServ({password, userId});
      if(data.success){
        dispatch(setNotification({type: data.message, message: 'success', show: true}));
        setTimeout(() => {
          const protokol = process.env.NODE_ENV === 'production' ? 'https' : 'http';
          window.location.href = `${protokol}://${process.env.REACT_APP_DOMAIN}/login`;
        }, 1000);
      }
  }  
  
  return (
    <>
      <DialogTitle>
        Confirm Password
      </DialogTitle>
      <Formik
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        initialValues={initialValues}
        validateOnChange={true}
        validateOnBlur={false}
      >
        {
          ({
            errors,
            touched,
            handleBlur,
            handleSubmit,
            handleChange,
            getFieldProps
          }) => {
            return (
              <S.ConfirmPasswordWrapper onSubmit={handleSubmit}>
                <S.Form>
                  <TextField
                    {...getFieldProps('password')}
                    fullWidth
                    size='small'
                    id="password"
                    name='password'
                    label="password"
                    type="password"
                    onChange={(e) => {
                      handleBlur(e)
                      handleChange(e)
                    }}
                    variant="outlined"
                    error={touched.password && !!errors.password}
                    helperText={touched.password && errors.password}
                  />
                  <TextField
                    {...getFieldProps('confirmPassword')}
                    fullWidth
                    name='confirmPassword'
                    size='small'
                    type="password"
                    label="confirmPassword"
                    onChange={(e) => {
                      handleBlur(e)
                      handleChange(e)
                    }}
                    variant="outlined"
                    error={touched.confirmPassword && !!errors.confirmPassword}
                    helperText={touched.confirmPassword && errors.confirmPassword}
                  />
                  <S.ButtonsContainer>
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={handleSubmit}
                    >
                      Submit
                    </Button>
                  </S.ButtonsContainer>
                </S.Form>
              </S.ConfirmPasswordWrapper>
            )
          }
        }
      </Formik>
    </>
  )
}