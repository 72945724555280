import { createSlice } from '@reduxjs/toolkit';
import { upperFirst } from 'utils/functions';

const initialState = {
  activePage: 'Home'
};

export const pageNameSlice = createSlice({
  name: 'page_name',
  initialState,
  reducers: {
    changePageName: (state, action)=> {
      const name = upperFirst(action.payload);
      state.activePage = name;
    }
  },
});

export const {
  changePageName
} = pageNameSlice.actions;
export const selectPageName = (state) => state.pageName;
export default pageNameSlice.reducer;