import * as S from "./ChatsLogs.styles";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { getMessageUserName } from "pages/HistoryPage/HistoryPage.config";


function ChatLogs({ item }) {
  const teamList = useSelector(({ agents })=> [...agents.partnerTeam.values()]);

  const senderName = useCallback((id) => {
    return getMessageUserName(id, teamList, item.visitorPartner);
  }, [teamList, item.visitorPartner]);

  return (
    <S.ChatContainer>
      {item.logs?.map((log) => {
        return (
        <div key={Math.random()}>
          <S.ChatLogsItem key={Math.random()}>
            <S.ChatLeftItem>
              <p>{senderName(log.userId).name}</p>
              <p>{log.action}</p>
            </S.ChatLeftItem>
            {new Date(log.createdAt).toLocaleString()}
          </S.ChatLogsItem>
        </div>);
      }
      )}
    </S.ChatContainer>
  );
}

export { ChatLogs };