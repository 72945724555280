import cx from "classnames";
import * as S from "./PartnerSettings.styles";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { DialogTitle, Button } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import { FILE_STORAGE_PERMISSION, FILE_PARTNERS_TYPE } from "configs/constants";
import RadioButtons from "components/CustomButtons/RadioButtons";
import { updatePartnerFilePermission } from "redux_toolkit/reducers/super_admin/partners/partnersSlice";
import { hideModal } from "redux_toolkit/reducers/modals/modalsSlice";
import { SelectBox } from "ui";

const list = [
  { id: 1, value: 1, label: 'Disable' },
  { id: 3, value: 3, label: 'Enable' }
];
const defaultSelect = { value: '', label: '---' };

export const PartnerSettings = ({ editablePartner }) => {
  const dispatch = useDispatch();

  const [val, setVal] = useState(editablePartner?.file || FILE_PARTNERS_TYPE.DISABLE);
  const [selectValue, setSelectValue] = useState(
    list.find(option => option.value === editablePartner?.file) || defaultSelect
  );

  const attachFile = (value) => {
    setVal(value);
    setSelectValue(list.find(option => option.value === value) || defaultSelect);
    dispatch(updatePartnerFilePermission({
      _id: editablePartner._id,
      file: value,
    }));
  };

  const handleRadioChange = (e) => {
    attachFile(Number(e.target.value));
  };

  const handleSelectChange = (selectedOption) => {
    attachFile(selectedOption.value);
  };

  return (
    <S.PartnerSettingsContainer>
      <DialogTitle>Settings</DialogTitle>
      <S.BoxContainer>
        <h4>Attach file</h4>
        <RadioButtons
          aria-label="Attach File"
          name="file"
          value={+val}
          onChange={handleRadioChange}
          data={FILE_STORAGE_PERMISSION}
          color="primary"
          className={cx({ radioButtonBox: true })}
        />
        <S.SelectContainer>
          <SelectBox
            onChange={handleSelectChange}
            list={list}
            value={selectValue}
            placeholder="---"
            menuPosition='fixed'
            maxMenuHeight={180}
            className="manual"
          />
        </S.SelectContainer>
        <S.CloseButtonContainer>
          <Button color="secondary" onClick={() => dispatch(hideModal())}>
            <CloseIcon />
          </Button>
        </S.CloseButtonContainer>
      </S.BoxContainer>
    </S.PartnerSettingsContainer>
  );
};
