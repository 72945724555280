import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  list: [
    {
      id: 1,
      reason: 'Test reason',
      visitor: '#248875004',
      dateCreated: new Date()
    }
  ],
  searchData: true,
}

export const bannedSlice = createSlice({
  name: 'banned',
  initialState,
  reducers: {
    searching: (state) => {
      state.searchData = false;
    }
  },
});

export const { searching } = bannedSlice.actions;
export const selectBanned = (state) => state.banned;
export default bannedSlice.reducer;
