import styled from 'styled-components/macro';
import TextFieldContainer from '@material-ui/core/TextField';
import TabsContainer from '@material-ui/core/Tabs';
import { TabPanel } from 'components';
import { Radio } from '@material-ui/core';

export const AppearanceContainer = styled.div`
  display: flex;
  padding: 20px 15px;
  flex-direction: column;

  @media (min-width: 960px) {
    flex-direction: row;
  }
`

export const InfoItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
`
export const ColorContainer = styled.div`
  display: block !important;
`
export const GradientTypesContainer = styled.div`
  display: flex;
  padding: 10px;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  gap: 20px;
  .gradient {
    cursor: pointer;
    width: 20px;
    height: 20px;
    position: relative;
    &.active {
      &::before {
        content: '';
        width: 30px;
        height: 30px;
        position: absolute;
        left: -5px;
        top: -5px;
        border: 1px solid;
        padding: 2px
      }
    }
  }
`
export const Linear = styled.div`
  background: ${({ degree }) =>  `linear-gradient(${degree}deg, #fff, #000)`};
`
export const Circular = styled.div`
  background: radial-gradient(#fff, #000);
`
export const Piramide = styled.div`
  background: ${({ degree }) =>  `conic-gradient(from ${degree}deg, #fff 0deg, #000 360deg)`};
`
export const Midline = styled.div`
  background: ${({ degree }) =>  `linear-gradient(${degree - 90}deg, #000, #fff, #000)`};
`

export const Degree = styled.div`
  width: 50px;
  height: 50px;
  border: 2px solid;
  border-radius: 50%;
  position: relative;
  transform: ${({ degree }) => `rotate(${degree}deg)`};
  &::before {
    content: '';
    width: 25px;
    height: 2px;
    top: 11px;
    left: 11px;
    transform: rotate(90deg);
    background: #0f3d56;
    position: absolute;
  }
  &::after {
    content: '';
    width: 6px;
    height: 6px;
    top: 20px;
    background: #0f3d56;
    border-radius: 50%;
    left: 20px;
    position: absolute;
  }
  &:focus {
    cursor: grabbing;
  }
  &:hover {
    cursor: grabbing;
  }
`

export const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  .title {
    width: 150px;
    text-align: start
  }

  div {
    font-weight: bold;
    font-size: 14px;
    color: #0f3d56;
  }
  .picker-container {
    display: flex;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
  }
  .degree {
    margin-left: 60px;
  }
  input{
    border: none;
    background-color: unset;
  }
  .MuiInputBase-input {
    padding: 10.5px 14px;
  }
`

export const InfoItemTitle = styled.div`
  color: #0f3d56;
  font-size: 18px;
  font-weight: bold;
  justify-content: space-between;
  align-items: center;
  border-bottom: 3px solid #0f3d56;
  padding: 10px 0px;
`

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const LeftPart = styled(ColumnContainer)`
  flex: 2;
  gap: 30px;
`

export const RightPart = styled(ColumnContainer)`
  flex: 1;
  @media(max-width: 1100px){
    flex: 3;
    padding-left: 15px;
  }
  @media(max-width: 959px){
    padding-left: 0;
  }
`

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-right: 20px;
  gap: 10px;

  .Button {
    padding: 15px 35px !important;
    margin-right: 20px;
    @media(max-width: 1100px){
      padding: 15px 25px !important;
    }
  }
`

export const InfoItemDescription = styled.div`
  display: flex;
  color: #555;
  font-size: 15px;
  font-weight: bold;
  text-align: start;
`



export const Tabs = styled(TabsContainer)`
  margin-top: 25px;
`

export const DesktoMobileBody = styled(TabPanel)`
  > div {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0px;
  }
`

export const SpacesContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 30px
`

export const SpaceItem = styled.div`
  display: flex;
  align-items: center;
  font-weight: normal;

  p{
    font-size: 16px;
    margin-right: 7px;
    font-weight: 400;
  }
  span{
    margin: 0px 2px;
    font-weight: normal;
  }
`

export const TextField = styled(TextFieldContainer)`
  width: 80px
`

export const RadioMI = styled(Radio)`
  padding-right: 4px;
`