import * as S from './NotificationsTab.styles'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Checkbox from '@material-ui/core/Checkbox';
import { optionsList } from './NotificationsTab.config'
import { updateNotification } from 'redux_toolkit/reducers/partner/agents/agentsSlice';


export const NotificationsTab = () => {
  const dispatch = useDispatch();
  const { userData } = useSelector(({userPartner})=> userPartner);

  const [notificationData, setNotificationData] = useState({
    requestFlag: userData.requestFlag,
    messageFlag: userData.messageFlag
  });

  const changeNotification = (e, optionName)=> {
    let body = {};
    body[optionName] = +e.target.checked;
    setNotificationData({...notificationData, ...body});
    dispatch(updateNotification({partnerId: userData.partnerId, agentId: userData._id, body}));
  }

  return (
    <S.NotificationsTabContainer>
      <S.TabTitle>
        Notifications
      </S.TabTitle>
      <S.TabInfo>
        Set your notification preferences for when you are in or away from the dashboard. You will need to configure your browser settings to allow notifications from digitech.am.
      </S.TabInfo>
      <S.OptionsListContainer>
        {
          optionsList.map(option => {
            return (
              <S.OptionItem key={option.id}>
                <S.OptionText className='Label'>
                  { option.label }
                </S.OptionText>
                <Checkbox
                  onClick={(e)=> changeNotification(e, option.name)}
                  checked={Boolean(notificationData[`${option.name}`])}
                  color="primary"
                />
                <S.OptionText>
                  { option.description }
                </S.OptionText>
              </S.OptionItem>
            )
          })
        }
      </S.OptionsListContainer>
    </S.NotificationsTabContainer>
  )
}