import cn from "classnames";
import * as S from "./UserInfo.styles";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@material-ui/core/TextField";
import { FormControlLabel, Radio, RadioGroup, Paper } from "@mui/material";
import moment from "moment";
import { isEmpty } from "utils/functions";
import {
  STATUS,
  ROLES,
  FILE_PERMISSION_CHAT,
  DISABLE_ENABLE_BUTTON,
  FILE_PARTNERS_TYPE
} from "configs/constants";
import { Loader, ClickOutside } from "components";
import {
  filePermission,
  transferChat,
  visitorBan,
  transferAssign,
  getVisitor,
  updateVisitorData,
} from "socket/socket";
import { updateDate } from "./UserInfo.config";
import { hideModal } from "redux_toolkit/reducers/modals/modalsSlice";


const UserInfo = ({
  chatInfo,
  canEdit,
}) => {
  const dispatch = useDispatch();
  const teamList = useSelector(({ agents }) => [...agents.partnerTeam.values()]);
  const {list: visitors, visitorPastChats} = useSelector(({ visitors }) => visitors);
  const { currentPartner } = useSelector(({ currentPartner }) => currentPartner);
  const { partnerId, role, _id: currentUserId } = useSelector(({ userPartner }) => userPartner.userData);

  const [showDropDown, setShowDropDown] = useState(false);
  const [isDisabled, setIsDisabled] = useState(chatInfo?.transfer);
  const [isOpenedBanContent, setIsOpenedBanContent] = useState(false);
  const [visitorIsBanned, setVisitorIsBanned] = useState(false);
  const [reason, setReason] = useState("");
  const [banDuration, setBanDuration] = useState(1);
  const [file, setfile] = useState( chatInfo.permissionForFile || DISABLE_ENABLE_BUTTON.DISABLE );
  const [time, setTime] = useState(updateDate(chatInfo.startDate));
  const [visitorInfo, setVisitorInfo] = useState({});
  const [userNote, setUserNote] = useState('');
  let timeoutId = useRef();

  const getPastChats = useCallback((id)=> visitorPastChats.get(id), [visitorPastChats]);

  const getVisitorData = useCallback((vistorId) => {
    const data = visitors.find((visitor) => visitor._id === vistorId) || {};
    setVisitorInfo(data);
    setUserNote(data.note);
  }, [visitors]);

  useEffect(()=> {
    getVisitorData(chatInfo?.visitorId || chatInfo?.recipentId);
  }, [chatInfo.visitorId, chatInfo.recipentId, getVisitorData, visitors]);

  const pastChats = useMemo(() => getPastChats(visitorInfo?._id)?.length, [visitorInfo?._id, getPastChats]);

  const updateInfoCallBack = useCallback((args)=> {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }

    timeoutId.current = setTimeout(()=> {
      updateVisitorData(chatInfo.visitorId, {
        partnerId,
        note: visitorInfo.note || '',
        ...args
      })
    }, 1000);
    setUserNote(args.note);
  }, [visitorInfo, chatInfo.visitorId, partnerId]);

  const showPermissionButton = currentPartner?.file > 0;

  const assignTo = useCallback(
    (agent) => {
      transferAssign(chatInfo.chatId, agent._id, true);
      dispatch(hideModal());
    },
    [
      chatInfo.chatId,
      dispatch
    ]
  );

  // Transfer All login agents
  const teammatesToAssign =  useMemo(
    ()=> teamList.filter((agent) => agent._id !== currentUserId && agent.status !== STATUS.OFFLINE
  ),[currentUserId, teamList]);
  // Transfer All ONLINE agents
  // const teammatesToAssign =  useMemo(
  //   ()=> teamList.filter((agent) => agent._id !== currentUserId && agent.status === STATUS.ONLINE
  // ),[currentUserId, teamList]);

  const handleTransferChat = useCallback(() => {
    if (role === ROLES.TEAMLEADER) {
      setIsOpenedBanContent(false);
      setShowDropDown(prev => !prev);
    } else {
      setIsDisabled(true);
      transferChat(chatInfo.chatId);
    }
  }, [
    role,
    chatInfo.chatId
  ]);

  const handleSetFilePermission = useCallback(() => {
    const permission = file === DISABLE_ENABLE_BUTTON.DISABLE ? DISABLE_ENABLE_BUTTON.ENABLE : DISABLE_ENABLE_BUTTON.DISABLE;
    setfile(permission);
    filePermission(chatInfo.chatId);
  }, [file, chatInfo.chatId]);

  const handleBanUnbanVisitor = useCallback((data) => {
    let banTime = 0;
    if(!data?.unban) banTime = banDuration;
    visitorBan(visitorInfo._id, banTime, reason);
    setIsOpenedBanContent(false);
    setVisitorIsBanned(!visitorIsBanned);
  }, [banDuration, reason, visitorIsBanned, visitorInfo._id]);

  useEffect(() => {
    if (chatInfo.recipentId && visitorInfo._id !== chatInfo.recipentId) {
      getVisitor(chatInfo.recipentId);
    }
  }, [chatInfo.recipentId, visitorInfo._id, partnerId, dispatch]);

  useEffect(() => {
    setVisitorIsBanned( (visitorInfo.banExpire && moment().isBefore(visitorInfo.banExpire)) || false );
  }, [
    visitorInfo.banExpire
  ]);

  useEffect(() => {
    if (visitorIsBanned) {
      setIsOpenedBanContent(false);
    }
  }, [visitorIsBanned]);

  useEffect(() => {
    setIsDisabled(chatInfo.transfer);
  }, [chatInfo.transfer]);

  useEffect(() => {
    let interval;
    if(!chatInfo.endDate) {
      interval = setInterval(() => {
        const minute = chatInfo.startDate ? updateDate(chatInfo.startDate):0;
        setTime(minute);
      }, 60000);

      return () => clearInterval(interval);
    }

    setTime(Math.floor((new Date(chatInfo.endDate).getTime() - new Date(chatInfo.startDate).getTime())/60000));
  }, [ chatInfo.timeOnSite, chatInfo.endDate, chatInfo.startDate ]);

  const parts = visitorInfo?.userAgent?.split('(').map(part => part.trim());

  return (
    <S.UserInfoContainer className="UserInfo">
      {isEmpty(visitorInfo) ? (
        <Loader
          color="primary"
          size={100}
          styles={{ height: "100%", position: "inherit" }}
        />
      ) : (
        <>
          <S.ColumnContainer className="ColumnContainer">
            <TextField
              size="small"
              variant="outlined"
              label="Add note"
              multiline={true}
              minRows={13}
              disabled={!canEdit}
              onChange={(e) => updateInfoCallBack({note: e.target.value})}
              value={userNote || ''}
            />
            <S.ActivityContainer>
              <S.ActivityItem>
                <div>{visitorInfo.visits || "---"}</div>
                <div>Past visits</div>
              </S.ActivityItem>
              <S.ActivityItem>
                <div>{pastChats || "---"}</div>
                <div>Past chats</div>
              </S.ActivityItem>
              <S.ActivityItem>
                <div>{time} minutes</div>
                <div>{chatInfo.endDate? 'Chat duration' : 'Time on site'}</div>
              </S.ActivityItem>
            </S.ActivityContainer>
            <S.RowContainer>
              <>{canEdit &&
                <>
                  {!chatInfo.endDate && (
                    <>
                      <S.Button
                        disabled={isDisabled}
                        onClick={() => {
                          handleTransferChat();
                        }}
                      >
                        Transfer Chat
                      </S.Button>
                      {showDropDown && (
                        <S.DropDownMenu>
                          {teammatesToAssign.length ? teammatesToAssign.map((agent) => (
                            <S.Button
                              key={agent._id}
                              onClick={() => assignTo(agent)}
                            >
                              {agent.name}
                            </S.Button>
                          )) : <div>no active agents</div>}
                          
                        </S.DropDownMenu>
                      )}
                    </>
                  )}
                  {showPermissionButton && !chatInfo.endDate && (
                    <S.Button
                    disabled={currentPartner?.file === FILE_PARTNERS_TYPE.ENABLE}
                    onClick={handleSetFilePermission}
                    >
                      Attach {FILE_PERMISSION_CHAT[file]}
                    </S.Button>
                  )}
                </>
              }
              </>
              {
                (role === ROLES.TEAMLEADER || role === ROLES.ADMIN) && <S.BanComponent>
                {visitorIsBanned ? (
                  <S.Button onClick={() => handleBanUnbanVisitor({unban: true})}>Unban</S.Button>
                ) : (
                  <S.Button
                    onClick={() => setIsOpenedBanContent((prev) => !prev)}
                  >
                    Ban
                  </S.Button>
                )}
                {isOpenedBanContent && !visitorIsBanned && (
                  <ClickOutside onOutsideClick={() => setIsOpenedBanContent(false)} >
                    <S.BanContent>
                      <Paper elevation={24} className="ban-content">
                        <TextField
                          label="Reason"
                          placeholder="Reason"
                          variant="outlined"
                          defaultValue=""
                          size="small"
                          onChange={(e) => setReason(e.target.value)}
                        />
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                          value={banDuration || ''}
                          onChange={(_e, value) => setBanDuration(value)}
                        >
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="1 Day"
                          />
                          <FormControlLabel
                            value="7"
                            control={<Radio />}
                            label="7 Day"
                          />
                          <FormControlLabel
                            value="1000000"
                            control={<Radio />}
                            label="Always"
                          />
                        </RadioGroup>
                        <S.Button onClick={() => handleBanUnbanVisitor()}>
                          Ban Visitor
                        </S.Button>
                      </Paper>
                    </S.BanContent>
                  </ClickOutside>
                )}
              </S.BanComponent>
              }
            </S.RowContainer>
            <S.Referrers>
              {visitorInfo.referrers?.length && visitorInfo.referrers.map((referrer) => (
                <span key={Math.random()}>{referrer}</span>
              ))}
            </S.Referrers>
            <S.UserComputerData className={cn({ UserComputerData: true })}>
              <S.DataItem >
                <div>Location</div>
                <div>{visitorInfo?.location || "-----"}</div>
              </S.DataItem>
              <S.DataItem>
                <div>Browser</div>
                <div>{parts[0] || "-----"}</div>
              </S.DataItem>
              <S.DataItem>
                <div>Platform</div>
                <div>{parts[1]?.split(';')[0] || "-----"}</div>
              </S.DataItem>
              <S.DataItem>
                <div>Ip Address</div>
                <div>{visitorInfo.ip || "-----"}</div>
              </S.DataItem>
              <S.DataItem>
                <div>User Agent</div>
                <div>{visitorInfo.userAgent || "-----"}</div>
              </S.DataItem>
            </S.UserComputerData>
          </S.ColumnContainer>
        </>
      )}
    </S.UserInfoContainer>
  );
};

export { UserInfo };