import { configureStore } from '@reduxjs/toolkit';
import adminsReducer from './reducers/super_admin/admins/adminsSlice';
import partnersReducer from './reducers/super_admin/partners/partnersSlice';
import userReducer from './reducers/user/userSlice';
import partnerWidgetReducer from './reducers/partner/widget/widgetSlice';
import partnerShortcutReducer from './reducers/partner/shortcut/shortcutSlice';
import visitorsReducer from './reducers/partner/visitors/visitorsSlice';
import agentsReducer from './reducers/partner/agents/agentsSlice';
import appReducer from './reducers/app/appSlice';
import bannedReducer from './reducers/banned/bannedSlice';
import chatReducer from './reducers/chat/chatSlice';
import modalReducer from './reducers/modals/modalsSlice';
import historyReducer from './reducers/history/historySlice';
import notificationReducer from './reducers/notification/notificationSlice';
import connectReducer from './reducers/connect/connectSlice';
import pageNameReducer from './reducers/page_name/pageNameSlice';
import currentPartnerReducer from './reducers/current_partner/currentPartnerSlice';
import countsReducer from './reducers/super_admin/counts/countsSlice';
import userPartnerReducer from './reducers/user_partner/userPartnerSlice';
import thunk from 'redux-thunk';

export const store = configureStore({
  reducer: {
    admins: adminsReducer,
    partners: partnersReducer,
    user: userReducer,
    widget: partnerWidgetReducer,
    shortcut: partnerShortcutReducer,
    app: appReducer,
    banned: bannedReducer,
    chat: chatReducer,
    history: historyReducer,
    notification: notificationReducer,
    modal: modalReducer,
    agents: agentsReducer,
    visitors: visitorsReducer,
    connect: connectReducer,
    pageName: pageNameReducer,
    currentPartner: currentPartnerReducer,
    counts: countsReducer,
    userPartner: userPartnerReducer
  },
  middleware: [thunk],
});
