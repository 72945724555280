import * as S from "./LoginPage.styles";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import MaterialButton from "@material-ui/core/Button";
import EmailIcon from "images/emailIcon.png";
import PasswordIcon from "images/passwordIcon.png";
import { Button, Input } from "ui";
import { initialValues } from "./LoginPage.config";
import { SUPER_ADMIN_URL, ROLES } from "configs/constants";
import { login } from "redux_toolkit/reducers/user/userSlice";


export const LoginPage = ({
  isSuperAdmin = window.location.pathname.includes(SUPER_ADMIN_URL),
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { error } = useSelector(({ user }) => user);

  const onSubmit = (values) => {
    dispatch(login({
      body: values,
      isSuperAdmin: !!isSuperAdmin,
    })).then((res) => {
      if(res.error) return console.error("LOGIN PAGE ERROR", res.error);

      if(res.payload.role === ROLES.SUPERADMIN){
        return navigate('/superadmin/home');
      }
      navigate('/partners');
    })
  };

  return (
    <>
      <S.FormTitle>Welcome back</S.FormTitle>
      <Formik onSubmit={onSubmit} initialValues={initialValues}>
        {({ handleChange }) => {
          return (
            <S.Form>
              {error && <S.FormError>{error}</S.FormError>}
              <Input
                id="email"
                name="email"
                onChange={handleChange}
                icon={EmailIcon}
                placeholder="Email Address"
              />
              <Input
                name="password"
                onChange={handleChange}
                icon={PasswordIcon}
                type="password"
                placeholder="Password"
              />
              {!isSuperAdmin && (
                <MaterialButton style={{ padding: 0, alignSelf: "flex-end" }}>
                  <S.ForgotPassword to="/forgot-password">
                    Forgot your password?
                  </S.ForgotPassword>
                </MaterialButton>
              )}
              <Button
                variant="contained"
                color="primary"
                type="submit"
              >
                Login
              </Button>
            </S.Form>
          );
        }}
      </Formik>
    </>
  );
};
