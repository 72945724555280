import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from "@mui/material/Alert";
import { setNotification } from 'redux_toolkit/reducers/notification/notificationSlice';

function Alert(props) {
  return <MuiAlert sx={{ width: '100%' }} elevation={6} variant="filled" {...props}>{props.children}</MuiAlert>;
}

export const Notification = function () {
  const dispatch = useDispatch()
  const {type, message, show} = useSelector(({ notification }) => notification);
  const handleClose = () => {
    dispatch(setNotification({type:"", message:"", show: false}));
  };

  return (
    <div>
      {show && <Snackbar open={show} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={handleClose} severity={message}>
          {type}
        </Alert>
      </Snackbar>}
    </div>
  );
}

