import styled from 'styled-components'
import MaterialButton from '@material-ui/core/Button'


export const AgentsPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  .MuiSwitch-colorPrimary.Mui-checked{
    color: #0f3d56;
  }
  .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track{
    background-color:#0f3d56!important;
    opacity: 0.4!important;
  }
  @media (min-width:951px){
    .chat-routing{
      margin-right: 50px;
   }
  }
`

export const ActionsContainer = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
`

export const ActionButton = styled(MaterialButton)`
  min-width: 0px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  svg.logout-icon {
    transform: rotate(180deg);
    width: 20px;
    height: 20px;
  }
`

export const HeaderContainerActions = styled.div`
  color:#fff;
  font-size: 17px!important;
  display: flex;
  justify-content: center;
  align-items: center;
  .MuiSwitch-colorPrimary.Mui-checked{
    color: #444!important;
  }
  .MuiSwitch-root {
    margin-top: 3px;
  }
  .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track{
    background-color:#999!important;
    opacity: 1!important;
  }
  @media (max-width:950px){
    font-size: 8px!important;
  }
`
export const Plus = styled(MaterialButton)`
  color: #fff;
  .fa-plus{
    font-size: 15px!important;
  }
  @media (max-width:950px){
    .fa-plus{
      font-size: 12px!important;
    }
  }
`
