import './ColorPicker.css';
import React from 'react'

export const ColorPicker = ({ onChange, showColorValue=true, ...rest }) => {
  const onSelectColor = ({ target: { value: color } }) => {
    onChange(color)
  }

  return (
    <div className='zc-color-picker-input-container'>
      <input
        className='zc-olor-picker-input'
        type='color'
        onChange={(e) => onSelectColor(e)}
        {...rest}
      />
      {
        showColorValue &&
        <div>
          {rest.value}
        </div>
      }
    </div>
  )
}