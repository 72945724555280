import styled from 'styled-components/macro'
import Paper from '@material-ui/core/Paper'

const gradients = {
  'linear-gradient': (color1, color2, deg) => `linear-gradient(${deg}deg, ${color1}, ${color2})`,
  'radial-gradient': (color1, color2, _deg) => `radial-gradient(${color1}, ${color2})`,
  'conic-gradient': (color1, color2, deg) => `conic-gradient(from ${deg}deg, ${color1} 0deg, ${color2} 360deg)`,
  'midline-gradient': (color1, color2, deg) => `linear-gradient(${deg - 90}deg, ${color1}, ${color2}, ${color1})`
}

export const PreChatFormContainer = styled(Paper)`
  display: flex;
  width: 412px;
  height: auto;
  flex-direction: column;
  box-shadow: 0 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);
  @media(max-width: 1100px){
    width: 100%;
    max-width: 412px;
  }
  &.popout{
    border-radius: 35px 35px 9px 9px;
  }
  &.overlay{
    box-shadow: 0px -2px 4px #00000099;
    border-radius: 0;
  }
`

export const PreChatFormHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: 
    ${({ headerLeftColor, headerRightColor, degree }) => 
      `linear-gradient(${degree}deg, ${headerLeftColor}, ${headerRightColor})`};
  padding: 8px 31px 38px;
  gap: 5px;
  &.popout{
    border-top-left-radius: 35px;
    border-top-right-radius: 35px;
  }
  &.overlay{
    border-radius: 0;
  }
  .MuiButtonBase-root {
    min-width: unset;
    border-radius: 50%;

    .MuiSvgIcon-root {
      color: #fff;
    }
  }
`

export const PreChatFormHeaderInner = styled.div`
  display: flex;
  align-items: center;  
`

export const PreChatFormHeaderText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 10px;
  & > h2,
  p{
    margin: 0;
    color: #ffffff;
    text-shadow: 0 0 8px rgb(0 0 0 / 75%);
  }
  h2{
    font-size: 30px;
    line-height: 1;
    @media(max-width: 1100px){
      font-size: 26px;
    }
  }
  p{
    font-weight: bold;
  }
  & > div{
    display: flex;
    align-items: center;
  }
`

export const PreChatFormStatus = styled.span`
  width: 8px;
  height: 8px;
  background-color: #07FF00;
  border-radius: 50%;
  display: inline-block;
  margin-right: 3px;
  
`

export const PreChatFormContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px;
  flex: 1;
  align-items: center;
  background: ${({ bgColor }) => bgColor};
  justify-content: center;
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;

  button {
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)
  }

  .MuiButtonBase-root {
    margin-top: auto;
  }

  .MuiTextField-root {
    border-radius: 15px;
    color: #fff;
    background: #fff;
    width: 100%;

    .Mui-focused {
      fieldset {
        border-color: ${({ header_left_color }) => header_left_color} !important;
      }
    }

    &:hover {
      .MuiOutlinedInput-root {
        fieldset {
          border-color: ${({ header_left_color }) => header_left_color} !important;
        }
      }
    }    

    &:first-child {
      margin-top: auto;
    }
  }
`

export const PreChatCoversationContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 13px;
  flex: 1;
  align-items: center;
  background: ${({ bgColor }) => bgColor};
  margin-top: -33px;
  &.popout{
    border-radius: 35px 35px 9px 9px;
  }
  &.overlay{
    border-radius: 0;
  }
  button {
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)
  }

  .MuiInputBase-root {
    margin-right: 10px;
    width: 100%;
    background: #fff;
    border: 1.5px solid ${({ borderColor }) => borderColor};
    border-radius: 15px;
  }

  .MuiButtonBase-root {
    margin-top: auto;
  }

  .MuiTextField-root {
    border-radius: 10px;
    color: #fff;
    width: 100%;

    .Mui-focused {
      fieldset {
        border-color: ${({ header_left_color }) => header_left_color} !important;
      }
    }

    &:hover {
      .MuiOutlinedInput-root {
        fieldset {
          border-color: ${({ header_left_color }) => header_left_color} !important;
        }
      }
    }    

    &:first-child {
      margin-top: auto;
    }
  }
`
export const PreChatFormTitle = styled.div`
    font-weight: bold;
    color:  ${({ color }) => color};
    margin-right: auto;
`

export const PreChatMessagesBlock = styled.div`
  width: 100%;
  height: 50vh;
  overflow: auto;
` 

export const MessageWrapper = styled.div`
  width: 100%; 
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  align-items: ${({ position }) => position};
  padding-bottom: 15px;

`

export const MessageText = styled.div`
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ textColor }) => textColor};
  border-radius: 18px;
  padding: 5px 15px;
  text-align: initial;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
  max-width: 240px;
  font-family: "IRANSansX-light";
  line-height: 1.25;
  &:before{
    content: "";
    position: absolute;
    top: 100%;
    width: 0;
    height: 0;
    margin-top: -12px;
    border-style: solid;
  }
  &:after{
    content: "";
    position: absolute;
    width: 19px;
    height: 19px;
    border-radius: 50%;
    top: 100%;
    margin-top: -20px;
    background-color: ${({ badgeColor }) => badgeColor};
  }
  &.agentMessage{
    border-bottom-left-radius: 0;
    margin-left: 15px;
    &:before{
      left: -11px;
      border-width: 0 0 12px 12px;
      border-color: transparent transparent ${({ bgColor }) => bgColor} transparent;
      line-height: 0;
      _border-color: #000000 #000000 ${({ bgColor }) => bgColor} #000000;
      _filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
    }
    &:after{
      left: -19px;
    }
  }
  &.ourMessage{
    border-bottom-right-radius: 0;
    margin-right: 15px;
    &:before{
      right: -11px;
      border-width: 12px 0 0 12px;
      border-color: transparent transparent transparent ${({ bgColor }) => bgColor};
      _border-color: #000000 #000000 #000000 ${({ bgColor }) => bgColor};
      _filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
    }
    &:after{
      right: -19px;
    }
    .messageDate{
      left: auto;
      right: 0;
    }
  }
  &.botMessage {
    max-width: 100%;
    width: 100%;
    align-items: center;

    &:before, &:after {
      display: none;
    }
    .messageDate {
      left: 50%;
      transform: translateX(-50%)
    }
  }

`
export const MessageDate = styled.div`
  font-size: 12px;
  align-self: ${({ alignSelf }) => alignSelf};
  font-weight: bold;
  line-height: 1;
  position: absolute;
  left: 0;
  top: 100%;
  color: ${({ textColor }) => textColor};
  margin-top: 4px;
`

export const PreChatTextAreaBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  fieldset {
    display: none;
  }
`
export const ChatBubbleContent = styled.div`
  width: 100px;
  height: 100px;
  display: flex;
  background: ${({ colors, degree, type }) => gradients[type](colors.left, colors.right, degree)};
  align-items: center;
  border-radius: 10px;
  justify-content: center;
  @media(max-width: 1100px){
    width: 70px;
    height: 70px;
  }
  &.circular {
    border-radius: 50%;
  }
`;

export const ChatIcon = styled.div`
  display: flex;
  align-items: center;
  
  svg {
    height: 4em;
    width: 4em;
    @media(max-width: 1100px){
      height: 2.6em;
      width: 2.6em;
    }
    path {
      fill: ${({ bgColor }) => bgColor}
    }
  }
  .circular, .squared {
    background: ${({ bgColor }) => bgColor};
    border-radius: 10px;
  }
` 
export const ChatBubbleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 40px
` 
export const Minimized = styled.div`
  width: 60px;
  height: 10px;
  @media(max-width: 1100px){
    width: 40px;
    height: 7px;
  }
` 

export const IconBox = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  svg{
    margin: 0 0 0 7px;
    cursor: pointer;
    width: 32px;
    height: 32px;
    fill: #8E8E8E;
  }
  .attach-icon{
    transform: rotate(45deg);
    margin-top: 5px;
    cursor: default;
    opacity: 0.5;
  }
`

export const PreChatFormImg = styled.div`
  width: 52px;
  height: 52px;
  overflow: hidden;
  border-radius: 50%;
  box-shadow: 0 0 8px rgb(0 0 0 / 75%);
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`

export const PreChatEndWrapper = styled.div`
  display: flex;
  width: 100%;
  img{
    width: 23px;
    height: auto;
  }
  p{
    font-size: 20px;
    color: #8E8E8E;
    margin: 0;
    line-height: 1.2;
    margin-left: 6px;
  }
`
