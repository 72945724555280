import cx from 'classnames'
import styles from 'assets/jss/material-dashboard-react/components/tableStyle'
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Button from '@material-ui/core/Button'
import CloseIcon from '@mui/icons-material/Close';
import { Card, CardBody, CardHeader } from "components"
import { getTableBodyHeight } from './Table.config';

const useStyles = makeStyles(styles);

export const Table = ({
  tableHead,
  onRowClick,
  tableData,
  cardHeaderText,
  selectedRowIndex,
  hideModal,
  cellTextCenter = true,
  tableHeaderColor,
  tableRowClassName = (_row) => null,
  headerComponent = null,
  notFound,
  pastChatCardStyles = false,
  tbodyClassName,
  theadClassName,
  allTdEqual,
  allScreen
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [tableBodyHeight, setTableBodyHeight] = useState(0);

  useEffect(()=> {
    const headerHeight = document.getElementById('header')?.getBoundingClientRect().height;
    const tableTop = document.getElementById('tableTop')?.getBoundingClientRect().height || 0;
    setTableBodyHeight(getTableBodyHeight(headerHeight+tableTop, 120));
  }, [])

  const sizeEqual = 100 / tableHead.length;
  const styleEqual = allTdEqual ? { width: `${sizeEqual}%`, paddingLeft: '10px', overflowWrap: 'anywhere'}:{}

  return (
    <Card pastChatCard={pastChatCardStyles}>
      {
        cardHeaderText &&
          <CardHeader
          color='primary'
          className={`CardHeader`}
          style={{ background:'#0f3d56', alignItems: 'center', zIndex: 2 }}
          > 
            <h4 style={{ marginLeft: 'auto !important'}}>{ cardHeaderText }</h4>
            {headerComponent}
            {
              !!hideModal &&
                <div className={classes.closeButtonContainer}>
                  <Button onClick={()=> dispatch(hideModal())} className={classes.closeButton}>
                    <CloseIcon fontSize='medium'/>
                  </Button>
                </div>
            }
          </CardHeader>
      }
      <CardBody>
        <div
        style={{maxHeight: tableBodyHeight, minHeight: allScreen? tableBodyHeight:'auto', overflowY: 'auto', paddingBottom: '20px'}}
        className={classes.tableResponsive}
        >
          <MaterialTable className={classes.table}>
            {tableHead !== undefined ? (
              <TableHead className={cx(classes[tableHeaderColor + "TableHeader"], theadClassName)}>
                <TableRow
                style={{
                  position: 'sticky',
                  top: 0,
                  background: 'white',
                  zIndex: 1
                }}
                className={classes.tableHeadRow}
                >
                  {tableHead.map((prop, key) => {
                    return (
                      <TableCell
                        className={cx(classes.tableCell, classes.tableHeadCell, cellTextCenter && classes.cellTextCenter)}
                        key={key}
                        style={styleEqual}
                      >
                        {prop}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
            ) : null}      
            <TableBody className={tbodyClassName}>
              {tableData && tableData.map((prop, key) => {
                const rowData = prop[prop.length - 1]
                const isRowSelected = selectedRowIndex === key
                return (
                  <TableRow
                    key={key}
                    className={cx(
                      classes.tableBodyRow,
                      tableRowClassName(prop),
                      {
                        [classes.selected]: isRowSelected,
                        [classes.clickable]: !!onRowClick
                      }
                    )}
                    onClick={() => {
                      onRowClick && onRowClick(rowData, key)
                    }}
                  >
                    {prop.slice(0, onRowClick ? prop.length - 1 : prop.length).map((prop, key) => {
                      return (
                        <TableCell
                        className={cx(classes.tableCell, cellTextCenter && classes.cellTextCenter)}
                        key={key}
                        style={styleEqual}
                        >
                          {prop}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>   
          </MaterialTable>
          {notFound && <p style={{textAlign: 'center'}}>{notFound}</p>}
        </div>
      </CardBody>
    </Card>
  );
}

Table.defaultProps = {
  tableHeaderColor: "#0f3d56",
  selectedRowIndex: -1
};

Table.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
    "#0f3d56"
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string)
};
