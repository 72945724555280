import styled from 'styled-components/macro'
import { Form as FormikForm } from 'formik'

export const ConfirmPasswordContainer = styled.div`
  max-width: calc(100% - 10px);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4px 24px 20px;
  width: 500px;
`

export const ConfirmPasswordWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`

export const MessageText=styled.p`
  font-size: 13px;
  font-weight: bold;
  position: absolute;
  top: 65px;
`

export const ButtonsContainer = styled.div`
  display: flex;
  margin-top: 20px;
  gap: 20px;
  justify-content: flex-end;
`

export const FormRow = styled.div`
  display: flex;
  gap: 15px;
`

export const Form = styled(FormikForm)`
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: center;
  flex: 1;
`