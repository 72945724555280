import { axiosInstance } from '../../../../utils/axiosInstance';
import { superAdminsURL } from '../../../../utils/urls';

export const getAdminsListServ = async () => {
  return await axiosInstance
    .get(`${superAdminsURL}`)
    .then((res) => res.data)
    .catch((err) => new Error(err.response ? err.response.data.message : "Getting Admins List failed"));
};

export const addAdminServ = async (dataBody) => {
  return await axiosInstance
    .post(`${superAdminsURL}`, {...dataBody})
    .then((res) => res.data)
    .catch((err) => new Error(err.response?.data.message || "Create Admins failed"));
};

export const editAdminServ = async (dataBody) => {
  const userId = dataBody._id;
  return await axiosInstance
    .put(`${superAdminsURL}/${userId}`, dataBody)
    .then((res) => res.data)
    .catch((err) => new Error(err.response ? err.response.data.message : "Edit Admin failed"));
};

export const deleteAdminServ = async (id) => {
  return await axiosInstance
    .delete(`${superAdminsURL}/${id}`)
    .then(res => res.data)
    .catch((err) => new Error(err.response ? err.response.data.message : "Delete Admins failed"))
}