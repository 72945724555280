import './SearchInput.css';
import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";

export const SearchInput = ({ clearInput, ...rest }) => {
  return (
    <div className="zc-search-input SearchInput">
      <div className="zc-icon-container">
        <SearchIcon style={{ fill: "#0f3d56" }} />
      </div>
      <input
      className="zc-input-styled"
      {...rest}
      />
      {!!rest.value && (
        <div
          onClick={() => clearInput()}
          className="zc-icon-container right"
          style={{ marginLeft: "auto" }}
        >
          <ClearIcon />
        </div>
      )}
    </div>
  );
};