import * as S from './ForgotPasswordPage.styles'
import React from 'react'
import {useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Formik } from 'formik'
import EmailIcon from 'images/emailIcon.png'
import { Button, Input } from 'ui'
import { initialValues } from './ForgotPasswordPage.config'
import { forgotPassword } from 'redux_toolkit/reducers/user/userSlice'


export const ForgotPasswordPage = ({error}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const { resetSuccessfully, resetError } = useSelector(({ user }) => user);

  return (
    <>
      <S.FormTitle>
        Reset Password
      </S.FormTitle>
      <Formik
        onSubmit={values => dispatch(forgotPassword(values))}
        initialValues={initialValues}
      >
        {
          ({
            handleSubmit,
            handleChange
          }) => {
            return (
              <S.Form>
                {
                  error &&
                    <S.FormError>
                      { error }
                    </S.FormError>
                }
             {resetSuccessfully && <S.MessageText style={{color:'#489448'}}>Reset password request was <br/> sent to your email!</S.MessageText>}  
             {resetError && <S.MessageText style={{color:'red'}}> {resetError} </S.MessageText>}  
                <Input
                  id='email'
                  name='email'
                  onChange={handleChange}
                  icon={EmailIcon}
                  placeholder='Email Address'
                />
                <S.ButtonsContainer>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={()=>navigate(-1)}
                  >
                    Back
                  </Button>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </S.ButtonsContainer>
              </S.Form>
            )
          }
        }
      </Formik>
    </>
  )
}