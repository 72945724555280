import * as S from './AdminForm.styles'
import React from 'react'
import { useDispatch } from 'react-redux'
import { Formik } from 'formik'
import TextField from '@material-ui/core/TextField'
import DialogTitle from '@material-ui/core/DialogTitle'
import { initialValues, validationSchema } from './AdminForm.config'
import { Button } from 'ui'
import { addAdmin, editAdmin } from 'redux_toolkit/reducers/super_admin/admins/adminsSlice'
import { hideModal } from 'redux_toolkit/reducers/modals/modalsSlice'


export const AdminForm = ({
  adminToEdit
}) => {

  const dispatch = useDispatch();

  const defaultValues = adminToEdit ? {
    name: adminToEdit.name,
    email: adminToEdit.email,
    companyName: adminToEdit.companyName,
    phone: adminToEdit.phone,
    _id: adminToEdit._id
  } : initialValues;

  const onSubmit = (values) => {
    adminToEdit ? dispatch(editAdmin(values)) : dispatch(addAdmin(values));
    dispatch(hideModal());
  }

  return (
    <S.AdminFormContainer>
      <DialogTitle>
        Add new admin
      </DialogTitle>
      <Formik
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        initialValues={defaultValues}
        validateOnChange={true}
        validateOnBlur={false}
      >
        {
          ({
            values,
            errors,
            touched,
            handleBlur,
            handleSubmit,
            handleChange,
            getFieldProps
          }) => {
            return (
              <S.AdminFormWrapper onSubmit={handleSubmit}>
                <TextField
                  {...getFieldProps('name')}
                  value={values.name}
                  fullWidth
                  size='small'
                  id="name"
                  name='name'
                  label="Full Name"
                  onChange={(e) => {
                    handleBlur(e)
                    handleChange(e)
                  }}
                  variant="outlined"
                  error={touched.name && !!errors.name}
                  helperText={touched.name && errors.name}
                />
                <S.FormRow>
                  <TextField
                    {...getFieldProps('companyName')}
                    value={values.companyName}
                    fullWidth
                    size='small'
                    id="companyName"
                    name='companyName'
                    label="Company Name"
                    onChange={(e) => {
                      handleBlur(e)
                      handleChange(e)
                    }}
                    variant="outlined"
                    error={touched.companyName && !!errors.companyName}
                    helperText={touched.companyName && errors.companyName}
                  />
                  <TextField
                    {...getFieldProps('phone')}
                    value={values.phone}
                    fullWidth
                    name='phone'
                    size='small'
                    label="Phone Number"
                    onChange={(e) => {
                      handleBlur(e)
                      handleChange(e)
                    }}
                    variant="outlined"
                    error={touched.phone && !!errors.phone}
                    helperText={touched.phone && errors.phone}
                  />
                </S.FormRow>
                <TextField
                  {...getFieldProps('email')}
                  value={values.email}
                  fullWidth
                  id="email"
                  name='email'
                  label="Email"
                  size='small'
                  onChange={(e) => {
                    handleBlur(e)
                    handleChange(e)
                  }}
                  variant="outlined"
                  error={touched.email && !!errors.email}
                  helperText={touched.email && errors.email}
                />
                <S.ButtonsContainer>
                  <Button
                    color='secondary'
                    onClick={()=> dispatch(hideModal())}
                  >
                    Close
                  </Button>
                  <Button
                    onClick={handleSubmit}
                    type='submit'
                  >
                    {adminToEdit ? 'Save' : 'Create'}
                  </Button>
                </S.ButtonsContainer>
              </S.AdminFormWrapper>     
            )
          }
        }
      </Formik>
    </S.AdminFormContainer>
  )
}