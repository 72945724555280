import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import moment from "moment";

export const getDates = (onOpen, onClose) => {
  const date = moment().set({hour: 0, minute: 0, second: 0, millisecond: 0}).format();
  return [
    {
      id: 0,
      label: "Today",
      props: {
        onMouseEnter: onClose,
      },
      value: {
        dateFrom: moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
      },
    },
    {
      id: 1,
      label: "Yesterday",
      props: {
        onMouseEnter: onClose,
      },
      value: {
        dateFrom: moment(date).add(-1, "day").format(),
        dateTo: moment(date).add(-1, "second").format(),
      },
    },
    {
      id: 2,
      label: "Current week",
      props: {
        onMouseEnter: onClose,
      },
      value: {
        dateFrom: moment().startOf('week').format('YYYY-MM-DD HH:mm:ss'),
        dateTo: moment().endOf('week').format('YYYY-MM-DD HH:mm:ss')
      },
    },
    {
      id: 3,
      label: "Current month",
      props: {
        onMouseEnter: onClose,
      },
      value: {
        dateFrom: moment().startOf("month").format(),
      },
    },
    {
      id: 4,
      label: "Last 6 months",
      props: {
        onMouseEnter: onClose,
      },
      value: {
        dateFrom: moment().subtract(6, "month").format(),
      },
    },
    {
      id: 5,
      renderValue: "Date range",
      props: {
        onMouseEnter: onOpen,
      },
      label: (
        <>
          Date range
          <ArrowRightIcon />
        </>
      ),
      value: "dateRange",
    },
  ];
};
