import * as Yup from 'yup'

export const initialValues = {
  name: '',
  email: '',
  companyName: '',
  phone: ''
}

const requiredErrorMessage = 'This field is required'

export const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(4, 'The full name must be a string min 4 characters.')
    .required(requiredErrorMessage),
  companyName: Yup.string()
    .min(3, 'The company name must be a string min 3 characters.')
    .required(requiredErrorMessage),
  phone: Yup.string()
    .required(requiredErrorMessage)
    .matches(
      /^[0-9]{9}\d*$/,
      'The phone number must be a format number'
    ),
  email: Yup.string()
    .required(requiredErrorMessage)
    .email('Email is not valid')
});