import styled from 'styled-components/macro'


export const RemoveUserModalContainer = styled.div`
  display: flex;
  padding: 25px 40px;
  flex-direction: column;

  .MuiTypography-root {
    color: #555;
    font-size: 1rem;
    @media (max-width:950px){
      font-size:12px;
    }
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  margin-top: 20px;
  gap: 10px;
  justify-content: center;

  button{
    padding: 8px 4px!important;
  }
`