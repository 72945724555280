export const shortcuts = [
  {
    title: 'When chatting',
    list: [
      {
        keywords: 'Ctrl + 1 , 2 ....',
        description: 'Switch between chat windows'
      },
      {
        keywords: 'Ctrl + ,',
        description: 'Minimize chat window'
      },
      {
        keywords: 'Ctrl + .',
        description: 'Close chat window'
      },
      {
        keywords: 'Ctrl + ~',
        description: 'Serve incoming chat'
      },
      {
        keywords: 'Ctrl + Alt + [',
        description: 'Go to previous chat window'
      },
      {
        keywords: 'Ctrl + Alt + ]',
        description: 'Go to next chat window'
      },
    ]
  },
  {
    title: 'Global',
    list: [
      {
        keywords: 'Ctrl + /',
        description: 'Open keyboard shortcuts help'
      }
    ]
  }
]