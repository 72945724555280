import moment from "moment";

export const formatTableData = (pastChats) => {
  const formattedList = [];
  for (const chat of pastChats) {
    const originalTime = moment(chat.createdAt);
    formattedList.push([
      chat.agent,
      originalTime.add('hours').format('DD:MM:YYYY HH:mm'),
      chat
    ])
  }

  return formattedList
}

export const tableHead = [
  'Agent',
  'Time',
]