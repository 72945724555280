import styled from 'styled-components/macro'


export const HomePageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const Title = styled.h3`
  font-size: 20px;
  text-align: start;
`

export const StatisticsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  gap: 50px;

  .CardIcon {
    width: 86px;
    height: 86px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 36px;
      height: 36px;
      font-size: 36px;
      text-align: center;
      line-height: 56px;
    }
  }

  .SelectBox {
    margin-top: 10px;

    .MuiSelect-root {
      font-size: 14px;
      margin-bottom: -5px;
      z-index:unset;
    }
    .MuiSvgIcon-root {
      margin-right: 0px;
      top: calc(50% - 16px);
      width: 24px;
      height: 24px;
    }
    .MuiPopover-root{
      z-index:unset;
    }
  }
  @media (min-width: 768px) {
    gap: 0px;
    flex-direction: row;
  }
`