import * as S from "./ShortcutsModal.styles";
import React from "react";
import { useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import CloseIcon from "@mui/icons-material/Close";
import { shortcuts } from "./ShortcutsModal.config";


export const ShortcutsModal = ({ hideModal }) => {
  const dispatch = useDispatch();
  return (
    <S.ShortcutsModalContainer>
      <S.ModalHeaderContainer>
        <S.ModalTitle>Hotkeys</S.ModalTitle>
        <Button onClick={()=> dispatch(hideModal())}>
          <CloseIcon />
        </Button>
      </S.ModalHeaderContainer>
      {shortcuts.map((shortcutsSection, key) => {
        return (
          <S.ShortcutsSectionContainer key={key}>
            <S.ShortcutsSectionTitle>
              {shortcutsSection.title}
              <S.ShortcutsSectionList>
                {shortcutsSection.list.map((shortcut, index) => {
                  return (
                    <S.Shortcut key={index}>
                      <div>{shortcut.keywords}</div>
                      <div>{shortcut.description}</div>
                    </S.Shortcut>
                  );
                })}
              </S.ShortcutsSectionList>
            </S.ShortcutsSectionTitle>
          </S.ShortcutsSectionContainer>
        );
      })}
    </S.ShortcutsModalContainer>
  );
};
