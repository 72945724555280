import React, { useCallback, useEffect } from 'react';
import { Outlet } from "react-router";
import { useDispatch } from 'react-redux';
import { NotAuthLayout } from './components/NotAuthLayout';
import { NotAuthFormLayout } from './components/NotAuthFormLayout/NotAuthFormLayout';
import { loginStepOne } from 'redux_toolkit/reducers/app/appSlice';


export const LayoutPartners = ()=> {
  const dispatch = useDispatch();

  const initAppCallback = useCallback(() => {
    dispatch(loginStepOne());
  }, [ dispatch ]);

  useEffect(()=> {
    initAppCallback();
  }, [initAppCallback])

  useEffect(()=> {
    if(!!!localStorage.getItem('access_token')) {
      window.location.replace(`/login`);
    }
  }, [])

  return (
    <>
      {<NotAuthLayout>
        <NotAuthFormLayout headerHeight={125}>
          <Outlet />
        </NotAuthFormLayout>
      </NotAuthLayout>}
    </>
  )
}