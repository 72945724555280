import moment from 'moment';

export const tableHead = [
  'Visitor',
  'Reason',
  'Date Created',
  'Action'
]

export const formatTableData = (bannedList,actionButton) => {
  const formattedList = []
  for (const banned of bannedList) {
    formattedList.push([
      banned.name,
      banned.banReason,
      moment(banned.updatedAt).format(),
      actionButton(banned)
    ])
  }
  return formattedList
}