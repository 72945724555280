import styled from 'styled-components/macro'

export const TransferChatRequest = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 20px 15px;
`
export const Header = styled.div`
  font-size: 24px;
  margin-bottom: 20px
`
export const TransferChatContainer = styled.div`
  border: 1px solid;
  border-radius: 4px;
  padding: 10px;
`
export const ContentHeader = styled.div`
  font-size: 16px
`
export const VisitorInfo = styled.div`
  font-size: 12px;
  font-weight: bold;
`
export const ButtonsContainer = styled.div`
display: flex;
position: relative;
gap: 20px;
margin: 15px 0 5px;
justify-content: flex-end;
`
export const DropDownMenu = styled.div`
  position: absolute;
  z-index: 2;
  top: 100%;
  left: 0;
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 5px;
  height: 225px;
  overflow: auto;
`