import styled from 'styled-components/macro'
import { Form as FormikForm } from 'formik'


export const FormTitle = styled.div`
  color: #0f3d56;
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 25px;
`

export const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: auto;
`

export const Form = styled(FormikForm)`
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: center;
  flex: 1;
`

export const FormError = styled.div`
  color: #b20000;
  font-size: 13px;
  font-weight: bold;
`