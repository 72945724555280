import * as S from './Settings.styles';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { LANGUAGES } from 'configs/constants';
import { Button, SelectBox } from 'ui';
import { updateWidget } from 'redux_toolkit/reducers/partner/widget/widgetSlice';


export const Settings = () => {
  const dispatch = useDispatch();
  const widgetConfig = useSelector(({ widget }) => widget.config);
  const { partnerId } = useSelector(({ userPartner }) => userPartner.userData);
  const [selectId, setSelectId] = useState(0);

  const onSubmit = (payload) => {
    dispatch(updateWidget({partnerId, _id: widgetConfig._id, data: {...payload}}));
  }

  const getSelectedValue = useCallback(()=> {
    const languageIsList = LANGUAGES.find(item=> item.value === widgetConfig.language);
    setSelectId(languageIsList.id);
  }, [widgetConfig])

  useEffect(()=> {
    getSelectedValue();
  }, [getSelectedValue])

  return (
    <S.SettingsContainer>
      <Formik
        onSubmit={onSubmit}
        initialValues={widgetConfig}
        enableReinitialize={true}
      >
        {({ values, setValues, handleSubmit, setFieldValue }) => {
          return (
            <>
              <S.SettingItemContainer>
                <S.SettingItemTitle>
                  General preferences
                </S.SettingItemTitle>
                <S.SettingOption>
                  <S.OptionName className='WithHelperText'>
                    Language
                  </S.OptionName>
                  <S.ColumnContainer>
                    <SelectBox
                      list={LANGUAGES}
                      defaultValue={selectId}
                      onChange={(e) => {
                        setFieldValue('language', e.value)
                        setSelectId(e.id);
                      }}
                    />
                    <FormHelperText>
                      Note: The selected language is not displayed in the preview.
                    </FormHelperText>
                  </S.ColumnContainer>
                </S.SettingOption>
                <S.SettingOption>
                  <S.OptionName>
                    Notification sounds
                  </S.OptionName>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={values.notificationSound}
                        onChange={(e) => setFieldValue('notificationSound', e.target.checked)}
                      />
                    }
                    label="Play a sound for the visitor when a new message is received"
                  />
                </S.SettingOption>
              </S.SettingItemContainer>
              <S.SettingItemContainer>
                <S.SettingItemTitle>
                  Satisfaction ratins
                </S.SettingItemTitle>
                <S.SettingOption>
                  <S.OptionName>
                    Enable satisfaction ratings
                  </S.OptionName>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={values.isRatingEnabled}
                        onChange={(e) => setFieldValue('isRatingEnabled', e.target.checked)}
                      />
                    }
                    label="Allow visitors to rate their chats with your agents."
                  />
                </S.SettingOption>
              </S.SettingItemContainer>
              <S.ActionsContainer>
                <Button onClick={handleSubmit}>
                  Save Changes
                </Button>
                <Button
                  color='secondary'
                  onClick={() => {
                    setValues(widgetConfig)
                    getSelectedValue()
                  }}>
                  Discard Changes 
                </Button>
              </S.ActionsContainer>
          </>
        )}}
      </Formik>
    </S.SettingsContainer>
  )
}