import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  addAdminServ,
  deleteAdminServ,
  editAdminServ,
  getAdminsListServ,
} from './adminsApi';
import { setNotification } from 'redux_toolkit/reducers/notification/notificationSlice';

const initialState = {
  loading: false,
  list: [
    {
      _id: 1,
      name: '',
      email: '',
      companyName: '',
      phone: ''
    },
  ]
}

export const getAdmins = createAsyncThunk(
  'get_admins',
  async () => await getAdminsListServ()
);

export const addAdmin = createAsyncThunk(
  'add_addmin',
  async (data, {dispatch}) => {
    const res = await addAdminServ(data);
    if (res.success) {
      dispatch(setNotification({type: res.message, message: 'success'}));
      return {
        ...res.data,
        ...data,
      }
    }
    dispatch(setNotification({type: res.message, message: 'error'}));
    throw new Error('addAdmin function Error', res.message);
  }
);

export const editAdmin = createAsyncThunk(
  'edit_admin',
  async (admin, {dispatch}) => {
    const res = await editAdminServ(admin);
    if (res.success) {
      dispatch(setNotification({type: res.message, message: 'success'}));
      return admin;
    }
    dispatch(setNotification({type: res.message, message: 'error'}));
    throw new Error('editAdmin function Error', res.message);
  }
);

export const deleteAdmin = createAsyncThunk(
  'delete_admin',
  async (adminId, {dispatch}) => {
    const res = await deleteAdminServ(adminId);
    if (res.success) {
      dispatch(setNotification({type: res.message, message: 'success'}));
      return adminId;
    }
    dispatch(setNotification({type: res.message, message: 'error'}));
    throw new Error('deleteAdmin function Error', res.message);
  }
);

export const adminsSlice = createSlice({
  name: 'admins',
  initialState,
  reducers: {
    setLoadingAdmins: (state, action)=> {
      state.loading = action.payload;
    },
    setPartnerInAdminId: (state, action)=> {
      for(let item of state.list) {
        if(item._id === action.payload._id) item.partnerId = action.payload.partnerId;
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAdmins.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getAdmins.fulfilled, (state, action) => {
        state.status = 'idle';
        state.list = action.payload.data;
      })
      .addCase(getAdmins.rejected, (state, action) => {
        state.status = 'error';
        console.error("Error redux async func 'getAdmins'", action.error)
      })
      .addCase(addAdmin.pending, (state)=> {
        state.status = 'loading'
      })
      .addCase(addAdmin.fulfilled, (state, action) => {
        state.status = 'idle';
        state.list = state.list.concat(action.payload);
      })
      .addCase(addAdmin.rejected, (state, action) => {
        state.status = 'error';
        console.error("Error redux async func 'addAdmin'", action.error)
      })
      .addCase(editAdmin.pending, (state)=> {
        state.status = 'loading'
      })
      .addCase(editAdmin.fulfilled, (state, action) => {
        state.status = 'idle';
        state.list = state.list.map(admin =>{
          return action.payload._id === admin._id
            ? { ...admin, ...action.payload }
            : admin
        })
      })
      .addCase(editAdmin.rejected, (state, action) => {
        state.status = 'error';
        console.error("Error redux async func 'editAdmin'", action.error)
      })
      .addCase(deleteAdmin.pending, (state)=> {
        state.status = 'loading'
      })
      .addCase(deleteAdmin.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = action.payload;
        state.list = state.list.filter(admin => admin._id !== action.payload);
      })
      .addCase(deleteAdmin.rejected, (state, action) => {
        state.status = 'error';
        console.error("Error redux async func 'deleteAdmin'", action.error)
      });
  },
});

export const {
  setLoadingAdmins,
  setPartnerInAdminId
} = adminsSlice.actions;
export const selectAdmins = (state) => state.admins;
export default adminsSlice.reducer;