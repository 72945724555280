import styled from "styled-components/macro";
import MaterialListItem from "@material-ui/core/ListItem";
import MaterialListItemIcon from "@material-ui/core/ListItemIcon";
import MaterialListItemText from "@material-ui/core/ListItemText";
import MaterialList from "@material-ui/core/List";
import MaterialDivider from "@material-ui/core/Divider";
import MaterialButton from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core";

export const ListItem = styled(MaterialListItem)`
  transition: background 300ms linear;
  border-radius: 3px;
  padding: 4px 16px;

  &.Mui-selected {
    background: #0f3d56;
    &:hover {
      background: #0f3d56;
    }
  }
  justify-content: center;
  @media (min-width:1400px){
    font-size:15px;
  }
`;

export const ListItemIcon = styled(MaterialListItemIcon)`
  min-width: 0px;
  color: rgba(255, 255, 255, 0.8);
  @media (min-width:1400px){
    svg {
      width: 24px !important;
      height: 24px !important;
    }
  }
  @media only screen and (max-width: 1399px) and (min-width: 951px) 
    svg {
      width: 18px !important;
      height: 18px !important;
    }
  }
  @media (max-width:950px){
    svg {
      width: 16px !important;
      height: 16px !important;
    }
  }
`;

export const ListItemText = styled(MaterialListItemText)`
  color: #fff;
  margin-left: 15px;

  &.SubMenuItem {
    margin-left: 0;
    text-align: center;
  }

  .MuiTypography-root {
    font-weight: 300;
    font-size: 14px;
  }
  @media (min-width: 1920px){
  .MuiTypography-root {
    font-weight: 300;
    font-size: 14px;
  }
  }
  @media (max-width:950px){
    .MuiTypography-root {
      font-size: 12px;
    }
  }
`;

export const Drawer = styled.div`
  flex-shrink: 0;
  transition: width 0.3s ease;
  white-space: nowrap;
  position:relative;
  background: #1b1b1b;
  min-width: 222px;

  @media only screen and (max-width: 1399px) and (min-width: 951px) {
    min-width: 217px;
  }

  &.sidebarClosed{
    min-width: 110px;

    @media only screen and (max-width: 1920px) and (min-width: 951px) {
      min-width: 110px;
    }
    @media (max-width:950px){ {
       min-width: 100px;
    }
  }
  
  .MuiDrawer-paper {
    transition: width 0.3s ease;
  }

  @media (min-width:1920px){
    svg{
      width: 24px !important;
      height: 30px !important;
    }
  }

`;

export const List = styled(MaterialList)`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 8px 10px 0px 10px;
  overflow-y: auto;
  margin: 0px 0px 105px;

  & span{
    color: white;
  }

  &.sidebarClosed {
    padding: 8px 15px 0px 15px;

  }
  &::-webkit-scrollbar {
    display: none;
  }


  @media (max-width:950px){
    &.sidebarClosed{
      padding:8px 0px 0px 0px;
    }
  }
`;

export const PartnerInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content:space-between;

  &.sidebarClosed {
    gap: 0px;
    flex-direction: row;
    padding: 0px 0px 0px 0px;
    margin-left: 0;
    flex-direction:column !important;
  }
`;

export const PartnerAvatar = styled.div`
  display: flex;
  width: 51px;
  height: 51px;
  background-size: cover;
  position: relative;
  &.sidebarClosed {
    width: 40px;
    height: 31px;
    margin-bottom:15px;
  }
`;

export const Divider = styled(MaterialDivider)`
  background: rgba(180, 180, 180, 0.3);
  margin: 20px 0px;
`;

export const PartnerName = styled.div`
  color: #fff;
  font-size: 18px;
  margin-left: 11px;
  max-width: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
  &.sidebarClosed {
    display: block;
    margin-left: 0;
  }
  @media only screen and (max-width: 1920px) and (min-width: 951px) {
    &.sidebarClosed {
      font-size: 11px;
    }
  }
  @media only screen and (max-width: 1600px) and (min-width: 951px) {
      font-size: 12px;
  }
  @media (max-width:950px){
    max-width: 90px;
    &.sidebarClosed {
      font-size: 8px;
      margin-left:unset;
    }
  }
`;

export const StatusSelectWrapper = styled.div`
  &.sidebarClosed{
    left: -30px;
    > div {
      > div {
        display: flex;
        flex-direction: column;
        left: 3px;
        flex-direction: row-reverse;
      }
    }
  }

  .MuiInputBase-root {
    margin-left: 0px;
  }

  .MuiSelect-select {
    padding-right: 20px;
  }

  .MuiSelect-icon {
    color: #fff;
    top: 6px;
    right: 6px;
  }

  @media (max-width:950px){
    left: -30px;
    > div {
      > div {
        display: flex;
        flex-direction: column;
        flex-direction: row-reverse;
        left: 3px;
      }
    }
  }
`;

export const SidebarHeader = styled.div`
  display: flex;
  gap: 8px;
  transition: width 0.3s ease;
  background: #1b1b1b;
  flex-direction: column;
  padding: 15px 10px 0px 10px;

  &.sidebarClosed {
    padding: 15px 5px 0px 5px;
  }
  
`;

export const RequestButton = styled(MaterialButton)`
  display: flex;
  position: relative;
  justify-content: flex-start;
  min-width:unset !important;
  .MuiButton-label {
    color: #fff;
    gap: 10px;
  }
  align-items: center;
  color: #fff !important;
  padding: 10px 16px;
  font-size: 12px;
  background: #000 !important;
  &.sidebarClosed {
    justify-content:center;
  }
  @media (min-width:1600px){
    font-size:0.7rem;
  }
  @media only screen and (max-width: 1020px) and (min-width: 951px){
    font-size:10px;
  }
  @media (max-width:950px){
    font-size:0.6em;

    .MuiButton-label {
      color: #fff;
      gap: 5px;
      overflow:hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .MuiButton-label svg{
      width: 16px !important;
      height: 16px !important;
    }

    &.sidebarClosed{
      padding: 9px 1px;
      padding: 4px 15px;
      width: fit-content;
      margin: auto;
    }
  }
  
`;

export const Version = styled.div`
  text-align: center;
  color: #d1d1d1;
  font-size: 12px;
`;
export const DevPortal = styled.div`
  text-align: center;
`;

export const Button = styled(MaterialButton)`
  color: #fff;
  min-width:unset;
 
  .MuiButton-label {
    justify-content:unset;
    width:unset;
  }
  svg {
    width: 20px !important;
    height: 20px !important;
  }
  @media (max-width:1024px){
    max-width:unset;
    margin-left: 0px;

    .MuiButton-label {
     width:unset;
    }
    &.sidebarClosed {
      margin-left: unset;
    }
  }
  @media (min-width:1400px){
    svg{
      width: 25px !important;
      height: 25px !important;
    }
  }
`;

export const PartnerMainInfo = styled.div`
  display: flex;
  align-items: center;
  &.sidebarClosed{
    flex-direction:column;
  }
`;

export const RequestsCount = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  min-width: 25px;
  min-height: 25px;
  border-radius: 50%;
  z-index: 1;
  color: #fff;
  background: #d02929;
  right: ${({ sidebarClosed }) => (sidebarClosed ? -8.5 : -1.5)}px;
  top: -12.5px;
`;

export const SidebarFooter = styled.div`
  display: flex;
  gap: 15px;
  position: fixed;
  bottom: 30px;
  width: ${({ sidebarClosed }) => (sidebarClosed ? 110 : 222)}px;
  margin-top: 30px;
  flex-direction: column;

  @media only screen and (max-width: 1399px) and (min-width: 951px){
    width: ${({ sidebarClosed }) => (sidebarClosed ? 110 : 217)}px;
  }
  @media (max-width:950px){
    width: 100px;
  }
`;

export const PartnerAllInfo=styled.div`
  display:flex;
  justify-content:space-between;
  &.sidebarClosed{
    flex-direction: column;
  }
`;

export const TopMenuContainer=styled.div`
  position: sticky;
  width: 100%;
  top: 0
`;

export const ImgAvatar = styled.div`
  display: flex;
  width: 49px;
  height: 49px;
  border-radius: 50%;
  background-size: contain;
  position: relative;
  background-position: center;
  &.sidebarClosed {
    width: 30px;
    height: 30px;
    margin-bottom:15px;
  }
`;

export const selectStyles = {
  indicatorContainerStyles: {
    '& div[class*="indicatorContainer"]': { padding: '0' },
  },
  valueContainerStyles: {
    padding: 0,
    display: 'flex',
  },
  controlStyles: {
    width: 45,
    cursor: 'pointer'
  }
}

export const useStyles = makeStyles({
  hideScroll: {
    "& .MuiDialog-paper": {
      overflow: "inherit !important",
    },
  },
});