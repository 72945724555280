import * as S from "./AuthLayout.styles";
import React, { memo } from "react";
import { Sidebar } from "components/Sidebar/Sidebar";
import { Shortcuts } from "./components/Shortcuts/Shortcuts";
import { LayoutContainer } from "./components/LayoutContainer/LayoutContainer";


export const AuthLayout = memo(({ children }) => {
  return (
    <S.AuthLayout>
      <Shortcuts />
      <Sidebar />
      <S.ContentContainer>
        <LayoutContainer />
        <S.MainContainer>{children}</S.MainContainer>
      </S.ContentContainer>
    </S.AuthLayout>
  );
}, (prevProps, nextProps)=> {
  return prevProps.children === nextProps.children
});
