import * as S from "./PartnersPage.styles";
import React, { useEffect, useMemo } from "react";
import { Delete, Edit, Settings } from "@mui/icons-material";
import Switch from "@material-ui/core/Switch";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { Button } from "ui";
import { Table, Loader } from "components";
import { tableHead, formatTableData } from "./PartnersPage.config";
import { useDispatch, useSelector } from "react-redux";
import { updatePartnerStatus } from "redux_toolkit/reducers/super_admin/partners/partnersSlice";
import { showModal, hideModal } from "redux_toolkit/reducers/modals/modalsSlice";
import {
  deletePartner,
  removeAssignedAdmin,
  getPartners,
  editPartner
} from "redux_toolkit/reducers/super_admin/partners/partnersSlice";


export const PartnersPage = () => {
  const dispatch = useDispatch();
  const { loading,  list} = useSelector(({ partners }) => partners);
  const adminsList = useSelector(({ admins }) => admins.list);
  const partnersList = useMemo(()=> [...list.values()], [list]);

  useEffect(()=> {
    dispatch(getPartners());
  }, [dispatch])

  const getTableActions = (partner) => {
    let admins = adminsList;

    if (partner.admin?._id) {
      admins = [
        { _id: partner.admin?._id, name: partner.admin?.name},
         ...adminsList.filter(el => {
        return el._id !== partner.admin?._id
      })]
    }

    return [
      <Button
        className="assign-admin"
        onClick={() =>
          dispatch(showModal({
            type: 'assignAdmin',
            title: "Admins",
            admins,
            updatePartner: editPartner,
            removeAdmin: removeAssignedAdmin,
            assignedAdmins: partner.admin,
            partnerId: partner._id,
            hideModal: hideModal,
          }))
        }
      >
        {!!partner.admin?._id ? "Reassign Admin" : "Assign Admin"}
      </Button>,
      <S.ActionsContainer>
        <S.ActionButton
          onClick={() =>{
            dispatch(showModal({
              type: 'partnerForm',
              editablePartner: { _id: partner._id, name: partner.name },
              onConfirm: () => dispatch(editPartner(partner)),
            }))
          }}
        >
          <Edit />
        </S.ActionButton>
        <Switch
          color="primary"
          checked={!!partner.status}
          onChange={() => dispatch(updatePartnerStatus({ _id: partner._id, status: +(!partner.status) }))}
        />
        <S.ActionButton
          onClick={() =>
            dispatch(showModal({
              type: 'partnerSettings',
              editablePartner: {
                _id: partner._id,
                file: partner.file,
              },
            }))
          }
        >
          <Settings />
        </S.ActionButton>
        <S.ActionButton
          onClick={() =>
            dispatch(showModal({
              type: 'confirmation',
              title: `Are you sure you want to delete ${partner.name} ?`,
              confirmText: "Delete",
              description:
                "This item will be deleted immediately. You can't undo this action.",
                hideModal: hideModal,
              onConfirm: () => {
                dispatch(deletePartner(partner._id));
              },
            }))
          }
        >
          <Delete />
        </S.ActionButton>
      </S.ActionsContainer>,
    ]
  };


  function headerButtons() {
    return <S.HeaderContainerActions>
      <S.Plus onClick={() => dispatch(showModal({type: 'partnerForm'}))}>
        <FontAwesomeIcon icon={faPlus} classes='add-agent' />
      </S.Plus>
    </S.HeaderContainerActions>
  }

  return (
      <>
        <S.PartnersPageContainer>
          <Table
            tableHead={tableHead}
            tableData={formatTableData(partnersList, getTableActions)}
            cardHeaderText="Partners"
            headerComponent={headerButtons()}
          />
        </S.PartnersPageContainer>
        {loading && <Loader color="primary" size={100} />}
      </>
  );
};
