import * as S from "../../../../HistoryPage.styles";
import { memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "@mui/material/Pagination";
import moment from "moment";
import { SearchDatesSelect } from "components";
import { Button, SelectBox, SearchInput } from "ui";
import { ratings, types } from "../../../../HistoryPage.config";
import { selectHistory } from "redux_toolkit/reducers/history/historySlice";


export const HistorySearch = memo(({
  setHistoryDetailsItem, 
  setSelectedRowIndex, 
  partnerId, 
  itemLimit, 
  historyCount, 
  historyList
})=> {
  const dispatch = useDispatch();
  const today = { dateFrom: moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'), dateTo:  moment().format() };
  const { userData } = useSelector(({userPartner})=> userPartner);

  const [visitorId, setVisitorId] = useState("");
  const [agentName, setAgentName] = useState("");
  const [searchText, setSearchText] = useState("");
  const [date, setDate] = useState(today);
  const [rating, setRating] = useState();
  const [statusChat, setStatusChat] = useState();
  const [dropdownVisible, setDropdownVisible] = useState(true);
  const [clearDate, setClearDate] = useState(false);
  const [page, setPage] = useState(1);

  const getHistory = useCallback(async (page = 1) => {
    dispatch(selectHistory({partnerId, queryData: {
      searchText: searchText.trim(),
      date: {
        dateFrom: new Date(date.dateFrom).toGMTString(),
        dateTo: new Date(date.dateTo || moment().format()).toGMTString(),
      },
      page,
      visitorId: visitorId.trim(),
      status: statusChat?.value || '',
      agentName: agentName,
      limit: itemLimit,
      agentId: userData.role > 2 ? userData._id : '',
      rating: rating || 0
    }}));
    setHistoryDetailsItem(null);
    setSelectedRowIndex(-1);
  },[
    setSelectedRowIndex,
    setHistoryDetailsItem,
    date,
    rating,
    searchText,
    agentName,
    visitorId,
    statusChat,
    itemLimit,
    partnerId,
    dispatch
  ]);

  const handleChange = (e, p) => {
    setPage(p);
    getHistory(p);
  };

  function clearHistory() {
    setVisitorId("");
    setSearchText("");
    setAgentName("");
    setRating();
    setStatusChat();
    setClearDate(!clearDate);
    setDate(today);
    dispatch(selectHistory({partnerId, queryData: {limit: itemLimit, 
      date: {
        dateFrom: new Date(date.dateFrom).toGMTString(),
        dateTo: new Date(date.dateTo || moment().format()).toGMTString(),
      },
      page
    }}));
  }

  useEffect(() => {
    getHistory();
  }, [partnerId, itemLimit, dispatch]);

  return(
    <S.FiltersContainer id='tableTop'>
      <S.FilterRow>
        <SearchDatesSelect
          setTime={(val) => setDate(val)}
          styles={{ left: "125px", top: "185px", width: "450px" }}
          getDateFrom={(val)=> setDate({ dateFrom: val, dateTo: date.dateTo })}
          getDateTo={(val)=> setDate({ dateFrom: date.dateFrom, dateTo: val })}
          getHistory={getHistory}
          handleBlur={(e)=> setDropdownVisible(!dropdownVisible)}
          clearDate={clearDate}
        />
        <SelectBox
        list={types}
        onChange={(e)=> setStatusChat(e)}
        defaultValue={statusChat?.id}
        />
      </S.FilterRow>
      <S.FilterRow>
        <SearchInput
          value={visitorId}
          onChange={(e) => setVisitorId(e.target.value)}
          clearInput={() => setVisitorId("")}
          placeholder="Visitor id"
        />
        <SearchInput
          value={agentName}
          onChange={(e) => setAgentName(e.target.value)}
          clearInput={() => setAgentName("")}
          placeholder="Agent"
        />
        <SearchInput
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          clearInput={() => setSearchText("")}
          placeholder="Search"
        />
        <SelectBox
          list={ratings}
          onChange={(e)=> setRating(e.value)}
          defaultValue={rating}
        />
        <S.ButtonsContainer>
          <Button
            onClick={() => {
              getHistory();
              setPage(1);
            }}
          >
            Search
          </Button>
          <Button
            color="secondary"
            onClick={() => {
              clearHistory();
            }}
          >
            Clear
          </Button>
        </S.ButtonsContainer>
        <S.PaginationContainer>
          <S.ResultCount>Result: { historyCount || 0 }</S.ResultCount>
          {
            <Pagination
              count={
                (historyCount > 0 &&  historyList?.length > 0)
                  ? Math.ceil(historyCount / itemLimit)
                  : 0
              }
              size="small"
              page={page}
              variant="outlined"
              shape="rounded"
              onChange={handleChange}
            />
          }
        </S.PaginationContainer>
      </S.FilterRow>
    </S.FiltersContainer>
  )
}, (prevProps, nextProps)=> {
  return (
    prevProps.setHistoryDetailsItem === nextProps.setHistoryDetailsItem &&
    prevProps.setSelectedRowIndex === nextProps.setSelectedRowIndex &&
    prevProps.partnerId === nextProps.partnerId &&
    prevProps.itemLimit === nextProps.itemLimit &&
    prevProps.historyCount === nextProps.historyCount &&
    prevProps.historyList === nextProps.historyList
  )
})