import { axiosInstance } from "../../../../utils/axiosInstance"
import { partnerURL } from "../../../../utils/urls"

export const setWidgetNameServ = async (partnerId, body)=> {
  return await axiosInstance
    .post(`${partnerURL}/${partnerId}/widgets`, body)
    .then((res) => res.data)
    .catch((err) => new Error(err.response ? err.response.data.message : "Set WIDGET NAME failed"))
}

export const getWidgetsServ = async (partnerId)=> {
  return await axiosInstance
    .get(`${partnerURL}/${partnerId}/widgets`)
    .then((res) => res.data)
    .catch((err) => new Error(err.response ? err.response.data.message : "Get WIDGETS failed"))
}

export const updateWidgetServ = async (partnerId, _id, updateData)=>{
  return await axiosInstance
    .put(`${partnerURL}/${partnerId}/widgets/${_id}`, updateData)
    .then((res) => res.data)
    .catch((err) => new Error(err.response ? err.response.data.message : "Update WIDGETS STYLES failed"))
}

export const deleteWidgetServ = async (partnerId, widgetId)=>{
  return await axiosInstance
    .delete(`${partnerURL}/${partnerId}/widgets/${widgetId}`)
    .then((res) => res.data)
    .catch((err) => new Error(err.response ? err.response.data.message : "Delete WIDGET failed"))
}

export const sendScriptServ = async (partnerId, dataBody)=> {
  return await axiosInstance
    .post(`${partnerURL}/${partnerId}/send-script`, dataBody)
    .then((res) => res.data)
    .catch((err) => new Error(err.response ? err.response.data.message : "Send CHAT SCRIPT failed"))
}
