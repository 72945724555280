export const optionsList = [
  {
    id: 1,
    label: 'Chat request',
    description: 'Show notification for incoming chat request',
    name: 'requestFlag'
  },
  {
    id: 2,
    label: 'New messages',
    description: 'Show notification for new messages in open chats',
    name: 'messageFlag'
  },
  {
    id: 3,
    label: 'Status changes',
    description: 'Show notification for automatic status changes',
    name: ''
  }
]