import * as Yup from 'yup';

const requiredErrorMessage = 'This field is required';

export const validationSchema= Yup.object().shape({
    name: Yup.string()
    .min(4, 'The name must be a string min 4 characters.')
    .max(50, 'Too Long!')
    .required(requiredErrorMessage),
    email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
});

export const rolesMap = [
    { label: 'Agent', value: 3, id: 3 },
    { label: 'TeamLead', value: 2, id: 2 }
  ];
  export const chatLimitsMap = [
    { label: 1, value: 1, id: 1 },
    { label: 2, value: 2, id: 2 },
    { label: 3, value: 3, id: 3 },
    { label: 4, value: 4, id: 4 },
    { label: 5, value: 5, id: 5 },
    { label: 6, value: 6, id: 6 },
    { label: 7, value: 7, id: 7 },
    { label: 8, value: 8, id: 8 },
    { label: 9, value: 9, id: 9 },
    { label: 10, value: 10, id: 10 },
  ];
  
  export const customStyles = {
    textColor: '#555555',
    borderColor: '#c4c4c4',
    menuPlacement: "bottom"
  }