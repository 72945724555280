import React from 'react'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'


export const TabPanel = ({
  value,
  index,
  children,
  ...other
}) => {
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`personal-tabpanel-${index}`}
      aria-labelledby={`personal-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={2}>{children}</Box>}
    </Typography>
  )
}