import * as S from './BannedPage.styles'
import React from 'react'
import { BannedPageContiner } from './components/BannedContainer';


export const BannedPage = ()=> {
  return (
    <S.BannedPageContainer>
      <BannedPageContiner />
    </S.BannedPageContainer>
  )
}