import * as S from './Forms.styles'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TextField } from '@material-ui/core'
import { Button } from 'ui'
import { updateWidget } from 'redux_toolkit/reducers/partner/widget/widgetSlice'


export const Forms = () => {
  const dispatch = useDispatch();
  const selectedWidget = useSelector(({ widget }) => widget.config);
  const { partnerId } = useSelector(({ userPartner }) => userPartner.userData);

  const [greetingVisibility, setGreetingVisibility] = useState(true)
  const [greeting, setGreeting] = useState('');
  const [offlineGreeting, setOfflineGreeting] = useState(false)

  const saveChanges = () => {
    dispatch(updateWidget({partnerId, _id: selectedWidget._id, data: {styles: {...selectedWidget.styles}, greetingMessage: greeting, offlineGreeting: !offlineGreeting}}));
  }

  useEffect(() => {
    setGreeting(selectedWidget.greetingMessage)
    setOfflineGreeting(selectedWidget.offlineGreeting)
  }, [selectedWidget.greetingMessage, selectedWidget.offlineGreeting]);

  return (
    <S.FormsContainer>
      <S.LeftPart>
        <S.InfoItemContainer>
          <S.InfoItemTitle>
            <div>
              Pre-chat form
            </div>
            <S.ButtonsContainer>
              <Button onClick={() => setGreetingVisibility(false)} disabled={!greetingVisibility}>
                On
              </Button>
              <Button onClick={() => setGreetingVisibility(true)} disabled={greetingVisibility}>
                Off
              </Button>
            </S.ButtonsContainer>
          </S.InfoItemTitle>
          <S.InfoItemDescription>
            Require visitors to complain a form before starting chat
          </S.InfoItemDescription>
          <S.RowContainer>
            <S.Label>
              Pre-chat greeting
            </S.Label>
            <TextField
              onChange={(e) => setGreeting(e.target.value)}
              multiline
              inputProps={
                { disabled: greetingVisibility }
              }
              size='small'
              value={greeting}
              variant='outlined'
            />
          </S.RowContainer>
        </S.InfoItemContainer>
        <S.ActionsContainer>
        <Button onClick={saveChanges}>
          Save Changes
        </Button>
        <Button
          color='secondary'
          onClick={()=> setGreeting(selectedWidget.greetingMessage)}
        >
          Discard Changes
        </Button>
      </S.ActionsContainer>
      </S.LeftPart>  

    </S.FormsContainer>
  )
}