import { useDispatch } from 'react-redux'
import { useHotkeys } from 'react-hotkeys-hook'
import { showModal } from 'redux_toolkit/reducers/modals/modalsSlice'

export const Shortcuts = () => {
  const dispatch = useDispatch()

  useHotkeys('ctrl+/', () => dispatch(showModal({type: 'shortcutsModal', showOne: true })))

  return null
}