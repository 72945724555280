export const tableHead = [
  'Name',
  'Email',
  'Company',
  'Phone',
  '',
  'Action'
]


export const formatTableData = (adminsList, getTableActions) => {
  const formattedList = [];

  for (const admin of adminsList) {
    formattedList.push([
      admin.name,
      admin.email,
      admin.companyName,
      admin.phone,
      ...getTableActions(admin)
    ])
  }

  return formattedList
}