import * as S from "./SearchDatesSelect.styles";
import React, { useCallback, useMemo, useState, useEffect } from "react";
import { KeyboardDatePicker } from "@material-ui/pickers";
import moment from 'moment'
import { Button, SelectBox } from "ui";
import { getDates } from "./SearchDatesSelect.config";


export const SearchDatesSelect = ({
  setTime,
  disableToolbar = false,
  getDateFrom = null,
  getDateTo = null,
  styles,
  handleBlur,
  controlStyles,
  clearDate,
}) => {
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [opened, setOpened] = useState(false);
  const [dateFrom,setDateFrom] = useState(moment(new Date()));
  const [dateTo, setDateTo] = useState(moment(new Date()));
  const [isDatesFilterOpen, setIsDatesFilterOpen] = useState(false);
  const onDatesFilterOpen = () => setIsDatesFilterOpen(true);
  const onDatesFilterClose = () => setIsDatesFilterOpen(false);
  const dates = useMemo(() => getDates(onDatesFilterOpen, onDatesFilterClose), []);
  const [selectedDate, setSelectedDate] = useState(dates[0]);

  useEffect(()=> {
    setSelectedDate(dates[0]);
  },[clearDate, dates]);

  const onDateFromChange = (date, value) => {
    setOpened(true);
    setDateFrom(moment(date));
  };

  const onDateToChange = (date, value) => {
    setOpened(true);
    setDateTo(moment(date))
  };

  const onSelectGo = useCallback(() => {
    const dateValue = `${moment(dateFrom).format('DD MMM YYYY')} - ${moment(dateTo).format('DD MMM YYYY')}`;
    setTime({dateFrom: moment(dateFrom).format('DD MMM YYYY'), dateTo: moment(dateTo).format('DD MMM YYYY')});

    const itemIndex = dates.length-1;
    dates[itemIndex].label = dateValue;
    dates[itemIndex].value = dateValue;
    setSelectedDate(dates[itemIndex]);
  }, [dateFrom, dateTo, dates]);

  const onDateFromAccept = (date)=> {
    getDateFrom && getDateFrom(date)
  }

  const onDateToAccept = (date)=> {
    getDateTo && getDateTo(date);
  }

  const openMenu = useMemo(() => {
    if (isDatesFilterOpen) return true;
    if (!opened && isDatesFilterOpen) return true;
    if (!opened && !isDatesFilterOpen) return false;
    return true
  }, [isDatesFilterOpen, opened])

  return (
    <>
      <SelectBox
        list={dates}
        onChange={(e) => {
          setTime(e.value)
          setSelectedDate(e)
        }}
        className="SearchDatesSelect"
        menuIsOpen={openMenu}
        onMenuOpen={() => setOpened(true)}
        onMenuClose={() => setOpened(false)}
        controlStyles={controlStyles}
        defaultValue={selectedDate.id}
      />
      {isDatesFilterOpen && (
        <S.DatesFilterContainer customcss={styles} ispickeropen={isPickerOpen.toString()}>
          <KeyboardDatePicker
            onOpen={() => setIsPickerOpen(true)}
            onClose={() => setIsPickerOpen(false)}
            disableToolbar={disableToolbar}
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            allowKeyboardControl
            id="date-picker-inline"
            value={dateFrom}
            inputValue={moment(dateFrom).format('MM/DD/YYYY')}
            autoOk={true}
            label="From"
            onChange={onDateFromChange}
            onAccept={onDateFromAccept}
            disableFuture
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
          <KeyboardDatePicker
            onOpen={() => setIsPickerOpen(true)}
            onClose={() => setIsPickerOpen(false)}
            disableToolbar={disableToolbar}
            value={dateTo}
            inputValue={moment(dateTo).format('MM/DD/YYYY')}
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            allowKeyboardControl
            id="date-picker-inline"
            label="To"
            autoOk={true}
            onChange={onDateToChange}
            onAccept={onDateToAccept}
            disableFuture
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
          <Button
            onClick={() => {
              onSelectGo();
              setOpened(false);
              setIsDatesFilterOpen(false)
              handleBlur(false)
          }}
        
          >
            Go
          </Button>
        </S.DatesFilterContainer>
      )}
    </>
  );
};
