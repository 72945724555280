import * as S from "./ProfileForm.styles";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import TextField from "@material-ui/core/TextField";
import { validationSchema } from "./ProfileForm.config";
import { updateUserProfile } from "redux_toolkit/reducers/user_partner/userPartnerSlice";


export const ProfileForm = ({ userData }) => {
  const dispatch = useDispatch();
  const [editMode, setEditMode] = useState(false);

  const onSubmit = (values) => {
    if (!editMode) return setEditMode(true);

    dispatch(updateUserProfile(values));
    setEditMode(false);
  };

  return (
    <S.ProfileFormContainer>
      <Formik
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        initialValues={userData}
        validateOnChange={true}
        validateOnBlur={false}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleSubmit,
          handleChange,
          getFieldProps,
        }) => {
          return (
            <S.ProfileFormWrapper onSubmit={handleSubmit}>
              <TextField
                {...getFieldProps("name")}
                value={values.name}
                fullWidth
                id="name"
                disabled={!editMode}
                name="name"
                label="Display Name"
                size="small"
                onChange={(e) => {
                  handleBlur(e);
                  handleChange(e);
                }}
                variant="outlined"
                error={touched.name && !!errors.name}
                helperText={touched.name && errors.name}
              />
              <TextField
                {...getFieldProps("email")}
                value={values.email}
                fullWidth
                id="email"
                name="email"
                disabled={!editMode}
                size='small'
                label="Email Address"
                onChange={(e) => {
                  handleBlur(e);
                  handleChange(e);
                }}
                variant="outlined"
                error={touched.email && !!errors.email}
                helperText={touched.email && errors.email}
              />
              <TextField
                {...getFieldProps("tagLine")}
                value={values.tagLine}
                fullWidth
                size='small'
                id="tagLine"
                name="tagLine"
                disabled={!editMode}
                label="Tagline"
                onChange={(e) => {
                  handleBlur(e);
                  handleChange(e);
                }}
                variant="outlined"
                error={touched.tagLine && !!errors.tagLine}
                helperText={touched.tagLine && errors.tagLine}
              />
            </S.ProfileFormWrapper>
          );
        }}
      </Formik>
    </S.ProfileFormContainer>
  );
};
