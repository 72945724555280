import * as S from './ConfirmationAlert.styles'
import React from 'react'
import { useDispatch } from 'react-redux'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import { Button } from 'ui'


export const ConfirmationAlert = ({
  title,
  description,
  hideModal,
  onConfirm,
  confirmText,
  closeAllModals = false
}) => {
  const dispatch = useDispatch();
  return (
    <S.ConfirmationAlertContainer>
      <DialogTitle
        style={{ padding: '0px' }}
      >
        { title }
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          { description }
        </DialogContentText>
      </DialogContent>
      <S.ButtonsContainer>
        <Button
          color='secondary'
          onClick={()=> dispatch(hideModal())}
        >
          Close
        </Button>
        <Button
          color='primary'
          onClick={() => {
            onConfirm()
            dispatch(hideModal(closeAllModals))
          }}
        >
          { confirmText }
        </Button>
      </S.ButtonsContainer>
    </S.ConfirmationAlertContainer>
  )
}