import { createSlice } from '@reduxjs/toolkit';
import { dropRight } from 'utils/functions';
const initialState = {
  modals: [],
}

export const modalsSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    showModal: (state, action) => {
      const defaultProps = {
        isVisible: true,
        componentProps: action.payload,
        modalProps: action.payload.modalProps || {},
        type: action.payload.type
      }
      state.modals = [...state.modals, defaultProps];
      if(action.payloadcomponentProps?.showOne) state.modals = [defaultProps];
    },
    hideModal: (state, action) => {
      state.modals = action.payload === true ? [{
        isVisible: false,
        internal: null,
        componentProps: {},
        modalProps: {}
      }] : dropRight(state.modals);
    },
    hideModalChatId: (state, action)=> {
      state.modals = state.modals.filter((item)=> item.componentProps.chatId !== action.payload.chatId);
    }
  }
});

export const {
  showModal,
  hideModal,
  hideModalChatId
} = modalsSlice.actions;
export const selectModals = (state) => state.modals;
export default modalsSlice.reducer;