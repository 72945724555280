import { axiosInstance } from '../../../utils/axiosInstance';
import { usersURL, partnerURL } from "../../../utils/urls";

export const getUserPartnersServ = async ()=> {
  return await axiosInstance
    .get(`${usersURL}/partners`)
    .then((res) => res.data)
    .catch((err) => new Error(err.response ? err.response.data.message : "Get PARTNERS failed"));
}

export const forgotPasswordServ = async ( resetData )=> {
  return await axiosInstance
    .post(`${usersURL}/user/forgot-password`, resetData)
    .then((res) => res.data)
    .catch((err) => new Error(err.response ? err.response.data.message : "Forgot password failed"));
}

export const resetPasswordServ = async (resetData)=> {
  return await axiosInstance
    .post(`${usersURL}/user/reset-password`, resetData)
    .then((res) => res.data)
    .catch((err) => new Error(err.response ? err.response.data.message : "Reset password failed"));
}

export const loginUserServ = async (loginData)=> {
  return await axiosInstance
    .post(`${usersURL}/user/login`, loginData)
    .then((res) => res.data)
    .catch((err) => new Error(err.response ? err.response.data.message : "Login USER failed"));
}

export const loginSuperadminServ = async (loginData)=> {
  return await axiosInstance
    .post(`${usersURL}/superadmin/login`, loginData)
    .then((res) => res.data)
    .catch((err) => new Error(err.response ? err.response.data.message : "Login SUPERADMIN failed"));
}

export const logoutServ = async ()=> {
  return await axiosInstance
    .get(`${usersURL}/logout`)
    .then((res) => res.data)
    .catch((err) => new Error(err.response ? err.response.data.message : "LOGOUT failed"));
}

export const meAuthServ = async (partnerId)=> {
  return await axiosInstance
    .get(`${partnerURL}/${partnerId}/me`)
    .then((res) => res.data)
    .catch((err) => new Error(err.response ? err.response.data.message : "Get ME failed"));
}

export const confirmPasswordServ = async ({ password, userId })=> {
  return await axiosInstance
    .put(`${usersURL}/user/set-password`,{ password, userId })
    .then((res) => res.data)
    .catch((err) => new Error(err, "CONFIRM Password failed"));
}

export const resendEmailServ = async (data)=> {
  return await axiosInstance
    .post(`${usersURL}/user/resend`, data)
    .then((res) => res.data)
    .catch((err) => new Error(err.response ? err.response.data.message : "Resend email failed"));
}