import * as S from "./HistoryPage.styles";
import { HistoryPageContainer } from "./components/Container/HistroyPageContainer";


export const HistoryPage = () => {
  return (
    <S.HistoryPageContainer>
      <HistoryPageContainer />
    </S.HistoryPageContainer>
  );
};
