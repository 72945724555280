import * as S from "./SuperAdminHomePage.styles";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import moment from "moment";
import HandshakeIcon from "images/handshake.svg";
import {
  SearchDatesSelect,
  Card,
  CardHeader,
  CardFooter,
  CardIcon,
  GridItem
} from "components";
import { getChatsCount } from "redux_toolkit/reducers/super_admin/counts/countsSlice";


export const SuperAdminHomePage = () => {
  const dispatch = useDispatch();
  const { userCount, partnerCount, chatCount } = useSelector(({ counts }) => counts);

  const [dropdownVisible, setDropdownVisible] = useState(true);
  const [time, setTime] = useState({
    dateFrom: new Date(new Date().setHours(0, 0, 0, 0)),
    dateTo: new Date()
  });

  function getDateFrom(val) {
    setTime({ dateFrom: val, dateTo: time.dateTo })
  }

  function getDateTo(val) {
    setTime({ dateFrom: time.dateFrom, dateTo: val })
  }

  function handleBlur(e) {
    setDropdownVisible(!dropdownVisible);
  }

  function changeChatDate(value) {
    const timeRange = {...value, dateTo: value.dateTo || moment().format()}
    setTime(timeRange);
    dispatch(getChatsCount(timeRange));
  }

  useEffect(()=> {
    dispatch(getChatsCount(
      {
        dateFrom: new Date(new Date().setHours(0, 0, 0, 0)),
        dateTo: new Date()
      }
    ));
  }, [dispatch])

  const useStyles = makeStyles(styles);
  const classes = useStyles();

  return (
    <S.HomePageContainer>
      <S.Title>Dashboard</S.Title>
      <S.StatisticsContainer>
        <GridItem xs={12} sm={12} md={4}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon className="CardIcon" color="warning">
                <img
                  alt="icon"
                  src={HandshakeIcon}
                  style={{ width: "56px" }}
                />
              </CardIcon>
              <p className={classes.cardCategory}>Count</p>
              <h3 className={classes.cardTitle}>{partnerCount}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>All partners</div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon className="CardIcon" color="success">
                <PeopleOutlineIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Count</p>
              <h3 className={classes.cardTitle}>{userCount}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>Users</div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card>
            <CardHeader
              color="success"
              stats
              icon
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                <CardIcon className="CardIcon" color="info">
                  <MessageOutlinedIcon />
                </CardIcon>
                <SearchDatesSelect
                  styles={{ left: '-365px', top: "215px", width: '460px' }}
                  setTime={changeChatDate}
                  getDateFrom={getDateFrom}
                  getDateTo={getDateTo}
                  controlStyles={{ marginTop: 15 }}
                  handleBlur={handleBlur}
                />
              </div>
                <p className={classes.cardCategory}>Count</p>
                <h3 className={classes.cardTitle}>{chatCount}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>Chats</div>
            </CardFooter>
          </Card>
        </GridItem>      
      </S.StatisticsContainer>
    </S.HomePageContainer>
  );
};
