import styled from 'styled-components/macro'
import List from '@material-ui/core/List';


import EmptyAvatarIcon from 'images/emptyAvatar.png'

export const Title = styled.div`
  color: #0f3d56;
  font-size: 28px;
  font-weight: bold;
  margin: 25px 0px;
`

export const PartnersList = styled(List)`
  width: 100%;
  margin-bottom: 40px;
  max-height:250px;

  .MuiListItem-gutters{
    padding-left: 0 !important;
    font-size: 12px;
  }

   div{
    padding-top:0px;
    padding-bottom:0px;
  }
`

export const Email = styled.div`
  color: #0f3d56;
  margin-top: 35px;
`

export const NoPartner = styled.div`
  color: 0f3d56;
  margin-bottom: 50px;
  font-size: 20px;
`

export const AvatarIcon = styled.div`
  top: calc(-50px + (50 - 60) * (100vw - 1024px) / (1920 - 1024));
  left: 0;
  right: 0;
  height: 110px;
  width:110px;
  margin: auto;
  background: url(${EmptyAvatarIcon});
  background-size: cover;
  border-radius: 50%;
  position: absolute;
`