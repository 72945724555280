import { axiosInstance } from "utils/axiosInstance";
import { partnerURL } from "utils/urls";
export const roleGroups = {
  '2': 'partnerTeamleads',
  '3': 'partnerAgents'
}

export const addAgentServ = async (partnerId, dataBody)=> {
  return await axiosInstance
    .post(`${partnerURL}/${partnerId}/users`, dataBody)
    .then((res) => res.data)
    .catch((err) => new Error(err.response?.data.error || "CREATE AGENT failed"));
}

export const getAgentsByPartnerIdServ = async (partnerId)=> {
  return await axiosInstance
    .get(`${partnerURL}/${partnerId}/users`)
    .then((res) => res.data)
    .catch((err) => new Error(err.response?.data.message || "Get AGENTS failed"));
}

export const updateAgentServ = async (agentId, partnerId, data)=> {
  return await axiosInstance
    .put(`${partnerURL}/${partnerId}/users/${agentId}`, data)
    .then((res) => res.data)
    .catch((err) => new Error(err.response ? err.response.data.message : "UPDATE AGENT failed"));
}

export const removeAgentTemleadServ = async (agentId, partnerId)=> {
  return await axiosInstance
      .delete(`${partnerURL}/${partnerId}/users/${agentId}`)
      .then((res) => res.data)
      .catch((err) => new Error(err.response ? err.response.data.message : "Remove USER(AGENT) failed"))
}

export const getAutoAssignServ = async (partnerId)=> {
  return await axiosInstance
    .get(`${partnerURL}/${partnerId}/auto-assign`)
    .then((res) => res.data)
    .catch((err) => new Error(err.response?.data.message || "Get AUTO ASSIGN failed"));
}

export const updateNotificationServ = async (partnerId, agentId, body)=> {
  return await axiosInstance
    .put(`${partnerURL}/${partnerId}/notification/${agentId}`, body)
    .then((res) => res.data)
    .catch((err) => new Error(err.response ? err.response.data.message : "UPDATE AGENT failed"));
}