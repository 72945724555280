import styled from "styled-components/macro";
const { makeStyles } = require("@material-ui/core");

export const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export const Iframe = styled.iframe`
  @media  screen and (max-width:950px){
    width: 100%;
  }
`;