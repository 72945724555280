import cx from "classnames";
import * as S from "../../../Sidebar.styles";
import { memo, useMemo } from "react";
import { StatusSelect } from "components";
import ZeychatLogo from "images/zeychat.png";


export const PartnerAvatar = memo(({
  dashboardImage,
  isSuperAdmin,
  sidebarClosed,
  currentPartner,
  noAdmin
})=> {
  const imgSettings = useMemo(()=> {
    return {
      style: {backgroundImage: dashboardImage ? `url(${dashboardImage})` : `url(${ZeychatLogo})`},
      className: sidebarClosed ? 'sidebarClosed' : ''
    }
  }, [dashboardImage, sidebarClosed])

  return(
    <S.PartnerInfo className={cx({ sidebarClosed })}>
      <S.PartnerMainInfo className={cx({ sidebarClosed })}>
        <S.PartnerAvatar
          className={cx({ sidebarClosed })}
          dashboardImage
        >
          {!isSuperAdmin && noAdmin ? (
              <S.StatusSelectWrapper className={cx({ sidebarClosed })} onClick={e => e.preventDefault()}>
                <StatusSelect
                  partner={currentPartner}
                  indicatorContainerStyles={S.selectStyles.indicatorContainerStyles}
                  valueContainerStyles={S.selectStyles.valueContainerStyles}
                  controlStyles={S.selectStyles.controlStyles}
                  avatarImg={imgSettings}
                />
              </S.StatusSelectWrapper>
            )
            : <S.ImgAvatar  className={imgSettings.className} style={imgSettings.style}></S.ImgAvatar>
          }
        </S.PartnerAvatar>
        <S.PartnerName className={cx({ sidebarClosed })}>
          {currentPartner && currentPartner.name}
        </S.PartnerName>
      </S.PartnerMainInfo>
    </S.PartnerInfo>
  )
}, (prevProps, nextProps)=> {
  return(
    prevProps.dashboardImage === nextProps.dashboardImage &&
    prevProps.isSuperAdmin === nextProps.isSuperAdmin &&
    prevProps.sidebarClosed === nextProps.sidebarClosed &&
    prevProps.currentPartner === nextProps.currentPartner &&
    prevProps.noAdmin === nextProps.noAdmin
  )
})