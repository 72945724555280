import styled from "styled-components/macro";

export const HistoryDetailsContainer = styled.div`
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
  background: #fff;
  border-radius: 4px;

  @media (min-width: 768px) {
    width:60%;
  }
`;

export const DetailsHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;

  .MuiButtonBase-root {
    min-width: unset !important;
    padding: 10px 23px !important;
  }
  @media (max-width: 960px) {
    flex-direction: column;
    flex-direction: column-reverse;
  }
`;

export const TabsContainer = styled.div`
  display: flex;
  margin-right: 10px;
  .MuiButtonBase-root {
    min-width: unset !important;
    padding: 10px !important;
    background: #fff;
    border: 1px solid;
    border-radius: 0px !important;
    color: #0f3d56;
    margin: 0px;
    padding: 15px 15px !important;
    min-width: unset !important;

    &.disabled {
      background: #0f3d56 !important;
      color: #fff;
      border-color: #0f3d56;
    }

    &:first-child {
      border-bottom-left-radius: 5px !important;
      border-top-left-radius: 5px !important;
    }

    &:last-child {
      border-bottom-right-radius: 5px !important;
      border-top-right-radius: 5px !important;
    }
  }
  @media (max-width: 960px) {
    button {
      width: 62px;
      height: 35px;
    }
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  gap: 5px;
  justify-content: center;
  @media (max-width: 960px) {
    margin-bottom: 10px;

    button {
      width: 30px;
      height: 30px;
    }
  }
`;

export const DetailsContent = styled.div`
  display: flex;
`;
