import {
    primaryColor,
    infoColor,
    successColor,
    warningColor,
    dangerColor,
    roseColor,
    whiteColor,
  } from "assets/jss/material-dashboard-react.js";
  
  const radioButtonStyle = {
    radio: {
      fontSize: "12px",
      fontWeight: "400",
      textTransform: "uppercase",
      letterSpacing: "0",
      touchAction: "manipulation",
      cursor: "pointer",
      background: infoColor
    },
    white: {
      color: whiteColor,
    },
    rose: {
      color: roseColor[0],
    },
    secondary: {
      color: "#EFEFEF",
    },
    primary: {
      color: primaryColor[0],
    },
    info: {
      color: infoColor[0],
    },
    success: {
      color: successColor[0],
    },
    warning: {
      color: warningColor[0],
    },
    danger: {
      color: dangerColor[0],
    },
    transparent: {
      "&,&:focus,&:hover": {
        color: "inherit",
        boxShadow: "none",
      },
    },
    disabled: {
      opacity: "0.65",
      pointerEvents: "none",
    },
    lg: {
      padding: "1.125rem 2.25rem",
      fontSize: "0.875rem",
      lineHeight: "1.333333",
      borderRadius: "0.2rem",
    },
    sm: {
      padding: "0.40625rem 1.25rem",
      fontSize: "0.6875rem",
      lineHeight: "1.5",
      borderRadius: "0.2rem",
    },
  };
  
  export default radioButtonStyle;
  