import * as S from './Tabs.styles'
import React, { useState } from 'react'
import { TabPanel } from 'components'
import { tabs } from './Tabs.config'


export const Tabs = () => {
  const [activeTab, setActiveTab] = useState(0)

  return (
    <>
      <S.Tabs
        value={activeTab}
        indicatorColor="primary"
        textColor="primary"
        onChange={(e, tab) => {setActiveTab(tab)}}
        centered
        aria-label="personal tabs"
      >
        {
          tabs.map(tab => {
            return (
              <S.Tab
                key={tab.value}
                label={tab.label}
              />
            )
          })
        }
      </S.Tabs>
      {
        tabs.map(tab => (
          <TabPanel
            key={tab.value}
            index={tab.value}
            value={activeTab}
          >
            <tab.component />
          </TabPanel>
        ))
      }
    </>
  )
}