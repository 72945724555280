import * as S from './AgentsPage.styles';
import React from 'react';
import { ContainerAgents } from './components/ContainerAgents/ContainerAgents';


export const AgentsPage = () => {
  return (
    <S.AgentsPageContainer>
      <ContainerAgents />
    </S.AgentsPageContainer>
  );
};