import { useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router"
import { Modal } from "components";
import { AuthLayout } from "./components/AuthLayout/AuthLayout";
import { loginSuperAdmin } from "redux_toolkit/reducers/app/appSlice";
import { Notification } from "components";


export const LayoutSuperAdmin = ()=> {
  const dispatch = useDispatch();
  const { userInfo } = useSelector(({ user }) => user);

  const initAppCallback = useCallback(async() => {
    dispatch(loginSuperAdmin());
  }, [dispatch]);

  useEffect(()=> {
    initAppCallback();
  }, [initAppCallback]);

  const accessToken = localStorage.getItem('access_token');

  useEffect(()=> {
    if(!!!accessToken ) {
      window.location.replace(`/superadmin/login`);
    }
  }, [accessToken])

  return(
    <>
      <Modal />
      <Notification />
      {!!accessToken && userInfo && (
        <AuthLayout isSidebarOpened={+localStorage.getItem('sidebar_open')}>
          <Outlet />
        </AuthLayout>
      )}
    </>
  )
}