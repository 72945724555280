import styled from 'styled-components/macro'


export const FormsContainer = styled.div`
  display: flex;
  padding: 20px 15px;
  gap: 30px;
  @media (max-width:950px){
    flex-direction:column;
  }
`

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const LeftPart = styled(ColumnContainer)`
  flex: 2;
  gap: 30px;
`

export const RightPart = styled(ColumnContainer)`
  flex: 1;
`

export const InfoItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
`

export const RowContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 25px;
`

export const InfoItemTitle = styled(RowContainer)`
  color: #0f3d56;
  font-size: 18px;
  font-weight: bold;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  div:first-child {
    border-bottom: 3px solid #0f3d56;
    padding: 10px 0px;
  }
`

export const InfoItemDescription = styled.div`
  display: flex;
  color: #555;
  font-size: 15px;
  font-weight: bold;
`

export const Label = styled(InfoItemDescription)`
  font-size: 13px;
`

export const ButtonsContainer = styled(RowContainer)`
  gap: 10px;

  button {
    min-width: unset !important ;
  }
`

export const PreviewText = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: #0f3d56;
  text-align: start;
`

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-right: 20px;
  gap: 10px;

  .Button {
    padding: 15px 35px !important;
    margin-right: 20px;
  }
`