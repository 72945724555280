import styled from 'styled-components/macro'
import MaterialButton from '@material-ui/core/Button'


export const AgentsModalContainer = styled.div`

  .CardHeader {
    margin: 0px;
    padding: 15px 15px 0px !important;
    margin-top: -15px !important;
  }

  h4 {
    margin-left: auto !important;
  }
  table tr th{
    padding:13px!important;
  }
  table tr td{
    padding:13px!important;
  }
`

export const ActionButton = styled(MaterialButton)`
  min-width: 0px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
`