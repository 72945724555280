import './input.css';
import React from 'react'

export const Input = ({
  icon,
  ...rest
}) => {
  return (
    <div className='zc-input-container'>
      <img src={icon} className='zc-icon-input' alt='Input ui'/>
      <input {...rest} className='zc-input'/>
    </div>
  )
}
