import styled from 'styled-components/macro'


export const SettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 15px;
  position:relative;
`


export const SettingItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
`

export const SettingItemTitle = styled.h2`
  color: #0f3d56;
  font-size: 18px;
  padding: 10px 0px;
  border-bottom: 3px solid #0f3d56;
`

export const SettingOption = styled.div`
  display: flex;
  width: 100%;
  align-items: center;

  .MuiSelect-outlined {
    text-align: start;
    width: 330px;
  }

  .MuiTypography-root {
    color: #555;
    @media (max-width:950px){
      font-size:12px;
    }
  }

  @media (min-width: 768px) {
  }
`

export const OptionName = styled.div`
  font-weight: bold;
  font-size: 14px;
  color: #555;
  margin: 17px 0;
  margin-right: 10px;
  min-width: 200px;
  text-align: start;

  &.WithHelperText {
    margin-bottom: 30px;
  }
`

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;

  .MuiFormHelperText-root {
    text-align: center;
  }
`

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  gap: 10px; 

  .Button {
    padding: 15px 35px !important;
  }
`

