import styled from 'styled-components/macro'
import { Form as FormikForm } from 'formik'


export const FormTitle = styled.div`
  color: #0f3d56;
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 70px;
`

export const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-top: auto;

  button{
    min-width:100px!important;
  }
`

export const Form = styled(FormikForm)`
  display: flex;
  gap:30px;
  flex-direction: column;
  align-items: center;
  flex: 1;

  input {
    width: 100%
  }
`

export const FormError = styled.div`
  color: #b20000;
  font-size: 13px;
  font-weight: bold;
`

export const MessageText=styled.p`
  font-size: 13px;
  font-weight: bold;
  position: absolute;
  top: 65px;
`