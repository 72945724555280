import { Appearance } from './components/Appearance/Appearance';
import { Forms } from './components/Forms/Forms';
import { GettingStarted } from './components/GettingStarted/GettingStarted';
import { Settings } from './components/Settings/Settings';


export const tabs = [
  {
    label: 'Getting started',
    value: 0,
    component: GettingStarted
  },
  {
    label: 'Appearance',
    value: 1,
    component: Appearance
  },
  {
    label: 'Forms',
    value: 2,
    component: Forms
  },
  {
    label: 'Settings',
    value: 3,
    component: Settings
  },
]