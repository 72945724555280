import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  deleteWidgetServ,
  getWidgetsServ,
  sendScriptServ,
  setWidgetNameServ,
  updateWidgetServ
} from './widgetApi';
import { setNotification } from 'redux_toolkit/reducers/notification/notificationSlice';

const initialState = {
  loading: false,
  list: [],
  config: {},
  conversation: [
      {
          text: 'You joined',
          type: 'bot',
          date: '15:51'
      },
      {
          text: 'Test agent joined',
          type: 'bot',
          date: '15:51'
      },
      {
          text: "Hi there, I'm looking for green and pink shirts.",
          type: 'visitor',
          date: '15:51'
      },
      {
          text: 'Hi Jane, welcome to our store!',
          type: 'agent',
          date: '15:51'
      },
      {
          text: "We have green shirts in all sizes. Unfortunately, we're out of pink shirts at the moment.",
          type: 'agent',
          date: '15:51'
      },
      {
          text: 'I see. Yes please!',
          type: 'visitor',
          date: '15:51'
      },
      {
          text: "You're welcome. I’ll transfer you to the department now. Please wait for an agent to attend to you.",
          type: 'agent',
          date: '15:51'
      },
  ],
};

export const setWidgetName = createAsyncThunk(
  'set_widget_name',
  async ({partnerId, body}, {dispatch})=> {
    const res = await setWidgetNameServ(partnerId, body);
    if (res.success) {
      dispatch(setNotification({type: res.message, message: 'success'}));
      return res.data;
    }
    dispatch(setNotification({type: res.message, message: 'error'}));
    throw new Error('setWidgetName function Error', res.message)
  }
);

export const getWidgets = createAsyncThunk(
  'get_widgets',
  async (partnerId, {dispatch})=> {
    const res = await getWidgetsServ(partnerId);
    if(res.success){
      dispatch(setWidgetInConfig(res.data.find(d => d.name === 'default')));
    }
    return res.data;
  }
);

export const updateWidget = createAsyncThunk(
  'update_widget',
  async (updateData, {dispatch})=> {
    const res = await updateWidgetServ(updateData.partnerId ,updateData._id ,updateData.data);
    if (res.success) {
      dispatch(setNotification({type: res.message, message: 'success'}));
      return { ...updateData };
    }
    dispatch(setNotification({type: res.message, message: 'error'}));
    throw new Error('updateWidget funcyion Error', res.message);
  }
);

export const deleteWidget = createAsyncThunk(
  'delete_widget',
  async ({partnerId, widgetId}, {dispatch})=> {
    const res = await deleteWidgetServ(partnerId, widgetId);
    if(res.success){
      dispatch(setNotification({type: res.message, message: 'success'}));
      return widgetId;
    }
    dispatch(setNotification({type: res.message, message: 'error'}));
    throw new Error('deleteWidget function Error', res.message)
  }
);

export const sendScript = createAsyncThunk(
  'send_script',
  async ({partnerId, data}, {dispatch})=> {
    const res = await sendScriptServ(partnerId, data);
    if(res.success){
      dispatch(setNotification({type: res.message, message: 'success'}));
      return true;
    }
    dispatch(setNotification({type: res.message, message: 'error'}));
    throw new Error('deleteWidget function Error', res.message);
  }
);


export const partnerWidgetSlice = createSlice({
  name: 'partner_widget',
  initialState,
  reducers: {
    setSelectedWidget: (state, action)=> {
      state.config = { ...state.config, ...state.list.find(el => el._id === action.payload)};
    },
    setNewMessageApper: (state, action)=> {
      state.conversation = [...state.conversation, action.payload]
    },
    setWidgetInConfig: (state, action)=> {
      state.config = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(setWidgetName.pending, (state) => {
        state.loading = true;
      })
      .addCase(setWidgetName.fulfilled, (state, action) => {
        state.loading = false;
        state.list = [...state.list, action.payload];
        state.config = action.payload;
      })
      .addCase(setWidgetName.rejected, (state, action) => {
        state.loading = false;
        console.error("Error redux async func 'setWidgetName'", action.error)
      })
      .addCase(getWidgets.pending, (state) => {
        state.loading = true;
      })
      .addCase(getWidgets.fulfilled, (state, action) => {
        state.loading = false;
        state.list = action.payload;
      })
      .addCase(getWidgets.rejected, (state, action) => {
        state.loading = false;
        console.error("Error redux async func 'getWidgets'", action.error)
      })
      .addCase(updateWidget.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateWidget.fulfilled, (state, action) => {
        state.loading = false;
        const widgetIndex = state.list.findIndex(widget => widget._id === action.payload._id);

        state.list[widgetIndex] = {...state.config, ...action.payload.data};
        state.config = {...state.config, ...action.payload.data};
      })
      .addCase(updateWidget.rejected, (state, action) => {
        state.loading = false;
        console.error("Error redux async func 'updateWidget'", action.error)
      })
      .addCase(deleteWidget.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteWidget.fulfilled, (state, action) => {
        state.loading = false;
        state.list = state.list.filter(el => el._id !== action.payload);
        state.config = { ...state.config,  ...state.list.find(el => el.name === 'default')};
      })
      .addCase(deleteWidget.rejected, (state, action) => {
        state.loading = false;
        console.error("Error redux async func 'deleteWidget'", action.error)
      })
      .addCase(sendScript.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(sendScript.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = action.payload.data;
      })
      .addCase(sendScript.rejected, (state, action) => {
        state.status = 'error';
        console.error("Error redux async func 'sendScript'", action.error)
      })
  },
});

export const {
  setSelectedWidget,
  setNewMessageApper,
  setWidgetInConfig
} = partnerWidgetSlice.actions;
export const selectPartnerWidget = (state) => state.partnerWidget;
export default partnerWidgetSlice.reducer;