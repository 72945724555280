import * as S from "./AssignAdmin.styles";
import React from "react";
import { useDispatch } from "react-redux";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import EmptyAvatarIcon from "images/emptyAvatar.png";
import RemoveIcon from '@mui/icons-material/Delete'
import { updatePartnerAssign } from "redux_toolkit/reducers/super_admin/partners/partnersSlice";


export const AssignAdmin = ({
  title,
  admins,
  removeAdmin,
  partnerId,
  hideModal,
  assignedAdmins
}) => {
  const dispatch = useDispatch();

  return (
    <S.AssignAdminsContainer>
      <DialogTitle style={{ padding: "0px" }}>{title}</DialogTitle>
      <DialogContent>
        {admins.map(({ _id, name }) => {
          const activeAdmin = assignedAdmins?.find(item=> item._id === _id);
          return (
            <S.AdminCard
              isAssigned={activeAdmin}
              key={Math.random()}
            >
              <S.AdminNameWrapper 
                onClick={() => {
                  dispatch(updatePartnerAssign({ _id: partnerId, admin: { _id, name } }));
                  dispatch(hideModal());
                }}
              >
                <S.AdminCardImg src={EmptyAvatarIcon} />
                <span>{name}</span>
              </S.AdminNameWrapper>
              {activeAdmin &&
                <RemoveIcon onClick={() => {
                  dispatch(removeAdmin({ partnerId: partnerId, adminId: _id }));
                  dispatch(hideModal());
                }} />
              }
            </S.AdminCard>
          );
        })}
      </DialogContent>
    </S.AssignAdminsContainer>
  );
};
