export const VISITORS_PER_PAGE = 10;

export const tableHead = [
  'Visitor',
  'Info',
  'Referrer',
  'Chats',
  'Online'
];

export const tableHeadSecond = [
  'Visitor',
  'Info',
  'Referrer',
  'Chats',
];

const browsers = ['chrome', 'firefox', 'safari', 'opera', 'edge']
const platforms = ['windows', 'ubuntu', 'linux', 'android', 'ios', 'mac'];

const getItem = (array, text)=> {
  return array.find(item => text.toLowerCase().includes(item));
};

export const formatTableData = (visitorsList, status) => {
  const formattedList = [];
  if(visitorsList.length){
    for (let index = 0;  index < visitorsList.length; index++) {
      const visitor = visitorsList[index];
      formattedList.push([
        visitor.name,
        <div className='d-flex'>
          {visitor.countryCode && <div className={`flags_small-${visitor.countryCode}`} style={{ height: 25, width: 25 }} />}
          {visitor.userAgent && <div className={`browser-${getItem(browsers, visitor.userAgent.toLowerCase())}`} style={{ height: 25, width: 25 }} />}
          {visitor.userAgent && <div className={`platform-${getItem(platforms, visitor.userAgent.toLowerCase())}`} style={{ height: 25, width: 25 }} />}
        </div>,
        visitor.referrer,
        visitor.pastChats || 0
      ]);
      if(status !== 'idle' && status !== 'active'){
        formattedList[index].push(visitor.roleCreated ? (parseInt((Date.now() - new Date(visitor.roleCreated).getTime())/1000/60)) : 0);
      }
      formattedList[index].push(visitor);
    }
  }
  return formattedList
}