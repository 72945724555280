import * as S from './Appearance.styles';
import * as ST from '../../Tabs.styles';
import cn from 'classnames';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  TextField,
  RadioGroup,
  FormControlLabel,
} from '@material-ui/core';
import { Formik } from 'formik';
import { PreChatForm } from 'components';
import { Button, ColorPicker } from 'ui';
import { upperFirst } from 'utils/functions';
import { RadioApperance } from './components/RadioApperance';
import { windowSize } from './Appearance.config';
import { updateWidget } from 'redux_toolkit/reducers/partner/widget/widgetSlice';


export const Appearance = () => {
  const dispatch = useDispatch();
  const widgetConfigs = useSelector(({ widget }) => widget.config);
  const { partnerId } = useSelector(({ userPartner }) => userPartner.userData);
  const headerElement = useRef();
  const widgetElement = useRef();
  const elementId = useRef()

  const [widgetDegree, setWidgetDegree] = useState(widgetConfigs.styles.widgetDegree);
  const [headerDegree, setHeaderDegree] = useState(widgetConfigs.styles.headerDegree);
  const [activeTab, setActiveTab] = useState(0)
  
  const setFn = useMemo(() => ({
    setWidgetDegree,
    setHeaderDegree,
  }), []);

  useEffect(() => {
    setHeaderDegree(widgetConfigs.styles.headerDegree);
    setWidgetDegree(widgetConfigs.styles.widgetDegree);
  }, [widgetConfigs.styles.headerDegree, widgetConfigs.styles.widgetDegree])

  const onSubmit = (payload) => {
    dispatch(updateWidget({partnerId, _id: widgetConfigs._id, data: {styles: {...payload, headerDegree: headerDegree, widgetDegree: widgetDegree}}}))
  }
  
  const rotatePointer = useCallback((e) => {
    let element = null;
    if (elementId.current === headerElement.current.id) element = headerElement
    if (elementId.current === widgetElement.current.id) element = widgetElement

    if(element) {
      const boxBoundingRect = element.current.getBoundingClientRect();
      const boxCenter = {
        x: boxBoundingRect.left + boxBoundingRect.width/2, 
        y: boxBoundingRect.top + boxBoundingRect.height/2
      }

      let angle = Math.atan2(e.clientX - boxCenter.x, - (e.clientY - boxCenter.y) )*(180 / Math.PI);
      angle = angle < 0 ? angle += 360 : angle;
      setFn[`set${upperFirst(element.current.id)}Degree`](Number(angle.toFixed()));
    }
  }, [setFn])

  let widgetPositionType = 'widgetPosition';
  let windowSizeType = 'windowSize';
  let spacingSideType = 'spacingSide';
  let spacingBottomType = 'spacingBottom';
  let zIndexType = 'zIndex';

  if(activeTab) {
    widgetPositionType = 'widgetPositionMobile';
    windowSizeType = 'windowSizeMobile';
    spacingSideType = 'spacingSideMobile' ;
    spacingBottomType ='spacingBottomMobile';
    zIndexType = 'zIndexMobile';
  }

  const widgetSpacing = useMemo(()=> [
    {
      label: 'Side',
      value: spacingSideType
    },
    {
      label: 'Bottom',
      value: spacingBottomType
    },
  ], [spacingSideType, spacingBottomType])

  const checkboxSettings = useMemo(()=> [
    {
      label: 'Window size',
      value: windowSizeType,
      items: [{label: 'Overlay window', value: 'overlay'}, {label: 'Chat popout', value: 'popup'}]
    },
    {
      label: 'Widget position',
      value: widgetPositionType,
      items: [{label: 'Bottom Left', value: 'bottom-left'}, {label: 'Bottom Right', value: 'bottom-right'}]
    },
  ], [windowSizeType, widgetPositionType])

  useEffect(() => {
    document.addEventListener('mousemove', rotatePointer)
    document.addEventListener('mouseup', (e) => {
      elementId.current = null;
    })
    return () => {
      document.removeEventListener('mousemove', rotatePointer);
      document.removeEventListener('mouseup', (e) => {
        elementId.current = null;
      })
    }
  }, [rotatePointer])

  return (
    <S.AppearanceContainer>
      <Formik
        onSubmit={onSubmit}
        initialValues={widgetConfigs.styles}
        enableReinitialize={true}
      >
        {
          ({ values, setValues, handleSubmit, setFieldValue }) => {
            return (
              <>
                <S.LeftPart>
                  <S.InfoItemContainer>
                    <S.InfoItemTitle>
                      Chat window
                    </S.InfoItemTitle>
                    <S.RowContainer>
                      <div className='title'>Top title</div>
                      <TextField
                        value={values.title}
                        size='small'
                        variant='outlined'
                        onChange={(e) => setFieldValue('title', e.target.value)}
                      />
                    </S.RowContainer>
                    <S.RowContainer>
                      <div className='title'>
                        Header color
                      </div>
                      <div className='picker-container'>
                        <ColorPicker
                          value={values.headerLeftColor}
                          onChange={value => {
                            setFieldValue('headerLeftColor', value)
                          }
                        }
                        />
                        <ColorPicker
                          value={values.headerRightColor}
                          onChange={value => {
                            setFieldValue('headerRightColor', value)
                          }
                        }
                        />
                        <div className='degree'>
                          Angel
                        </div>
                        <S.Degree
                          degree={headerDegree}
                          id="header"
                          ref={headerElement}
                          onMouseDown={(e) => {
                            elementId.current = e.target.id
                          }}
                        />
                        <S.TextField
                          value={headerDegree}
                          size='small'
                          type='number'
                          variant='outlined'
                          onChange={(e) => {
                            setHeaderDegree(Number(e.target.value))
                            setFieldValue('headerDegree', Number(e.target.value))
                          }}
                        />
                      </div>
                    </S.RowContainer>
                    <S.RowContainer>
                      <div className='title'>
                        Text color
                      </div>
                      <div className='picker-container'> 
                        <ColorPicker
                          value={values.agentTextColor}
                          onChange={value => {
                            setFieldValue('agentTextColor', value)
                          }
                        }
                        />
                        <ColorPicker
                          value={values.visitorTextColor}
                          onChange={value => {
                            setFieldValue('visitorTextColor', value)
                          }
                        }
                        />
                      </div>
                    </S.RowContainer>
                    <S.RowContainer>
                      <div className='title'>
                        Background color
                      </div>
                      <ColorPicker
                        onChange={value => setFieldValue('chatBadgeBackgroundColor', value)}
                        value={values.chatBadgeBackgroundColor}
                      />
                    </S.RowContainer>
                    <S.RowContainer>
                      <div className='title'>
                        Text Bubble color
                      </div>
                      <div className='picker-container'>
                        <ColorPicker
                          onChange={
                            value => {
                              setFieldValue('messagesBackgroundColors', {
                                ...values.messagesBackgroundColors,
                                our: value
                              })

                            }
                          }
                          value={values.messagesBackgroundColors.our}
                        />
                        <ColorPicker
                          onChange={
                            value => {
                              setFieldValue('messagesBackgroundColors', {
                                ...values.messagesBackgroundColors,
                                client: value
                              })
                              setFieldValue('messages_colors', {
                                ...values.messages_colors,
                                client: value
                              })
                            }
                          }
                          value={values.messagesBackgroundColors.client}
                        />
                      </div>
                    </S.RowContainer>
                    <S.RowContainer>
                      <div className='title'>
                        Bot Messages color
                      </div>
                      <ColorPicker
                        onChange={value => setFieldValue('botTextColor', value)}
                        value={values.botTextColor}
                      />
                        
                    </S.RowContainer>
                    
                  </S.InfoItemContainer>

                  <S.InfoItemContainer>
                    <S.InfoItemTitle>
                      Widget styles
                    </S.InfoItemTitle>
                    <S.RowContainer>
                      <div className='title'>Widget</div>
                      <RadioGroup
                        row
                        aria-label="position"
                        name="position"
                        onChange={e => setFieldValue('widgetType', e.target.value)}
                        value={values.widgetType}
                      >
                        <FormControlLabel
                          value="circular"
                          control={<S.RadioMI color="primary" />}
                          label="Circular"
                        />
                        <FormControlLabel
                          value="bar"
                          control={<S.RadioMI color="primary" />}
                          label="Bar"
                        />
                      </RadioGroup>
                    </S.RowContainer>
                    <S.RowContainer>
                      <div className='title'>
                        Background color
                      </div>
                      <div className='picker-container'>
                        <ColorPicker
                          onChange={value => setFieldValue('widgetLeftColor', value)}
                          value={values.widgetLeftColor}
                        />
                        <ColorPicker
                          onChange={value => setFieldValue('widgetRightColor', value)}
                          value={values.widgetRightColor}
                        />
                        <div className='degree'>
                          Angel
                        </div>
                        <S.Degree
                          id="widget"
                          ref={widgetElement}
                          degree={widgetDegree} 
                          onMouseDown={(e) => {
                            elementId.current = e.target.id
                          }}
                          onMouseMove={rotatePointer}
                        />
                        <S.TextField
                          value={widgetDegree}
                          size='small'
                          type='number'
                          variant='outlined'
                          onChange={(e) => {
                            setWidgetDegree(Number(e.target.value))
                            setFieldValue('widgetDegree', Number(e.target.value))
                          }}
                        />
                        <S.GradientTypesContainer >
                          <S.Linear
                            className={cn("gradient", { active: values.widgetGradientType === 'linear-gradient' })}
                            degree={widgetDegree}
                            onClick={() => setFieldValue('widgetGradientType', 'linear-gradient')}
                          />
                          <S.Circular
                            className={cn("gradient", { active: values.widgetGradientType === 'radial-gradient' })}
                            degree={widgetDegree}
                            onClick={() => setFieldValue('widgetGradientType', 'radial-gradient')}
                          />
                          <S.Piramide
                            className={cn("gradient", { active: values.widgetGradientType === 'conic-gradient' })}
                            degree={widgetDegree}
                            onClick={() => setFieldValue('widgetGradientType', 'conic-gradient')}
                          />
                          <S.Midline
                            className={cn("gradient", { active: values.widgetGradientType === 'midline-gradient' })}
                            degree={widgetDegree}
                            onClick={() => setFieldValue('widgetGradientType', 'midline-gradient')}
                          />
                        </S.GradientTypesContainer>
                      </div>
                    </S.RowContainer>
                    <S.RowContainer>
                      <div className='title'>
                        Icon color
                      </div>
                      <ColorPicker
                        onChange={value => setFieldValue('widgetIconColor', value)}
                        value={values.widgetIconColor}
                      />
                    </S.RowContainer>
                    <S.RowContainer>
                      <div className='title'>
                        Notification color
                      </div>
                      <ColorPicker
                        onChange={value => setFieldValue('notificationColor', value)}
                        value={values.notificationColor}
                      />
                    </S.RowContainer>
                    <>
                      <S.Tabs
                        value={activeTab}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={(e, tab) => {setActiveTab(tab)}}
                        centered
                        aria-label="personal tabs"
                      >
                        {
                          windowSize.map(tab => {
                            return (
                              <ST.Tab
                                key={tab.value}
                                label={tab.label}
                              />
                            )
                          })
                        }
                      </S.Tabs>
                      <S.DesktoMobileBody
                        index={activeTab}
                        value={activeTab}
                      >
                        {checkboxSettings.map((item, index)=> {
                          return(
                            <S.RowContainer key={index+10}>
                              <div className='title'>{item.label}</div>
                              <RadioApperance
                                onChange={e => {
                                  setFieldValue(item.value, e.target.value)
                                }}
                                value={values[item.value]}
                                items={item.items}
                              />
                            </S.RowContainer>
                          )
                        })}
                        <S.RowContainer>
                          <div className='title'>Window spacing</div>
                          <S.SpacesContainer>
                            {widgetSpacing.map((item, index)=> {
                              return(
                                <S.SpaceItem key={index+20}>
                                 <p>{item.label}</p>
                                <S.TextField
                                    value={values[item.value]}
                                    size='small'
                                    type='number'
                                    variant='outlined'
                                    onChange={(e) => {
                                      setFieldValue(item.value, Number(e.target.value))
                                    }}
                                />
                              <span>px</span>
                                </S.SpaceItem>
                              )
                            })}
                          </S.SpacesContainer>
                        </S.RowContainer>
                        <S.RowContainer>
                          <div className='title'>Z-Index</div>
                          <TextField
                            value={values[zIndexType]}
                            size='small'
                            type='number'
                            variant='outlined'
                            onChange={(e) => {
                              setFieldValue(zIndexType, Number(e.target.value))
                            }}
                          />
                        </S.RowContainer>
                      </S.DesktoMobileBody>
                    </>
                  </S.InfoItemContainer>
                  <S.ActionsContainer>
                    <Button onClick={handleSubmit}>
                      Save Changes
                    </Button>
                    <Button
                      color='secondary'
                      onClick={() => {
                        setValues(widgetConfigs.styles);
                        setHeaderDegree(widgetConfigs.styles.headerDegree);
                        setWidgetDegree(widgetConfigs.styles.widgetDegree);
                      }}
                    >
                      Discard Changes
                    </Button>
                  </S.ActionsContainer>
                </S.LeftPart>
                <S.RightPart>
                  <S.InfoItemContainer>
                    <S.InfoItemTitle>
                      Preview
                    </S.InfoItemTitle>
                    <S.InfoItemDescription>
                      This is how your chat widget will look in a desktop browser. Find out what it'll look like in a mobile browser.
                    </S.InfoItemDescription>
                    <PreChatForm chatConfig={{ ...values, headerDegree: headerDegree, widgetDegree: widgetDegree }} />
                  </S.InfoItemContainer>
                </S.RightPart>
              </>
            )
          }
        }
      </Formik>
    </S.AppearanceContainer>
  )
}
