import styled from 'styled-components/macro'
import {
  MenuItem as MuiMenuItem,
  TextareaAutosize,
  DialogContent,
  Button as MaterialButton
} from '@material-ui/core'

export const ClientChatContainer = styled.div`
  flex: 2.5;
`

export const ClientChatWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 53px);
`

export const ChatContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
  background: #f0f0f0;
  padding: 10px 20px 20px;

  .ck-content {
    height: 81px;
  }

  .ck-editor {
    flex: 1;
    width: 90%;
    min-height: 120px;
  }

  .MuiButtonBase-root {
    border-radius: 50%;
    min-width: unset;

    .MuiSvgIcon-root {
      color: rgb(15, 61, 86);
    }
  }
`

export const ChatHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2px 16px;
  background: #0f3d56;
  align-items: center;
  position: fixed;
  width: calc(100% - 64px);
  max-width: 1200px;
  z-index: 9;
  border-top-left-radius: 3.5px;
  border-top-right-radius: 3.5px;

  div {
    display: flex;
  }

  .MuiButtonBase-root {
    display: flex;
    color: #fff;
    border-radius: 50%;
    min-width: unset;

    .MuiSvgIcon-root {
      color: #fff;
    }

    &:first-child {
      align-items: flex-end;
    }
  }
`

export const ClientName = styled.div`
  color: #fff;
  font-weight: bold;
  font-size: 16px;  
`

export const EditorContainer = styled.div`
  display: flex;
  border: 1px solid #dcdcdc;
  background: #fff;
  border-radius: 4px;
  position: relative;
`

export const EditorButtonsContainer= styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  margin: 0px 15px;
`

export const ModalContentContainer = styled(DialogContent)`
  display: flex;
  gap: 15px;
  padding: 10px 10px 26px;
  width: 1200px;
  max-width: calc(100%);
  flex-direction: column;
  min-height: 674px;
  background: #f0f0f0;
  margin-top: 42px;
  @media (min-width: 1100px) {
    flex-direction: row;
  }

  .UserInfo {
    flex: 1;
    padding: 0px;
    padding-bottom: 20px;
  }

  .UserComputerData {
    flex: 1;
  }

  .ColumnContainer {
    flex: 1;
  }

  .ChatHistory {
    flex: 2.5;
  }

  .UserInfo {
    flex: 1;
  }
`

export const TabsContainer = styled.div`
  display: flex;
  gap: 1px;
  margin-left: 20px;

  button {
    min-width: unset !important;
    padding: 10px !important;
    background: #fff;
    border: 1px solid;
    border-radius: 0px !important;
    color: #0f3d56;
    margin: 0px;
    padding: 4px 8px !important;
    min-width: unset !important;  

    &.disabled {
      background: #0f3d56 !important;
      color: #fff !important;
      border-color: #0f3d56;
    }

    &:hover {
      background: transparent;
      color: #0f3d56;
    }

    &:focus {
      background: transparent;
    }

    &:first-child {
      border-bottom-left-radius: 5px !important;
      border-top-left-radius: 5px !important;
    }

    &:last-child {
      border-bottom-right-radius: 5px !important;
      border-top-right-radius: 5px !important;
    }
  }
`

export const TabButton = styled(MaterialButton)`
  border: solid 1px #b3b3b3;
  box-shadow: none;
  font-size: 12px;
  padding: 4px 8px;
  font-weight: bold;
  color: #555;
`
export const FileInput = styled.input`
  display: none;
`

export const InputLabel = styled.label`
  cursor: pointer;
`

export const ShortcutSelect = styled.div`
  position: absolute;
  width: 100%;
  top: 27px;
  padding-right: 70px;
  @media (max-width: 970px) {
    padding-right: 80px;
  }
`;

export const ShortcutContainer = styled.div`
  position: relative;
  z-index: 3;
  width: 100%;
  height: 173px;
  background: #393939;
  overflow: auto;
`
export const ContainerBtn = styled.div`
  display: flex;
  justify-content: end;
`
export const FileContent = styled.div`
  display: flex;
  position: relative;
  width: fit-content;
  svg {
    cursor: pointer;
    position: absolute;
    right: -5px;
    top: -5px;
    fill: white;
    background: #0f3d56;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    padding: 3px;
    z-index: 5;
  }
`
export const SelectedImage = styled.img`
  width: 80px;
  height: 80px;
`
export const SelectedVideo = styled.video`
  width: 80px;
  height: 80px;
`

export const TextArea = styled(TextareaAutosize)`
  resize: none;
  padding: 10px;
  border: 0;
  border-right: 1px solid #dcdcdc;
  width: 100%;
`

export const MenuItem = styled(MuiMenuItem)`
  cursor: pointer;
  width: 100%;
  border-radius: 0 !important;
  color: #FFFFFF;
  &.isSelected {
    background: #0f3d56;
  }
`
