import * as S from './TransferChat.styles';
import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { STATUS } from 'configs/constants';
import { Button } from 'ui';
import { transferAssign } from 'socket/socket';


export const TransferChat = ({
  hideModal,
  agentName,
  visitorName,
  chatId
}) => {
  const dispatch = useDispatch();
  const { partnerAgents, partnerTeamleads } = useSelector(({agents})=> agents);
  const [showDropDown, setShowDropDown] = useState(false);

  const teammates = partnerAgents.concat(partnerTeamleads);
  // Transfer All login agents
  const teammatesToAssign = teammates.filter((agent) => agent.name !== agentName && agent.status !== STATUS.OFFLINE);
  // Transfer All ONLINE agents
  // const teammatesToAssign = teammates.filter((agent) => agent.name !== agentName && agent.status === STATUS.ONLINE);

  const assignTo = useCallback((agent) => {
    transferAssign(chatId, agent._id);
    dispatch(hideModal());
  }, [hideModal, chatId, dispatch])

  const cancelAndDecline = useCallback(() => {
    transferAssign(chatId, '');
    dispatch(hideModal());
  }, [hideModal, chatId, dispatch])

  return (
    <S.TransferChatRequest>
      <S.Header>
        Transfer Request
      </S.Header>
      <S.TransferChatContainer>
        <S.ContentHeader>
          Transfer by {agentName}
        </S.ContentHeader>
        <S.VisitorInfo>
          Visitor ID: {visitorName}
        </S.VisitorInfo>
      <S.ButtonsContainer>
        <Button
          onClick={() => setShowDropDown(prev => !prev)}
        >
          Assign
        </Button>
        {showDropDown && 
          <S.DropDownMenu>
            {teammatesToAssign.map(agent =>
              <Button key={agent._id} onClick={() => assignTo(agent)}>
                {agent.name}
              </Button>
            )}
          </S.DropDownMenu>
        }
        <Button
          color='secondary'
          onClick={() => cancelAndDecline()}
        >
          Cancel
        </Button>
      </S.ButtonsContainer>
      </S.TransferChatContainer>
    </S.TransferChatRequest>
  )
}
