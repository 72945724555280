import * as S from "../../../AuthLayout.styles";
import { memo } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { logoutSocket } from 'socket/socket';
import { logoutUser } from "redux_toolkit/reducers/user/userSlice";
import { showModal } from "redux_toolkit/reducers/modals/modalsSlice";


export const HeaderLayout = memo(({ currentUser, isSuperAdmin }) => {
  const dispatch = useDispatch();

  const { currentPartner } = useSelector(({ currentPartner }) => currentPartner);
  const { activePage } = useSelector(({ pageName }) => pageName);

  const profileImage = currentUser?.profileImage;

  const logout = async () => {
    if (isSuperAdmin) {
      dispatch(logoutUser());
    } else {
      logoutSocket();
      localStorage.removeItem(currentPartner.key);
      const protokol = process.env.NODE_ENV === 'production' ? 'https' : 'http';
      window.location.href = `${protokol}://${process.env.REACT_APP_DOMAIN}/partners`;
    }
  };

  return(
    <S.AppBar position="relative" id="header">
      <S.Toolbar>
        <S.Typography
          variant="h6"
          noWrap
        >
          {activePage}
        </S.Typography>
        <S.ButtonsList>
          <>
            {!isSuperAdmin && (
              <S.Button onClick={() => dispatch(showModal({type: 'shortcutsModal'}))}>
                Hotkeys
              </S.Button>
            )}
            <S.Button onClick={logout}>
              Logout
              <FontAwesomeIcon
                icon={faSignOutAlt}
                style={{
                  marginLeft: "10px",
                  width: "20px",
                  height: "20px",
                }}
              />
            </S.Button>
            {!isSuperAdmin && (
              <S.Button component={Link} to={`/${currentPartner?.key}/personal`}>
                <S.Avatar>
                {profileImage && <S.AvatarImage src={profileImage} alt="User picture"/>}  
                  <S.UsernameLetter>{currentUser?.name?.substring(0, 1).toUpperCase()}</S.UsernameLetter>
                </S.Avatar>
              </S.Button>
            )}
          </>
        </S.ButtonsList>
      </S.Toolbar>
    </S.AppBar>
  )
}, (prevProps, nextProps)=> {
  return(
    prevProps.currentUser === nextProps.currentUser &&
    prevProps.isSuperAdmin === nextProps.isSuperAdmin
  );
});