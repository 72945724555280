import * as S from "./EditModal.styles";
import React from "react";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import TextField from "@material-ui/core/TextField";
import { validationSchema } from "./EditModal.config";
import { Modal } from "@mui/material";
import { Button } from "ui";
import { updateUserProfile } from "redux_toolkit/reducers/user_partner/userPartnerSlice";


export const EditModal = ({
  userData,
  modalOpened,
  setModalOpened,
}) => {
  const dispatch = useDispatch();

  const onSubmit = (values) => {
    dispatch(updateUserProfile(values));
  };

  const handleClose = () => setModalOpened(false);

  return (
    <Modal
      open={modalOpened}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <S.EditFormContainer>
        <S.TitleEdit>Edit Profile</S.TitleEdit>
        <Formik
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          initialValues={userData}
          validateOnChange={true}
          validateOnBlur={false}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleSubmit,
            handleChange,
            getFieldProps,
          }) => {
            return (
              <S.EditFormWrapper onSubmit={handleSubmit}>
                <TextField
                  {...getFieldProps("name")}
                  value={values.name}
                  fullWidth
                  id="name"
                  name="name"
                  label="Display Name"
                  onChange={(e) => {
                    handleBlur(e);
                    handleChange(e);
                  }}
                  size='small'
                  variant="outlined"
                  error={touched.name && !!errors.name}
                  helperText={touched.name && errors.name}
                />
                <TextField
                  {...getFieldProps("email")}
                  value={values.email}
                  fullWidth
                  id="email"
                  name="email"
                  label="Email Address"
                  onChange={(e) => {
                    handleBlur(e);
                    handleChange(e);
                  }}
                  variant="outlined"
                  error={touched.email && !!errors.email}
                  helperText={touched.email && errors.email}
                  size='small'
                />
                <TextField
                  {...getFieldProps("tagLine")}
                  placeholder={"Tagline"}
                  value={values.tagLine}
                  fullWidth
                  id="tagLine"
                  name="tagLine"
                  label="Tagline"
                  onChange={(e) => {
                    handleBlur(e);
                    handleChange(e);
                  }}
                  size='small'
                  variant="outlined"
                  error={touched.tagLine && !!errors.tagLine}
                  helperText={touched.tagLine && errors.tagLine}
                />
                <S.BtnContainer>
                    <Button 
                      className="closeBtn" 
                      onClick={handleClose}>
                       Close
                    </Button>
                     <Button 
                        type='submit'
                        onClick={handleSubmit}
                      > Edit 
                      </Button>
                    </S.BtnContainer>
              </S.EditFormWrapper>
            );
          }}
        </Formik>
      </S.EditFormContainer>
    </Modal>
  );
};
