import { useCallback, useEffect } from "react";
import { Outlet } from "react-router";
import { useDispatch, useSelector } from 'react-redux';
import { getPartnerId } from "utils/getPartnerData";
import { Modal, Notification, Loader } from "components";
import { ROLES_PAGES } from "configs/constants";
import { AuthLayout } from "./components/AuthLayout/AuthLayout";
import { loginStepTwo } from "redux_toolkit/reducers/app/appSlice";


export const LayoutUsers = ()=> {
  const dispatch = useDispatch();

  const { userData, loading } = useSelector(({ userPartner }) => userPartner);
  
  const partnerIdParam = getPartnerId() || '';
  const [_, partnerKey, activePage] = window.location.pathname.split("/");

  const initAppCallback = useCallback(() => {
    if(partnerIdParam) dispatch(loginStepTwo(partnerIdParam));
  }, [dispatch, partnerIdParam]);

  useEffect(()=> {
    initAppCallback();
  }, [initAppCallback]);

  useEffect(()=> {
    if(userData && !ROLES_PAGES[userData.role].includes(activePage)) window.location.replace(`/${partnerKey}/home`)
  }, [userData, activePage, partnerKey]);

  useEffect(()=> {
    if( !!!localStorage.getItem(`${partnerKey}`)) {
      window.location.replace(`/partners`)
    }
  }, [partnerKey]);

  if (loading) return <Loader />;

  return (
    <>
      <Modal />
      <Notification/>
      { userData && <AuthLayout isSidebarOpened={+localStorage.getItem('sidebar_open')}>
        <Outlet />
      </AuthLayout>
      }
    </>
  )
}