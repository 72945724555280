import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getPartnersCountServ, getUsersCountServ, getChatsCountServ } from './countsApi';

const initialState = {
  loading: true,
  userCount: 0,
  partnerCount: 0,
  chatCount: 0
};

export const getUsersCount = createAsyncThunk(
  'users_count',
  async () => await getUsersCountServ()
);

export const getPartnersCount = createAsyncThunk(
  'get_partners_count',
  async () => await getPartnersCountServ()
);

export const getChatsCount = createAsyncThunk(
  'chat_count',
  async (date) =>  await getChatsCountServ(date)
);


export const countsSlice = createSlice({
  name: 'counts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUsersCount.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUsersCount.fulfilled, (state, action) => {
        state.userCount = action.payload.data;
      })
      .addCase(getUsersCount.rejected, (state, action) => {
        state.loading = false;
        console.error("Error redux async func 'getUsersCount'", action.error)
      })
      .addCase(getPartnersCount.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getPartnersCount.fulfilled, (state, action) => {
        state.status = 'idle';
        state.partnerCount = action.payload.data;
      })
      .addCase(getPartnersCount.rejected, (state, action) => {
        state.status = 'error';
        console.error("Error redux async func 'getPartnersCount'", action.error)
      })
      .addCase(getChatsCount.pending, (state) => {
        state.loading = true;
      })
      .addCase(getChatsCount.fulfilled, (state, action) => {
        state.loading = false;
        state.chatCount = action.payload.data;
      })
      .addCase(getChatsCount.rejected, (state, action) => {
        state.loading = false;
        console.error("Error redux async func 'getChatsCount'", action.error)
      });
  },
});

export const selecCounts = (state)=> state.counts;
export default countsSlice.reducer;