import styled from 'styled-components/macro'
import DialogContent from '@material-ui/core/DialogContent'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'


export const AgentChatContainer = styled(DialogContent)`
  width: 1200px;
  max-width: calc(100%);
  overflow: hidden;
  margin-top: 42px;
`

export const AgentChatWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const ChatContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: #f0f0f0;
  padding: 20px;

  .ck-content {
    height: 81px;
  }

  .ck-editor {
    flex: 1;
    width: 90%;
    min-height: 120px;
  }

  .MuiButtonBase-root {
    border-radius: 50%;
    min-width: unset;

    .MuiSvgIcon-root {
      color: rgb(15, 61, 86);
    }
  }
`

export const ChatHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2px 16px;
  width: 100%;
  background: #0f3d56;
  align-items: center;

  div {
    display: flex;
  }

  .MuiButtonBase-root {
    display: flex;
    color: #fff;
    border-radius: 50%;
    min-width: unset;

    .MuiSvgIcon-root {
      color: #fff;
    }

    &:first-child {
      align-items: flex-end;
    }
  }
`

export const AgentName = styled.div`
  color: #fff;
  font-weight: bold;
  font-size: 16px;  
`

export const EditorContainer = styled.div`
  display: flex;
  border: 1px solid #dcdcdc;
  background: #fff;
  border-radius: 4px;
`

export const EditorButtonsContainer= styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  margin: 0px 15px;
`
export const TextArea = styled(TextareaAutosize)`
  resize: none;
  padding: 10px;
  border: 0;
  border-right: 1px solid #dcdcdc;
  width: 100%;
`
export const FileInput = styled.input`
  display: none;
`
export const InputLabel = styled.label`
  cursor: pointer;
`
export const FileContent = styled.div`
  display: flex;
  position: relative;
  width: fit-content;
  svg {
    cursor: pointer;
    position: absolute;
    right: -5px;
    top: -5px;
    fill: white;
    background: #0f3d56;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    padding: 3px;
    z-index: 5;
  }
`
export const SelectedImage = styled.img`
  width: 80px;
  height: 80px;
`
export const SelectedVideo = styled.video`
  width: 80px;
  height: 80px;
`