import * as S from './AgentForm.styles'
import React, { useEffect, useState } from 'react'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import FormHelperText from '@material-ui/core/FormHelperText';
import { Formik } from 'formik'
import {
  chatLimitsMap,
  customStyles,
  rolesMap,
  validationSchema
} from './AgentForm.config'
import { Button, SelectBox } from "ui";
import { useDispatch, useSelector } from 'react-redux';
import { hideModal } from 'redux_toolkit/reducers/modals/modalsSlice';
import { updateAgent, addAgent } from 'redux_toolkit/reducers/partner/agents/agentsSlice';
import { ROLES } from 'configs/constants';


export const AgentForm = ({
  editableAgent,
  isEdit,
}) => {
  const dispatch = useDispatch();
  const { role, partnerId } = useSelector(({ userPartner }) => userPartner.userData);

  const [userRole, setuserRole] = useState('');
  const [limit, setLimit] = useState(4);

  const onSubmit = (values) => {
    if (isEdit) {
      dispatch(updateAgent({agentId: values._id, partnerId, data: { name: values.name, email: values.email, role: userRole }}));
    } else {
      dispatch(addAgent({partnerId, body: {...values, limit}}));
    }
    dispatch(hideModal());
  }

  const defaultValues = { ...editableAgent, email: editableAgent.email || '', role: ROLES.AGENT }

  useEffect(() => {
    setuserRole(editableAgent.role);
  }, [editableAgent.role]);


  return (
    <S.AgentFormContainer>
      <DialogTitle>
        {isEdit ? 'Edit' : 'Add'} Team Member
      </DialogTitle>
      <Formik
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        initialValues={defaultValues}
        validateOnChange={true}
        validateOnBlur={false}
      >
        {
          ({ getFieldProps, values, errors, handleBlur, handleSubmit, handleChange, setFieldValue }) => {
            return (
              <S.AgentFormWrapper onSubmit={handleSubmit}>
                <S.InputContainer>
                  <TextField
                    {...getFieldProps('name')}
                    value={values.name}
                    fullWidth
                    id="name"
                    name='name'
                    label="Display Name *"
                    onChange={(e) => {
                      handleBlur(e)
                      handleChange(e)
                    }}
                    variant="outlined"
                    size='small'
                    error={!!errors.name}
                    helperText={errors.name}
                  />
                  <TextField
                    {...getFieldProps('email')}
                    value={values.email}
                    fullWidth
                    id="email"
                    name="email"
                    label="Email Address *"
                    onChange={(e) => {
                      handleBlur(e)
                      handleChange(e)
                    }}
                    disabled={role === ROLES.TEAMLEADER}
                    size='small'
                    variant="outlined"
                    error={!!errors.email}
                    helperText={errors.email}
                  />
                
                </S.InputContainer>
                {role !== ROLES.TEAMLEADER &&
                  <S.SelectContainer>
                    <S.RoleSelect isEdit={isEdit}>
                      <SelectBox
                        onChange={(e) => {
                          setFieldValue('role', e.value);
                          setuserRole(e.value);
                        }}
                        list={rolesMap}
                        value={rolesMap.find(r => r.id === (userRole || values.role))}
                        placeholder={userRole}
                        {...customStyles}
                      />
                      <FormHelperText>Please select team member</FormHelperText>
                    </S.RoleSelect>
                    {!isEdit && 
                      <div>
                        <SelectBox
                          onChange={(e) => {
                            setFieldValue('limit', e.value)
                            setLimit(e.value)
                          }}
                          list={chatLimitsMap}
                          value={chatLimitsMap.find(r => r.id === limit)}
                          placeholder="Chat Limit"
                          menuPosition='fixed'
                          maxMenuHeight={180}
                          {...customStyles}
                        />
                        <FormHelperText>min 1, max 10</FormHelperText>
                      </div>
                    }
                  </S.SelectContainer>
                }

                <S.ButtonsContainer>
                  <Button
                    color='secondary'
                    onClick={()=> dispatch(hideModal())}
                  >
                    Close
                  </Button>
                  <Button
                    type='submit'
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>
                </S.ButtonsContainer>
              </S.AgentFormWrapper>
            )
          }
        }
      </Formik>
    </S.AgentFormContainer>
  )
}