import { axiosInstance } from "utils/axiosInstance";
import { partnerURL } from "utils/urls";

export const selectChatHistoryServ = async (partnerId, body)=> {
  return await axiosInstance
    .post(`${partnerURL}/${partnerId}/history`, body)
    .then((res) => res.data)
    .catch((err) => new Error(err.response?.data.message || "Get CHAT HOISTORY failed"));
}

export const getChatsMessagesServ = async (partnerId, chatId)=> {
  return await axiosInstance
    .get(`${partnerURL}/${partnerId}/history/messages/${chatId}`)
    .then((res) => res.data)
    .catch((err) => new Error(err.response?.data.message || "Get CHAT MESSAGES failed"));
}