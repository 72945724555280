export const backURL = process.env.REACT_APP_BASE_URL

// Super admin repositories
export const superAdminURL = backURL+'/superadmin';
export const superAdminsURL = superAdminURL+'/admins';
export const superPartnersURL = superAdminURL+'/partners';

// User repositories
export const usersURL = backURL+'/users';

// Auth repositories
export const authURL = process.env.REACT_APP_BASE_URL+'/auth';

// Partner repositories
export const partnerURL = process.env.REACT_APP_BASE_URL+'/partners';